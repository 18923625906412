import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CategoryButton from "../components/CategoryButton";
import Dropdown from "../components/Dropdown";
import Modal from "../components/Modal";
import Input from "../components/Input";
import Button from "../components/Button";
import axios from "axios";
import SpecialtyDietSection from "../components/SpecialtyDietSection";
import { BASE_URL } from "../components/apiService";
import FeedingLHS from "./FeedingLHS";

// Main Feeding Component
const Feeding_f3: React.FC = () => {
  const navigate = useNavigate();

  // Room selection states
  const [selectedRoomCategory, setSelectedRoomCategory] =
    useState<string>("Lower Level");
  const [selectedRoom, setSelectedRoom] = useState<string>(
    "All Rooms Lower Level"
  );

  // Form fields states
  const [typeValue, setTypeValue] = useState<string>("");
  const [amountValue, setAmountValue] = useState<string>("");
  const [notesValue, setNotesValue] = useState<string>("");
  const [saltAdded, setSaltAdded] = useState<string>("No");

  const [currentDate, setCurrentDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const getCurrentTime = () => {
    const now = new Date();
    return now.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };
  const [currentTime, setCurrentTime] = useState<string>(getCurrentTime());

  // Species diet states
  const [hasSpecialtyDiet, setHasSpecialtyDiet] = useState<boolean>(false);
  const [tankNumber, setTankNumber] = useState<string>("");
  const [species, setSpecies] = useState<string>("");

  // New diet-specific states
  const [dietType, setDietType] = useState<string>("");
  const [dietAmount, setDietAmount] = useState<string>("");

  // Modal states
  const [showTypeModal, setShowTypeModal] = useState<boolean>(false);
  const [showAmountModal, setShowAmountModal] = useState<boolean>(false);
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  const [showSaltModal, setShowSaltModal] = useState<boolean>(false);
  const [showTankModal, setShowTankModal] = useState<boolean>(false);
  const [showSpeciesModal, setShowSpeciesModal] = useState<boolean>(false);

  // Options for dropdowns
  const roomCategoryOptions = ["Lower Level", "Upper Level", "Main Room"];

  const roomOptions = {
    "Lower Level": ["Lower Level"],
    "Upper Level": ["Upper Level"],
    "Main Room": ["Main Room"],
  };

  const [typeOptions, setTypeOptions] = useState<string[]>([]);
  const [speciesOptions, setSpeciesOptions] = useState<string[]>([]);

  const yesNoOptions = ["Yes", "No"];

  useEffect(() => {
    const fetchSpecies = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/species`);
        const speciesData = response.data.map(
          (item: { species_name: string }) => item.species_name
        );
        setSpeciesOptions(speciesData);
      } catch (error) {
        console.error("Error fetching species:", error);
      }
    };

    const fetchFoodTypes = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/food-types`);

        // Check if response.data is an array
        if (Array.isArray(response.data)) {
          const foodTypesData = response.data.map(
            (item: { food_name: string }) => item.food_name
          );
          setTypeOptions(foodTypesData);
        }
        // If response.data is an object with a data property that is an array
        else if (response.data && Array.isArray(response.data.data)) {
          const foodTypesData = response.data.data.map(
            (item: { food_name: string }) => item.food_name
          );
          setTypeOptions(foodTypesData);
        }
        // If the structure is completely different, log it for debugging
        else {
          console.log("Unexpected response format:", response.data);
          setTypeOptions([]);
        }
      } catch (error) {
        console.error("Error fetching food types:", error);
      }
    };

    fetchSpecies();
    fetchFoodTypes();
  }, []);

  // Handler for room category selection
  const handleRoomCategoryChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const category = e.target.value;
    setSelectedRoomCategory(category);
    setSelectedRoom(roomOptions[category as keyof typeof roomOptions][0]);
    resetFormFields();

    if (category !== "Main Room") {
      setHasSpecialtyDiet(false);
    }
  };

  // Reset form fields
  const resetFormFields = () => {
    setTypeValue("");
    setAmountValue("");
    setNotesValue("");
    setSaltAdded("No");
    setTankNumber("");
    setSpecies("");
    setDietType("");
    setDietAmount("");
    setHasSpecialtyDiet(false);
  };

  const handleFoodRecord = () => {
    navigate("/food-record");
  };

  const handleNutritionRecord = () => {
    navigate("/nutrition-record");
  };

  // Handler for date change (prevent future dates)
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(e.target.value);
    const today = new Date();
    if (isNaN(selectedDate.getTime())) {
      alert("Invalid date");
      return;
    }
    if (selectedDate <= today) {
      setCurrentDate(e.target.value);
    } else {
      alert("Cannot select future dates");
    }
  };

  // Handler for time change
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentTime(e.target.value);
  };

  // Save feeding log
  const handleSaveLog = async () => {
    // Validate required fields
    if (!typeValue || !amountValue) {
      alert("Please select food type and amount.");
      return;
    }

    // Construct the log data with comprehensive fields
    const logData = {
      date: currentDate,
      time: currentTime,
      room_category: selectedRoomCategory,
      room: selectedRoom,

      type: typeValue,
      amount: parseFloat(amountValue),
      notes: notesValue,

      salt_added: selectedRoomCategory === "Upper Level" ? saltAdded : null,

      specialty_diet: hasSpecialtyDiet,
      tank_number: hasSpecialtyDiet ? tankNumber : null,
      species: hasSpecialtyDiet ? species : null,
      diet_type: hasSpecialtyDiet ? dietType : null,
      diet_amount: hasSpecialtyDiet ? parseFloat(dietAmount) : null,
    };

    try {
      const response = await axios.post(`${BASE_URL}/feeding-log`, logData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 201) {
        console.log("Feeding log saved successfully:", response.data);
        alert("Feeding log saved successfully!");
        resetFormFields();
        navigate("/");
      } else {
        console.error("Failed to save feeding log:", response.statusText);
        alert("Failed to save feeding log.");
      }
    } catch (error) {
      console.error("Error saving feeding log:", error);
      alert("Error saving feeding log.");
    }
  };

  // Render form fields based on selected room category
  const renderCategoryButtons = () => {
    if (selectedRoomCategory === "Lower Level") {
      return (
        <div className="flex gap-4 w-1/2flex-wrap mt-6 mb-4">
          <CategoryButton
            label={`Type: ${typeValue || "Select..."}`}
            onClick={() => setShowTypeModal(true)}
            onClose={() => setTypeValue("")}
          />
          <CategoryButton
            label={`Amount: ${amountValue ? amountValue + " g" : "Select..."}`}
            onClick={() => setShowAmountModal(true)}
            onClose={() => setAmountValue("")}
          />
        </div>
      );
    } else if (selectedRoomCategory === "Upper Level") {
      return (
        <div className="flex gap-4 flex-wrap mt-6 mb-4">
          <CategoryButton
            label={`Type: ${typeValue || "Select..."}`}
            onClick={() => setShowTypeModal(true)}
            onClose={() => setTypeValue("")}
          />
          <CategoryButton
            label={`Amount: ${amountValue ? amountValue + " g" : "Select..."}`}
            onClick={() => setShowAmountModal(true)}
            onClose={() => setAmountValue("")}
          />
          <CategoryButton
            label={`Salt Added? ${saltAdded}`}
            onClick={() => setShowSaltModal(true)}
            onClose={() => setSaltAdded("No")}
          />
        </div>
      );
    } else if (selectedRoomCategory === "Main Room") {
      return (
        <>
          <div className="flex gap-4 flex-wrap mt-6 mb-4">
            <CategoryButton
              label={`Type: ${typeValue || "Select..."}`}
              onClick={() => setShowTypeModal(true)}
              onClose={() => setTypeValue("")}
            />
            <CategoryButton
              label={`Amount: ${
                amountValue ? amountValue + " g" : "Select..."
              }`}
              onClick={() => setShowAmountModal(true)}
              onClose={() => setAmountValue("")}
            />
          </div>
        </>
      );
    }
    return null;
  };

  const renderLeftBar = () => {
    return (
      <div className="w-56 bg-[#232323] flex flex-col">
        <div
          onClick={() => navigate("/")}
          className=" cursor-pointer justify-center align-middle text-center py-4 mb-6 px-4 py-3 p-6 mt-4 text-sm text-left bg-[#1C1C1E] text-white hover:text-white"
          style={{ borderRadius: 8 }}
        >
          Back to Dashboard
        </div>

        <div
          className="rounded-sm bg-[#707070] mb-4 text-white p-2 m-2 border-t border-r border-l "
          style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
        >
          <div className="py-2 px-2 border-b border-gray-400 cursor-pointer">
            Daily Feeding Log
          </div>
          <div
            onClick={handleFoodRecord}
            className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between"
          >
            <span className="text-sm">
              <span className="text-black">☆</span> Food Record
            </span>
            <span className="text-xs text-black">⇧ A</span>
          </div>
          <div
            onClick={handleNutritionRecord}
            className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between"
          >
            <span className="text-sm">
              <span className="text-black">☆</span> Nutrition Data
            </span>
            <span className="text-xs text-black">⇧ A</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-black text-white flex">
      {/* Left Sidebar */}

      <FeedingLHS />

      {/* Main Content */}
      <div className="flex-1 bg-[#1E1E1E]">
        <div className="flex justify-between items-center bg-[#262626] p-6">
          <div className="text-[#858585]">
            <h1 className="text-2xl font-bold">
              Daily Feeding Log (All Rooms)
            </h1>
            <div className="text-sm text-gray-400">The Doe Lab</div>
          </div>

          <div className="flex items-center">
            <div className="mr-4 text-blue-400">
              <input
                type="date"
                max={new Date().toISOString().split("T")[0]}
                value={currentDate}
                onChange={handleDateChange}
                className="bg-transparent"
              />
              <input
                type="time"
                value={currentTime}
                onChange={handleTimeChange}
                className="bg-transparent text-center"
              />
            </div>
            <div className="w-10 h-10 rounded-full bg-gray-500 flex items-center justify-center font-bold"></div>
          </div>
        </div>
        {/* Date and Feeding Period Section */}
        {/* <div className="p-4 rounded">
          <h2 className="text-xl font-bold mb-4">Date: {getCurrentDate()}</h2>

          <div className="flex flex-col md:flex-row  gap-6">
            <div className="flex items-center gap-4">
              <div className="flex items-center">
                <input
                  type="radio"
                  id="morning"
                  name="feedingPeriod"
                  value="morning"
                  checked={feedingPeriod === "morning"}
                  onChange={() => setFeedingPeriod("morning")}
                  className="mr-2"
                />
                <label htmlFor="morning">Morning Feeding</label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="afternoon"
                  name="feedingPeriod"
                  value="afternoon"
                  checked={feedingPeriod === "afternoon"}
                  onChange={() => setFeedingPeriod("afternoon")}
                  className="mr-2"
                />
                <label htmlFor="afternoon">Afternoon Feeding</label>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
              <label htmlFor="feedingTime" className="whitespace-nowrap">
                Feed all rooms at:
              </label>
              <Input
                id="feedingTime"
                type="time"
                value={feedingTime}
                onChange={(e) => setFeedingTime(e.target.value)}
                className="w-full sm:w-32 bg-gray-700 p-2 rounded text-white"
              />
            </div>
          </div>
        </div> */}

        {/* Room Selection & Form Fields */}
        <div className="flex p-6">
          {/* Left Half - Select Room Section */}
          <div className="w-1/2 pr-4">
            <div className="mb-2">Select Room:</div>
            <Dropdown
              value={selectedRoomCategory}
              onChange={handleRoomCategoryChange}
              options={roomCategoryOptions}
              className="mb-4 w-64"
            />

            {/* Render appropriate category buttons */}
            {renderCategoryButtons()}
          </div>

          {/* Right Half - Notes Section */}
          <div className="w-1/2 pl-4">
            <label className="block text-sm mb-2">Notes</label>
            <textarea
              value={notesValue}
              onChange={(e) => setNotesValue(e.target.value)}
              className="w-full bg-[#1E1E1E] border  p-3 rounded resize-none text-sm "
              placeholder="Enter your notes here..."
            />
          </div>
        </div>

        <SpecialtyDietSection
          hasSpecialtyDiet={hasSpecialtyDiet}
          setHasSpecialtyDiet={setHasSpecialtyDiet}
          selectedRoom={selectedRoom}
          setSelectedRoom={setSelectedRoom}
          tankNumber={tankNumber}
          setTankNumber={setTankNumber}
          species={species}
          setSpecies={setSpecies}
          dietType={dietType}
          setDietType={setDietType}
          dietAmount={dietAmount}
          setDietAmount={setDietAmount}
        />

        {/* Save Buttons */}
        <div className="flex gap-4  p-6">
          <Button
            onClick={handleSaveLog}
            className="bg-green-600 hover:bg-green-700"
          >
            Save Feeding Log
          </Button>
        </div>
      </div>

      {/* Modals */}
      {/* Type Selection Modal */}
      <Modal
        isOpen={showTypeModal}
        onClose={() => setShowTypeModal(false)}
        title="Select Feed Type"
      >
        <div className="space-y-4">
          {typeOptions.map((option) => (
            <div
              key={option}
              onClick={() => {
                setTypeValue(option); // Update typeValue
                setShowTypeModal(false);
              }}
              className="p-3 bg-gray-700 rounded cursor-pointer hover:bg-gray-600"
            >
              {option}
            </div>
          ))}
        </div>
      </Modal>

      {/* Amount Entry Modal */}
      {showAmountModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-[#1E1E1E] p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">Enter Feed Amount</h2>
            <div className="space-y-4">
              <div>
                <label className="block mb-2">Amount (in grams):</label>
                <input
                  type="number"
                  value={amountValue}
                  onChange={(e) => setAmountValue(e.target.value)}
                  placeholder="Enter amount in grams"
                  className="w-full p-2 bg-[#232323] text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex justify-end mt-4">
                <button
                  onClick={() => setShowAmountModal(false)}
                  className="bg-gray-600 hover:bg-gray-500 text-white px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    // Handle save logic here
                    console.log("Amount saved:", amountValue);
                    setShowAmountModal(false);
                  }}
                  className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Notes Entry Modal */}
      <Modal
        isOpen={showNotesModal}
        onClose={() => setShowNotesModal(false)}
        title="Enter Notes"
      >
        <div className="space-y-4">
          <textarea
            value={notesValue}
            onChange={(e) => setNotesValue(e.target.value)}
            placeholder="Enter notes here..."
            className="w-full h-32 bg-gray-700 rounded text-white px-3 py-2"
          />
          <div className="flex justify-end mt-4">
            <Button
              onClick={() => setShowNotesModal(false)}
              className="bg-gray-600 hover:bg-gray-500 mr-2"
            >
              Cancel
            </Button>
            <Button
              onClick={() => setShowNotesModal(false)}
              className="bg-blue-600 hover:bg-blue-700"
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      {/* Salt Added Modal */}
      <Modal
        isOpen={showSaltModal}
        onClose={() => setShowSaltModal(false)}
        title="Salt Added?"
      >
        <div className="space-y-4">
          {yesNoOptions.map((option) => (
            <div
              key={option}
              onClick={() => {
                setSaltAdded(option);
                setShowSaltModal(false);
              }}
              className={`p-3 rounded cursor-pointer ${
                saltAdded === option
                  ? "bg-blue-600"
                  : "bg-gray-700 hover:bg-gray-600"
              }`}
            >
              {option}
            </div>
          ))}
          <div className="flex justify-end mt-4">
            <Button
              onClick={() => setShowSaltModal(false)}
              className="bg-gray-600 hover:bg-gray-500"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      {/* Tank Number Modal */}
      <Modal
        isOpen={showTankModal}
        onClose={() => setShowTankModal(false)}
        title="Enter Tank Number"
      >
        <div className="space-y-4">
          <div>
            <label className="block mb-2">Tank Number:</label>
            <Input
              type="text"
              value={tankNumber}
              onChange={(e) => setTankNumber(e.target.value)}
              placeholder="Enter tank number"
              className="w-full"
            />
          </div>
          <div className="flex justify-end mt-4">
            <Button
              onClick={() => setShowTankModal(false)}
              className="bg-gray-600 hover:bg-gray-500 mr-2"
            >
              Cancel
            </Button>
            <Button
              onClick={() => setShowTankModal(false)}
              className="bg-blue-600 hover:bg-blue-700"
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      {/* Species Selection Modal */}
      <Modal
        isOpen={showSpeciesModal}
        onClose={() => setShowSpeciesModal(false)}
        title="Select Species"
      >
        <div className="space-y-4">
          {speciesOptions.map((option) => (
            <div
              key={option}
              onClick={() => {
                setSpecies(option);
                setShowSpeciesModal(false);
              }}
              className="p-3 bg-gray-700 rounded cursor-pointer hover:bg-gray-600"
            >
              {option}
            </div>
          ))}
          <div className="flex justify-end mt-4">
            <Button
              onClick={() => setShowSpeciesModal(false)}
              className="bg-gray-600 hover:bg-gray-500"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Feeding_f3;
