import { useLocation, useNavigate } from "react-router-dom";

const MorbidityLHS = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;

  const handleNavigateToRecord =()=>{
    navigate('/MorbidityRecord')
  }
  const handleNavigateToEntry =()=>{
    navigate('/MorbidityEntry')
  }

  return (
    <div className="w-56 bg-[#232323] flex flex-col">

      <div onClick={() => navigate("/")}
        className=" cursor-pointer justify-center align-middle text-center py-4 mb-6 px-4 py-3 p-6 mt-4 text-sm text-left bg-[#1C1C1E] text-white hover:text-white" style={{ borderRadius: 8 }}
      >
        Back to Dashboard
      </div>

      <div className="rounded-sm bg-[#707070] mb-4 text-white p-2 m-2 border-t border-r border-l " style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }} >
        <div className="py-2 px-2 border-b border-gray-400 cursor-pointer">
          Morbidity Record
        </div>
        <div onClick={handleNavigateToEntry}
         className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between"
         style={{ opacity: isActive('/MorbidityEntry') ? 1 : 0.6, fontWeight:  isActive('/MorbidityEntry') ? 'bold' : undefined }}
         >
          <span className="text-sm"><span className='text-black' >☆</span> Morbidity Entry</span>
          <span className="text-xs text-black">⇧ A</span>
        </div>
        <div
         onClick={handleNavigateToRecord} 
         className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between"
         style={{ opacity: isActive('/MorbidityRecord') ? 1 : 0.6, fontWeight:  isActive('/MorbidityRecord') ? 'bold' : undefined }}
         >
          <span className="text-sm"><span className='text-black' >☆</span> Morbidity Record</span>
          <span className="text-xs text-black">⇧ A</span>
        </div>
      </div>

    </div>

  )
}

export default MorbidityLHS;