import React, { useState } from "react";
import { formatDate, formatTime } from "../../utils/utils";
import DropDownSelectInput from "../../components/DropDownSelectInput";
import ValueInput from "../../components/ValueInput";
import InputDescriptionBox from "../../components/InputDescriptionBox";
import AnalyticsGraph from "../../components/AnalyticsGraph";
import BirthTable from "./BirthTable";
import { useNavigate } from "react-router-dom";
import BirthLHS from "./BirthLHS";
import BirthAnalyticsGraph from "./BirthAnalyticsGraph";

function BirthRecordPage(props: any) {
  const [currentDateTime, setCurrentDateTime] = useState<Date>(new Date());
  const navigate = useNavigate();

  const handleNavigateToRecord = () => {
    navigate("/BirthRecordPage");
  };

  const handleNavigateToEntry = () => {
    navigate("/BirthEntryPage");
  };

  const newDateValue = (dateValue: any) => {
    const updatedDate = new Date(dateValue);
    const currentTime = new Date(currentDateTime);

    updatedDate.setHours(currentTime.getHours());
    updatedDate.setMinutes(currentTime.getMinutes());

    return updatedDate;
  };

  const newTimeValue = (timeValue: any) => {
    const [hours, minutes] = timeValue.split(":");
    const updatedTime = new Date(currentDateTime);

    updatedTime.setHours(hours);
    updatedTime.setMinutes(minutes);

    return updatedTime;
  };

  const renderHeader = () => {
    return (
      <div className="flex-1 bg-[#1E1E1E]">
        <div className="flex bg-[#262626] justify-between items-center p-6">
          <div className="text-[#858585]">
            <h1 className="text-2xl font-bold">Birth Record</h1>
            <div>The Doe Lab</div>
          </div>

          <div className="flex items-center">
            <div className="flex space-x-2 text-blue-400 px-2 py-1 rounded">
              <input
                type="date"
                value={formatDate(currentDateTime)}
                onChange={(e) =>
                  setCurrentDateTime(newDateValue(e.target.value))
                }
                max={new Date().toISOString().split("T")[0]} // Set max date to today
                className="px-2 py-1 bg-[#1C1C1E] rounded text-sm text-blue-400 text-center appearance-none cursor-pointer focus:outline-none"
              />
              <input
                type="time"
                value={formatTime(currentDateTime)}
                onChange={(e) =>
                  setCurrentDateTime(newTimeValue(e.target.value))
                }
                className="px-2 py-1 bg-[#1C1C1E] rounded text-sm text-blue-400 text-center appearance-none cursor-pointer focus:outline-none"
              />
            </div>

            <div className="w-10 h-10 rounded-full bg-gray-500 flex items-center justify-center font-bold">
              {
                currentDateTime.toLocaleTimeString([], {
                  hour12: true,
                  hour: "2-digit",
                })[0]
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div>
        <div>
          <div className="my-2 text-sm mt-4">Fish Births Table</div>
          <BirthTable />
        </div>

        <div className="mt-8">
          <div className="text-center text-lg">
            Number of Deaths by Species Over Time
          </div>
          <BirthAnalyticsGraph />
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-[#1C1C1E] text-white flex">
      <div className=" bg-[#232323] min-h-screen"></div>

      <div className=" bg-[#232323] min-h-screen">
        <BirthLHS />
      </div>

      <div className="flex flex-col w-full">
        {/* Header */}
        {renderHeader()}

        {/* Body */}
        <div className="flex-1 px-4 py-6">{renderBody()}</div>
      </div>
    </div>
  );
}

export default BirthRecordPage;
