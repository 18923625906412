import React, { useState, useEffect } from "react";
import axios from "axios";
import { LightDarkBgColor } from "../../utils/utils";
import { BASE_URL } from "../../components/apiService";
import { XCircle } from "lucide-react";

interface MorbidityRecordAPI {
  id: number;
  species: string;
  tank: string;
  preserved: boolean;
  notes: string;
  name: string;
  date: string;
  time: string;
}

// Extended interface for processed data
interface MorbidityRecord extends MorbidityRecordAPI {
  formattedDateTime: string;
}

const MorbidityTable: React.FC = () => {
  const [morbidityData, setMorbidityData] = useState<MorbidityRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteLoading, setDeleteLoading] = useState<number | null>(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage] = useState<number>(5);

  const formatDateTime = (dateString: string, timeString: string): string => {
    try {
      const date = new Date(dateString);
      const formattedDate = date
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-");
      let formattedTime = timeString;
      if (timeString.includes("T")) {
        formattedTime = timeString.split("T")[1].substring(0, 8);
      }

      return `${formattedDate} ${formattedTime}`;
    } catch (error) {
      console.error("Error formatting date/time:", error);
      return `${dateString} ${timeString}`;
    }
  };

  const fetchMorbidityData = async () => {
    try {
      setLoading(true);
      const response = await axios.get<MorbidityRecordAPI[]>(
        `${BASE_URL}/morbidity-log`
      );
      const processedData = response.data.map((item, index) => ({
        ...item,
        formattedDateTime: formatDateTime(item.date, item.time),
      }));

      setMorbidityData(processedData);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching morbidity data:", err);
      setError("Failed to load morbidity data. Please try again later.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMorbidityData();
  }, []);

  const handleDeleteLog = async (recordId: number) => {
    if (!window.confirm("Are you sure you want to delete this record?")) {
      return;
    }
    try {
      setDeleteLoading(recordId);
      await axios.delete(`${BASE_URL}/morbidity-log/${recordId}`);
      fetchMorbidityData();

      const remainingRecords = morbidityData.length - 1;
      const newTotalPages = Math.ceil(remainingRecords / recordsPerPage);
      if (currentPage > newTotalPages && currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }

      setDeleteLoading(null);
      alert("Record deleted successfully");
    } catch (err) {
      console.error("Error deleting morbidity record:", err);
      setError("Failed to delete record. Please try again later.");
      setDeleteLoading(null);
    }
  };

  // Pagination logic
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = morbidityData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const totalPages = Math.ceil(morbidityData.length / recordsPerPage);

  // Change page
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-32">
        <div className="text-white">Loading morbidity data...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 p-4 border border-red-700 bg-red-900 bg-opacity-30 rounded">
        {error}
        <button
          className="ml-4 underline"
          onClick={() => {
            setError(null);
            fetchMorbidityData();
          }}
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      {morbidityData.length === 0 ? (
        <div className="text-gray-400 p-4 text-center border border-gray-700">
          No morbidity records available
        </div>
      ) : (
        <>
          <table className="w-full text-sm text-left border border-gray-700 divide-y divide-gray-700">
            <thead className="text-xs uppercase bg-[#232323] text-white">
              <tr>
                <th className="px-4 py-3 border border-gray-700"></th>
                <th className="px-4 py-3 border border-gray-700">Species</th>
                <th className="px-4 py-3 border border-gray-700">
                  Tank Number
                </th>
                <th className="px-4 py-3 border border-gray-700">
                  Preserved (Yes/No)
                </th>
                <th className="px-4 py-3 border border-gray-700">Notes</th>
                <th className="px-4 py-3 border border-gray-700">Entered by</th>
                <th className="px-4 py-3 border border-gray-700">Date/Time</th>
                <th className="px-4 py-3 border border-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {currentRecords.map((record, index) => (
                <tr
                  key={record.id}
                  className="hover:bg-gray-700 bg-[#1C1C1E] text-white"
                >
                  <td className="p-3 border border-[#444] bg-[#333333]">
                    {indexOfFirstRecord + index + 1}
                  </td>
                  <td className="px-4 py-3 font-medium border border-gray-700">
                    {record.species}
                  </td>
                  <td className="px-4 py-3 border border-gray-700">
                    {record.tank}
                  </td>
                  <td className="px-4 py-3 border border-gray-700">
                    {record.preserved ? "Yes" : "No"}
                  </td>
                  <td className="px-4 py-3 border border-gray-700">
                    {record.notes}
                  </td>
                  <td className="px-4 py-3 border border-gray-700">
                    {record.name}
                  </td>
                  <td className="px-4 py-3 border border-gray-700">
                    {record.formattedDateTime}
                  </td>
                  <td className="px-4 py-2 text-left border border-gray-700">
                    <button
                      onClick={() => handleDeleteLog(record.id)}
                      className="text-red-500 hover:text-red-700"
                      title="Delete Record"
                    >
                      ✖
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-4">
            <button
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-[#232323] text-white rounded disabled:opacity-50"
            >
              Prev
            </button>
            <span className="text-white">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
              className="px-4 py-2 bg-[#232323] text-white rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default MorbidityTable;
