import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

const WaterQualityLHS = ({ showMenu = false }) => {
  const [showSubMenu, setShowSubMenu] = useState(showMenu);

  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  const handleNavigate = (path: string) => {
    navigate(path);
  }

  return (
    <div className="w-60 bg-[#232323] flex flex-col">
      <div
        onClick={() => navigate("/")}
        className="cursor-pointer justify-center align-middle text-center py-4 mb-6 px-4 py-3 p-6 mt-4 text-sm text-left bg-[#1C1C1E] text-white hover:text-white"
        style={{ borderRadius: 8 }}
      >
        Back to Dashboard
      </div>
      <div
        className="rounded-sm bg-[#707070] mb-4 text-white p-2 m-2 border-t border-r border-l"
        style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
      >
        <div className="py-2 px-2 border-b border-gray-400 cursor-pointer">
          Water Quality
        </div>
        <div
          onClick={() => handleNavigate('/water-quality')}
          className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between"
          style={{ opacity: isActive('/water-quality') ? 1 : 0.6, fontWeight:  isActive('/water-quality') ? 'bold' : undefined }}
        >
          <span className="text-sm"><span className='text-black'>☆</span> Water Quality Entry</span>
          <span className="text-xs text-black">⇧ A</span>
        </div>
        <div
          onClick={() => {
            toggleSubMenu();
            handleNavigate('/water-quality-logs');
          }}
          className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between"
          style={{ opacity: isActive('/water-quality-logs') ? 1 : 0.6, fontWeight:  isActive('/water-quality-logs') ? 'bold' : undefined }}
        >
          <span className="text-sm"><span className='text-black '>☆</span> Master Record</span>
          <span className="text-xs text-black">{showSubMenu ? "▼" : "▶"}</span>
        </div>

        {showSubMenu && (
          <div className="ml-8 ">
            <div onClick={() => { }} className="py-2 px-2 flex items-center cursor-pointer">
              <span className="text-sm">pH Log</span>
            </div>
            <div onClick={() => { }} className="py-2 px-2 flex items-center cursor-pointer">
              <span className="text-sm">Conductivity Log</span>
            </div>
            <div onClick={() => { }} className="py-2 px-2 flex items-center cursor-pointer">
              <span className="text-sm">Temperature Log</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WaterQualityLHS;