import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  sidebarItems,
  labManagementItems,
  sidebarCalendarItems,
} from "../utils/data";
import axios from "axios";
import { BASE_URL } from "../components/apiService";
import {
  googleLogout,
  useGoogleLogin,
  TokenResponse,
} from "@react-oauth/google";
import { gapi } from "gapi-script";

interface FeedingData {
  id: number;
  type: string;
  amount: string;
  room: string;
  room_category: string;
  notes: string;
  created_at: string;
  date: string;
  time: string;
  specialty_diet: number;
  diet_type: string | null;
  diet_amount: string | null;
  salt_added: string | null;
  species: string | null;
  tank_number: string | null;
}

interface ImportantDocument {
  id: number;
  document_name: string;
  uploaded_by: string;
  category: string;
  file_url: string;
  created_at: string;
}

interface CalendarEvent {
  id: number;
  time: string;
  assignedUser: string;
  notes: string;
  synced_with_google: number;
}

const HomePage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("Jobs");
  const [refreshGoogleCalendar, setRefreshGoogleCalendar] = useState(1);
  const navigate = useNavigate();
  const [feedingData, setFeedingData] = useState<FeedingData[]>([]);
  const [importantDocuments, setImportantDocuments] = useState<
    ImportantDocument[]
  >([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState<any>();
  const [selectedDocument, setSelectedDocument] = useState<FeedingData | null>(
    null
  );
  const [visibleDocs, setVisibleDocs] = useState(6);
  const [googleUser, setGoogleUser] = useState<Omit<
    TokenResponse,
    "error" | "error_description" | "error_uri"
  > | null>(null);
  const [googleProfile, setGoogleProfile] = useState<any | null>(null);

  const calendarID = "labmng07@gmail.com";
  const apiKey: string = process.env.REACT_APP_GOOGLE_API_KEY || "";

  // Calendar event related states
  const [isEventDialogOpen, setIsEventDialogOpen] = useState(false);
  const [eventData, setEventData] = useState<CalendarEvent>({
    id: 0,
    time: "",
    assignedUser: "",
    notes: "",
    synced_with_google: 0,
  });

  const [unsyncedEventData, setUnsyncedEventData] = useState<CalendarEvent[]>(
    []
  );

  const login = useGoogleLogin({
    scope: [
      "https://www.googleapis.com/auth/calendar.events.public.readonly",
      "https://www.googleapis.com/auth/calendar",
      "https://www.googleapis.com/auth/calendar.events",
      "https://www.googleapis.com/auth/calendar.calendarlist",
      "https://www.googleapis.com/auth/calendar.events.owned",
    ].join(" "),
    onSuccess: (codeResponse) => {
      sessionStorage.setItem("googleUser", JSON.stringify(codeResponse));
      setGoogleUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    // fetchFeedingData();
    const tempGoogleUser = sessionStorage.getItem("googleUser");
    if (tempGoogleUser) {
      setGoogleUser(JSON.parse(tempGoogleUser));
    }
    fetchImportantDocuments();
  }, []);

  useEffect(() => {
    if (googleUser) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${googleUser.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setGoogleProfile(res.data);
          sessionStorage.setItem("googleProfile", JSON.stringify(res.data));
          setRefreshGoogleCalendar(refreshGoogleCalendar + 1);
          getUnsyncedCalendarEvents();
          // const calendarList = getGoogleCalendarId(apiKey);
          // const events = getEvents(calendarID, apiKey);
          // setCalendarEvents(events);
        })
        .catch((err) => {
          console.log(err);
          alert("You have been logged out, please login again.");
          logOut();
        });
    }
  }, [googleUser]);

  useEffect(() => {
    if (
      unsyncedEventData &&
      unsyncedEventData.length > 0 &&
      googleUser &&
      googleUser.access_token
    ) {
      console.log(unsyncedEventData);
      unsyncedEventData.forEach((event) => {
        var tempEvent = {
          summary: event.notes + " - " + event.assignedUser,
          location: "",
          start: {
            dateTime: new Date(event.time).toISOString(),
            timeZone: "America/Los_Angeles",
          },
          end: {
            dateTime: new Date(
              new Date(event.time).getTime() + 60 * 60 * 1000
            ).toISOString(),
            timeZone: "America/Los_Angeles",
          },
          attendees: [],
          reminders: {
            useDefault: false,
            overrides: [
              { method: "email", minutes: 24 * 60 },
              { method: "popup", minutes: 10 },
            ],
          },
        };
        console.log("add event for tempEvent:", tempEvent);
        addEvent(calendarID, tempEvent, googleUser);
      });
      setRefreshGoogleCalendar(refreshGoogleCalendar + 1);
      const unsyncedEventIds = unsyncedEventData.map((event) => event.id);
      const unsyncedEventIdsString = unsyncedEventIds.join(",");
      markUnsyncedEventsAsSynced(unsyncedEventIdsString);
    }
  }, [unsyncedEventData, googleUser]);

  const markUnsyncedEventsAsSynced = async (unsyncedEventIdsString: string) => {
    try {
      if (!unsyncedEventIdsString || !unsyncedEventIdsString.length) {
        return;
      }

      const response = await axios.post(`${BASE_URL}/synced-calendar-events`, {
        calendar_ids: unsyncedEventIdsString,
      });

      if (response.status === 201) {
        setUnsyncedEventData([]);
      }
    } catch (error) {
      console.error("Error creating calendar event:", error);
    }
  };

  const getGoogleCalendarId = async (apiKey: string) => {
    function initiate() {
      gapi.client
        .init({
          apiKey: apiKey,
        })

        .then(function () {
          return gapi.client.request({
            path: `https://www.googleapis.com/calendar/v3/users/me/calendarList`,
            headers: {
              Authorization: `Bearer ${googleUser?.access_token}`,
              Accept: "application/json",
            },
          });
        })

        .then(
          (response: any) => {
            console.log(response);
            let calendarList = response.result.items;
            return calendarList;
          },
          function (err: any) {
            console.log(err);
            return [false, err];
          }
        );
    }

    gapi.load("client", initiate);
  };

  const addEvent = (calendarID: string, event: any, googleUserObj: any) => {
    function initiate() {
      gapi.client
        .request({
          path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
          method: "POST",
          body: event,
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${googleUserObj.access_token}`,
          },
        })
        .then(
          (response: any) => {
            console.log(response);
            return [true, response];
          },
          function (err: any) {
            console.log(err);
            return [false, err];
          }
        );
    }
    gapi.load("client", initiate);
  };

  const getEvents = (calendarID: string, apiKey: string) => {
    function initiate() {
      gapi.client
        .init({
          apiKey: apiKey,
        })

        .then(function () {
          return gapi.client.request({
            path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
          });
        })

        .then(
          (response: any) => {
            console.log(response);
            let events = response.result.items;
            return events;
          },
          function (err: any) {
            console.log(err);
            return [false, err];
          }
        );
    }

    gapi.load("client", initiate);
  };

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    sessionStorage.removeItem("googleUser");
    sessionStorage.removeItem("googleProfile");
    googleLogout();
    setGoogleProfile(null);
    setGoogleUser(null);
  };

  const googleLoginResponseMessage = (response: any) => {
    console.log("Google login response:");
    console.log(response);
  };
  const googleLoginErrorMessage = () => {
    console.log("Google login error");
  };

  const fetchFeedingData = async () => {
    try {
      const response = await axios.get<FeedingData[]>(`${BASE_URL}/feeding`);
      setFeedingData(response.data);
    } catch (error) {
      console.error("Error fetching feeding data:", error);
    }
  };

  const fetchImportantDocuments = async () => {
    try {
      const response = await axios.get<ImportantDocument[]>(
        `${BASE_URL}/important-documents`
      );
      setImportantDocuments(response.data);
    } catch (error) {
      console.error("Error fetching important documents:", error);
    }
  };

  const getFileExtension = (url: string) => {
    if (!url) return "";
    const parts = url.split(".");
    return parts.length > 1 ? parts[parts.length - 1].toUpperCase() : "";
  };

  const handleNavigation = (item: string) => {
    if (item === "Feeding" || item === "fish feeding") {
      navigate("/feeding/f2");
    } else if (item === "water quality") {
      navigate("/water-quality");
    } else if (item === "Fish Morbidity") {
      navigate("/MorbidityEntry");
    } else if (item === "tank cleaning") {
      navigate("/tank-cleaning");
    } else if (item === "Fish Birth") {
      navigate("/BirthEntryPage");
    } else if (item === "All Documents") {
      navigate("/AllDocuments");
    } else if (item === "Room Reservations") {
      navigate("/RoomBooking");
    } else if (item === "Task Manager") {
      navigate("/");
    } else if (item === "Lab Personnel Hours") {
      navigate("/lab-personal-hours");
    }
  };

  const handleDocumentClick = (document: FeedingData) => {
    setSelectedDocument(document);
    setIsDialogOpen(true);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const loadMoreDocuments = () => {
    setVisibleDocs((prevVisibleDocs) => prevVisibleDocs + 6);
  };

  const handleOpenEventDialog = () => {
    setIsEventDialogOpen(true);
  };

  const handleCloseEventDialog = () => {
    setIsEventDialogOpen(false);
    setEventData({
      id: 0,
      time: "",
      assignedUser: "",
      notes: "",
      synced_with_google: 0,
    });
  };

  const handleEventInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEventData({
      ...eventData,
      [name]: value,
    });
  };

  const getUnsyncedCalendarEvents = async () => {
    try {
      const response = await axios.get<CalendarEvent[]>(
        `${BASE_URL}/calendar-events`
      );

      if (response.status === 200) {
        setUnsyncedEventData(response.data);
        if (!googleUser || !googleUser.access_token) {
          alert("Please login to google to sync events.");
          return;
        }
      }
    } catch (error) {
      console.error("Error getting calendar event:", error);
    }
  };

  const handleSubmitEvent = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (!eventData.time || !eventData.assignedUser || !eventData.notes) {
        alert("All fields are required");
        return;
      }

      const response = await axios.post(
        `${BASE_URL}/calendar-events`,
        eventData
      );

      if (response.status === 201) {
        alert("Event created successfully!");
        getUnsyncedCalendarEvents();
        handleCloseEventDialog();
      }
    } catch (error) {
      console.error("Error creating calendar event:", error);
      alert("Failed to create event. Please try again.");
    }
  };

  return (
    <div className="bg-[#262626] text-[#858585] min-h-screen flex">
      <aside className="w-60 bg-[#232323] p-6">
        <div className="flex space-x-4 mb-6">
          <button
            className={`px-4 py-2 rounded ${
              selectedTab === "Jobs" ? "bg-[#1C1C1E]" : ""
            }`}
            onClick={() => setSelectedTab("Jobs")}
          >
            Jobs
          </button>
          <button
            className={`px-4 py-2 rounded ${
              selectedTab === "Calendar" ? "bg-[#1C1C1E]" : ""
            }`}
            onClick={() => setSelectedTab("Calendar")}
          >
            Calendar
          </button>
        </div>
        <nav>
          <ul className="space-y-2">
            {(selectedTab === "Jobs" ? sidebarItems : sidebarCalendarItems).map(
              (item, index) => (
                <li
                  key={index}
                  className="p-2 rounded hover:bg-[#1C1C1E] cursor-pointer"
                  onClick={() => handleNavigation(item)}
                >
                  {item}
                </li>
              )
            )}
          </ul>
        </nav>
      </aside>

      <main className="flex-1 bg-[#1E1E1E]">
        <header className="flex justify-between items-center p-6 bg-[#262626]">
          <div className="flex items-center space-x-4">
            <div className="bg-[#5D5D5D] rounded-full w-10 h-10 flex items-center justify-center">
              {/* Placeholder for user image */}
            </div>
            <div>
              <h1 className="text-xl font-semibold">Welcome John Doe</h1>
              <p className="text-sm text-gray-500">The Doe Lab</p>
            </div>
          </div>
          {/* <button className="bg-[#1C1C1E] px-4 py-2 text-white rounded">
            Check In
          </button> */}
        </header>

        <div className="flex flex-col p-6 ">
          <section className="mb-6">
            <h2 className="text-lg font-semibold mb-4">Lab Management</h2>
            <div className="grid grid-cols-7">
              {labManagementItems.map((item, index) => (
                <div
                  key={index}
                  className="text-center cursor-pointer"
                  onClick={() => handleNavigation(item.name)}
                >
                  <div className="bg-[#323232] w-32 h-20 mx-auto mb-2 rounded-md flex items-center justify-center">
                    <img
                      src={item.icon}
                      alt={item.name}
                      className="w-10 h-10"
                    />
                  </div>
                  <p className="text-xs mt-1">{item.name}</p>
                </div>
              ))}
            </div>
          </section>
          <section className="flex-1">
            <br />
            <div className="flex justify-between items-center mb-4">
              <div className="flex space-x-4">
                {googleProfile ? (
                  <div className="flex items-center space-x-4">
                    <div>
                      <p>Name: {googleProfile.name}</p>
                      <p>Email Address: {googleProfile.email}</p>
                    </div>
                    <button
                      onClick={logOut}
                      className="bg-[#1C1C1E] px-4 py-2 text-white rounded"
                    >
                      Log out
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => login()}
                    className="bg-[#1C1C1E] px-4 py-2 text-white rounded"
                  >
                    Sign in with Google
                  </button>
                )}
                <button
                  onClick={handleOpenEventDialog}
                  className="bg-[#1C1C1E] px-4 py-2 text-white rounded"
                >
                  Create Event
                </button>
              </div>
            </div>
          </section>
          <div className="flex space-x-6">
            <section className="flex-1">
              {/*</section><div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">Calendar</h2>
                <div className="flex items-center space-x-2">
                  <select className="bg-[#323232] p-2 rounded text-sm">
                    <option>January</option>
                  </select>
                  <button className="p-1 text-sm">&#9664;</button>
                  <button className="p-1 text-sm">&#9654;</button>
                </div>
              </div>
              <div className="grid grid-cols-7 gap-1">
                {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                  (day) => (
                    <div
                      key={day}
                      className="text-center p-2 bg-[#323232] text-xs"
                    >
                      {day}
                    </div>
                  )
                )}
                {[...Array(28)].map((_, i) => (
                  <div
                    key={i}
                    className="bg-[#323232] p-1 rounded-sm h-20 text-xs"
                  >
                    {/* Calendar day content
                  </div>
                ))}
              </div>*/}
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">Lab Calendar</h2>
              </div>
              <div className="bg-white rounded-lg overflow-hidden">
                <iframe
                  key={refreshGoogleCalendar}
                  src="https://calendar.google.com/calendar/embed?height=800&wkst=1&ctz=America%2FLos_Angeles&showPrint=0&mode=WEEK&showCalendars=0&showTabs=0&src=bGFibW5nMDdAZ21haWwuY29t&color=%23039BE5"
                  style={{ borderWidth: 0 }}
                  width="100%"
                  height="800"
                  frameBorder="0"
                  scrolling="no"
                  title="Lab Calendar"
                ></iframe>
              </div>
            </section>

            <section className="w-72">
              <h2 className="text-lg font-semibold mb-4">
                Important Documents
              </h2>
              {importantDocuments.length > 0 ? (
                <>
                  <ul className="space-y-2">
                    {importantDocuments.slice(0, visibleDocs).map((doc) => (
                      <li
                        key={doc.id}
                        className="bg-[#323232] p-3 rounded text-xs cursor-pointer hover:bg-[#3a3a3a]"
                      >
                        <div className="flex justify-between items-center">
                          <a
                            href={doc.file_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-semibold hover:text-white"
                          >
                            {doc.document_name}
                          </a>
                          <span className="text-[#858585] italic">
                            Uploaded by: {doc.uploaded_by}
                          </span>
                        </div>
                        <div className="flex justify-between mt-2">
                          <span className="text-[#858585]">
                            {getFileExtension(doc.file_url)}
                          </span>
                          <span className="text-[#858585]">
                            {formatDate(doc.created_at)}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                  {importantDocuments.length > visibleDocs && (
                    <button
                      className="mt-4 bg-[#1C1C1E] px-4 py-2 text-white rounded w-full"
                      onClick={loadMoreDocuments}
                    >
                      More
                    </button>
                  )}
                </>
              ) : (
                <div className="bg-[#323232] p-6 rounded text-center">
                  <p className="text-[#858585]">No Documents Available</p>
                </div>
              )}
            </section>
          </div>
        </div>
      </main>

      {/* Document Details Dialog */}
      {isDialogOpen && selectedDocument && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#323232] p-6 rounded-lg max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">
              {selectedDocument.type} Feeding Details
            </h3>
            <div className="space-y-2">
              <p>
                <strong>Food Type:</strong> {selectedDocument.type}
              </p>
              <p>
                <strong>Amount:</strong> {selectedDocument.amount}
              </p>
              <p>
                <strong>Room Category:</strong> {selectedDocument.room_category}
              </p>
              <p>
                <strong>Date:</strong> {formatDate(selectedDocument.date)}
              </p>
              <p>
                <strong>Time:</strong> {selectedDocument.time}
              </p>
              <p>
                <strong>Notes:</strong> {selectedDocument.notes}
              </p>
              {selectedDocument.specialty_diet === 1 && (
                <>
                  <p>
                    <strong>Specialty Diet:</strong> Yes
                  </p>
                  {selectedDocument.diet_type && (
                    <p>
                      <strong>Diet Type:</strong> {selectedDocument.diet_type}
                    </p>
                  )}
                  {selectedDocument.diet_amount && (
                    <p>
                      <strong>Diet Amount:</strong>{" "}
                      {selectedDocument.diet_amount}
                    </p>
                  )}
                </>
              )}
              {selectedDocument.species && (
                <p>
                  <strong>Species:</strong> {selectedDocument.species}
                </p>
              )}
              {selectedDocument.tank_number && (
                <p>
                  <strong>Tank Number:</strong> {selectedDocument.tank_number}
                </p>
              )}
              {selectedDocument.salt_added && (
                <p>
                  <strong>Salt Added:</strong> {selectedDocument.salt_added}
                </p>
              )}
              <p>
                <strong>Created At:</strong>{" "}
                {formatDate(selectedDocument.created_at)}
              </p>
            </div>
            <button
              className="mt-4 bg-[#1C1C1E] px-4 py-2 text-white rounded"
              onClick={() => setIsDialogOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Calendar Event Creation Dialog */}
      {isEventDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#323232] p-6 rounded-lg max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">
              Create Calendar Event
            </h3>
            <form onSubmit={handleSubmitEvent}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-1">Time</label>
                  <input
                    type="datetime-local"
                    name="time"
                    value={eventData.time}
                    onChange={handleEventInputChange}
                    className="w-full p-2 rounded bg-[#262626] border border-[#3a3a3a]"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1">
                    Assigned User
                  </label>
                  <input
                    type="text"
                    name="assignedUser"
                    value={eventData.assignedUser}
                    onChange={handleEventInputChange}
                    className="w-full p-2 rounded bg-[#262626] border border-[#3a3a3a]"
                    placeholder="Enter name"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1">
                    Additional Notes
                  </label>
                  <textarea
                    name="notes"
                    value={eventData.notes}
                    onChange={handleEventInputChange}
                    className="w-full p-2 rounded bg-[#262626] border border-[#3a3a3a] min-h-[100px]"
                    placeholder="Enter event details"
                    required
                  />
                </div>

                <div className="flex space-x-4">
                  <button
                    type="submit"
                    className="bg-[#1C1C1E] px-4 py-2 text-white rounded flex-1"
                  >
                    Create Event
                  </button>
                  <button
                    type="button"
                    onClick={handleCloseEventDialog}
                    className="bg-[#3a3a3a] px-4 py-2 text-white rounded flex-1"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
