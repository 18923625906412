import React, { useState, useEffect } from "react";
import axios from "axios";
import { darkBgColor, LightDarkBgColor } from "../../utils/utils";
import { BASE_URL } from "../../components/apiService";

// Define interface based on your API response
interface BirthLogEntry {
  id: number;
  date: string;
  time: string;
  room: string;
  name: string;
  species: string;
  tank: string;
  parents: string;
  num_of_fish: number;
  seperated: string;
  notes: string;
  created_at: string;
}

const BirthTable: React.FC = () => {
  const [birthData, setBirthData] = useState<BirthLogEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const recordsPerPage = 5;

  useEffect(() => {
    const fetchBirthData = async () => {
      try {
        setLoading(true);
        const response = await axios.get<BirthLogEntry[]>(
          `${BASE_URL}/birth-log`
        );

        const sortedData = [...response.data].sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });

        setBirthData(sortedData);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching birth data:", err);
        setError("Failed to load birth data. Please try again later.");
        setLoading(false);
      }
    };

    fetchBirthData();
  }, []);

  const formatDateField = (dateString: string | null): string => {
    if (!dateString) return "-";

    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  // Calculate pagination values
  const totalPages = Math.ceil(birthData.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = birthData.slice(indexOfFirstRecord, indexOfLastRecord);

  // Pagination controls
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Delete handler
  const handleDeleteLog = async (id: number) => {
    if (!window.confirm("Are you sure you want to delete this record?")) {
      return;
    }

    try {
      const response = await axios.delete(`${BASE_URL}/birth-log/${id}`);

      if (response.status === 200) {
        // Update the state by removing the deleted log
        const updatedLogs = birthData.filter((log) => log.id !== id);
        setBirthData(updatedLogs);

        // Show success message
        setError(null);
        alert("Record deleted successfully");

        // Adjust the current page if needed after deletion
        if (currentRecords.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      }
    } catch (error) {
      console.error("Error deleting birth log:", error);
      setError("Failed to delete birth log");
    }
  };

  if (loading) {
    return (
      <div className="text-center p-4 text-white">Loading birth data...</div>
    );
  }

  if (error) {
    return <div className="text-center p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="w-full text-sm text-left border border-gray-700 divide-y divide-gray-700">
        <thead className="text-xs uppercase bg-[#232323] text-white">
          <tr>
            <th className="px-4 py-3 border border-gray-700"></th>
            <th className="px-4 py-3 border border-gray-700">Date</th>
            <th className="px-4 py-3 border border-gray-700">Time</th>
            <th className="px-4 py-3 border border-gray-700">Room</th>
            <th className="px-4 py-3 border border-gray-700">Name</th>
            <th className="px-4 py-3 border border-gray-700">Species</th>
            <th className="px-4 py-3 border border-gray-700">Tank</th>
            <th className="px-4 py-3 border border-gray-700">Parents</th>
            <th className="px-4 py-3 border border-gray-700">Number of Fish</th>
            <th className="px-4 py-3 border border-gray-700">Separated</th>
            <th className="px-4 py-3 border border-gray-700">Notes</th>
            <th className="px-4 py-3 border border-gray-700">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-700">
          {currentRecords.length === 0 ? (
            <tr className="bg-[#1C1C1E] text-white">
              <td colSpan={12} className="px-4 py-3 text-center">
                No birth records found
              </td>
            </tr>
          ) : (
            currentRecords.map((entry, index) => (
              <tr
                key={entry.id || index}
                className="hover:bg-gray-700 bg-[#1C1C1E] text-white"
              >
                <td className="p-3 border border-[#444] bg-[#333333]">
                  {indexOfFirstRecord + index + 1}
                </td>
                <td className="px-4 py-3 border border-gray-700">
                  {formatDateField(entry.date)}
                </td>
                <td className="px-4 py-3 border border-gray-700">
                  {entry.time}
                </td>
                <td className="px-4 py-3 border border-gray-700">
                  {entry.room}
                </td>
                <td className="px-4 py-3 font-medium border border-gray-700">
                  {entry.name}
                </td>
                <td className="px-4 py-3 border border-gray-700">
                  {entry.species}
                </td>
                <td className="px-4 py-3 border border-gray-700">
                  {entry.tank}
                </td>
                <td className="px-4 py-3 border border-gray-700">
                  {entry.parents}
                </td>
                <td className="px-4 py-3 border border-gray-700">
                  {entry.num_of_fish}
                </td>
                <td className="px-4 py-3 border border-gray-700">
                  {entry.seperated}
                </td>
                <td className="px-4 py-3 border border-gray-700">
                  {entry.notes || "—"}
                </td>
                <td className="px-4 py-3 border border-gray-700">
                  <button
                    onClick={() => handleDeleteLog(entry.id)}
                    className="text-red-500 hover:text-red-700"
                    title="Delete Record"
                  >
                    ✖
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {/* Pagination Controls */}
      {birthData.length > 0 && (
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={prevPage}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-[#232323] text-white rounded disabled:opacity-50"
          >
            Prev
          </button>
          <span className="text-white">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={nextPage}
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-[#232323] text-white rounded disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default BirthTable;
