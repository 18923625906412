import React, { useState, useEffect } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { BASE_URL } from "./apiService";
import axios from "axios";

// Define interfaces for type safety
interface FeedingLog {
  id: number;
  date: string;
  type: string;
  amount: string | number;
}

interface FoodType {
  id: number;
  food_name: string;
}

// Function to generate a color based on a string hash
const stringToColor = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
};

const FoodDistributionPieChart: React.FC = () => {
  const [pieData, setPieData] = useState<
    { id: number; value: number; label: string; color: string }[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [typeOptions, setTypeOptions] = useState<string[]>([]);

  // Fetch food types from API
  useEffect(() => {
    const fetchFoodTypes = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/food-types`);

        // Check if response.data is an array
        if (Array.isArray(response.data)) {
          const foodTypesData = response.data.map(
            (item: { food_name: string }) => item.food_name
          );
          setTypeOptions(foodTypesData);
        }
        // If response.data is an object with a data property that is an array
        else if (response.data && Array.isArray(response.data.data)) {
          const foodTypesData = response.data.data.map(
            (item: { food_name: string }) => item.food_name
          );
          setTypeOptions(foodTypesData);
        }
        // If the structure is different, handle the format from the example
        else {
          setTypeOptions([]);
        }
      } catch (error) {
        setTypeOptions([]);
      }
    };

    fetchFoodTypes();
  }, []);

  // Fetch feeding logs and calculate data for pie chart
  useEffect(() => {
    const fetchFeedingLogs = async () => {
      // Don't fetch if we don't have food types yet
      if (typeOptions.length === 0) return;

      try {
        setIsLoading(true);
        const response = await fetch(`${BASE_URL}/feeding-log`, {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        });

        const responseText = await response.text();

        let data;
        try {
          data = JSON.parse(responseText);
        } catch (parseError) {
          throw new Error("Invalid JSON response");
        }

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Ensure data is an array, even if it's wrapped in another object
        const logArray = data.logs || [];

        // Initialize food type amounts with 0 for all food types
        const foodTypeAmounts = typeOptions.reduce((acc, type) => {
          acc[type] = 0;
          return acc;
        }, {} as Record<string, number>);

        // Add actual amounts from all logs
        logArray.forEach((log: FeedingLog) => {
          if (foodTypeAmounts.hasOwnProperty(log.type)) {
            // Convert amount to number, handling string inputs
            const amount =
              typeof log.amount === "string"
                ? parseFloat(log.amount)
                : log.amount;
            foodTypeAmounts[log.type] += amount;
          }
        });

        // Calculate total amount of food distributed
        const totalAmount = Object.values(foodTypeAmounts).reduce(
          (sum, amount) => sum + amount,
          0
        );

        // Transform to pie chart data with percentages
        const pieChartData = typeOptions.map((type, index) => {
          const value = foodTypeAmounts[type];
          const percentage =
            totalAmount > 0
              ? Number(((value / totalAmount) * 100).toFixed(1))
              : 0;

          return {
            id: index,
            value: percentage,
            label: `${type} (${percentage}%)`,
            color: stringToColor(type), // Generate color based on food name
          };
        });

        setPieData(pieChartData);
        setError(null);
      } catch (error) {
        setError(error instanceof Error ? error.message : String(error));

        // Create default pie data with 0% for all types
        const defaultPieData = typeOptions.map((type, index) => ({
          id: index,
          value: 0,
          label: `${type} (0%)`,
          color: stringToColor(type), // Generate color based on food name
        }));

        setPieData(defaultPieData);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFeedingLogs();
  }, [typeOptions]); // Added typeOptions to dependencies

  return (
    <div className="bg-[#232323] lg:w-[60%] w-[100%] p-4 rounded-lg">
      <h2 className="text-xl font-bold mb-4 text-white">
        Distribution of Fish Food Types
      </h2>

      {isLoading ? (
        <p className="text-white">Loading...</p>
      ) : error ? (
        <p className="text-red-500">Error: {error}</p>
      ) : pieData.length > 0 ? (
        <PieChart
          series={[
            {
              data: pieData,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: { innerRadius: 40, additionalRadius: -30, color: "gray" },
            },
          ]}
          width={600} // Increased width to accommodate the legend
          height={300}
          colors={pieData.map((item) => item.color)}
          slotProps={{
            legend: {
              direction: "column",
              position: { vertical: "middle", horizontal: "right" },
              padding: 0,
              labelStyle: {
                fontSize: 14,
                fill: "#FFFFFF", // White text for better visibility
              },
            },
          }}
        />
      ) : (
        <p className="text-white">No feeding logs found</p>
      )}
    </div>
  );
};

export default FoodDistributionPieChart;
