import FishFeedingIcon from "../assets/images/fishfeeding.svg";
import WaterQualityIcon from "../assets/images/waterquality.svg";
import TankCleaningIcon from "../assets/images/Vector.svg";
import DataLogIcon1 from "../assets/images/data1.svg";
import DataLogIcon2 from "../assets/images/data2.svg";
import DataLogIcon3 from "../assets/images/data3.svg";
import DataLogIcon4 from "../assets/images/data4.svg";
import FlakesIcon from "../assets/images/flakes.svg";
import PelletsIcon from "../assets/images/pellets.svg";
import OtherFoodIcon from "../assets/images/others.svg";
// import room from '../assets/images/room.svg';
export const room = require("../assets/images/room.svg").default;
export const nut1 = require("../assets/images/nutrient1.svg").default;
export const nut2 = require("../assets/images/nutrients2.svg").default;

export const sidebarItems = [
  // "Feeding",
  "Task Manager",
  "All Documents",
];
export const sidebarCalendarItems = [
  "Lab Personnel Hours",
  "Room Reservations",
];

export const labManagementItems = [
  { name: "fish feeding", icon: FishFeedingIcon },
  { name: "water quality", icon: WaterQualityIcon },
  { name: "tank cleaning", icon: TankCleaningIcon },
  { name: "Fish Morbidity", icon: DataLogIcon1 },
  { name: "Fish Birth", icon: DataLogIcon2 },
];

// export const importantDocuments = [
//   'December 31, 2024 Fish Log',
//   'Fish Feeding Chart',
//   'Lab Safety Waiver',
//   'Feeding Color Guide',
//   'Fish Mortality Log',
//   'Fish Feeding Procedures',
//   'Health and Safety Contact',
// ];

export const foodTypes = [
  { name: "Flakes", icon: FlakesIcon },
  { name: "Pellets", icon: PelletsIcon },
  { name: "Brine Shrimp", icon: FlakesIcon },
  { name: "Other", icon: OtherFoodIcon },
];

export const fishTanks = ["Room A", "Room B", "Room C"];

export const nutrients = [
  { id: "crude_protein", name: "Crude Protein" },
  { id: "crude_fat", name: "Crude Fat" },
  { id: "crude_fiber", name: "Crude Fiber" },
  { id: "moisture", name: "Moisture" },
  { id: "ash", name: "Ash" },
  { id: "phosphorus", name: "Phosphorus" },
  { id: "calcium", name: "Calcium" },
  { id: "vitamin_a", name: "Vitamin A" },
  { id: "vitamin_d3", name: "Vitamin D3" },
  { id: "vitamin_e", name: "Vitamin E" },
];
