import React, { useState, useEffect } from "react";
import { ChevronLeft, X, ChevronDown, AlertCircle } from "lucide-react";
import { formatDate, formatTime, LightDarkBgColor } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import CategoryButton from "../components/CategoryButton";
import TankCleaningLHS from "./TankCleaningLHS";
import { BASE_URL } from "../components/apiService";

function TankCleaning() {
  const [currentDateTime, setCurrentDateTime] = useState<Date>(new Date());
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [stopTime, setStopTime] = useState<Date | null>(null);
  const [selectedRoom, setSelectedRoom] = useState<string>("Lower Level");
  const [selectedRack, setSelectedRack] = useState<string | null>(null);
  const [notes, setNotes] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [sessionActive, setSessionActive] = useState<boolean>(false);

  // Load session data from localStorage on component mount
  useEffect(() => {
    const savedSession = localStorage.getItem("tankCleaningSession");

    if (savedSession) {
      const sessionData = JSON.parse(savedSession);

      // Only restore if the session is still active (no stopTime)
      if (!sessionData.stopTime) {
        setSessionActive(true);
        setUserName(sessionData.userName || "");
        setSelectedRoom(sessionData.selectedRoom || "Lower Level");
        setSelectedRack(sessionData.selectedRack || null);
        setNotes(sessionData.notes || "");
        setStartTime(
          sessionData.startTime ? new Date(sessionData.startTime) : null
        );
      } else {
        // Clear localStorage if session is complete
        localStorage.removeItem("tankCleaningSession");
      }
    }
  }, []);

  // Handle toast visibility timeouts
  useEffect(() => {
    if (errorMessage) {
      setShowErrorToast(true);
      const timer = setTimeout(() => {
        setShowErrorToast(false);
        setTimeout(() => setErrorMessage(""), 300);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  // Handle success toast visibility
  useEffect(() => {
    if (successMessage) {
      setShowSuccessToast(true);
      const timer = setTimeout(() => {
        setShowSuccessToast(false);
        setTimeout(() => setSuccessMessage(""), 300);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  // Check if user has an active session on load or name change
  useEffect(() => {
    if (userName.trim() && !sessionActive) {
      checkActiveSession();
    }
  }, [userName]);

  // Save session data to localStorage whenever relevant state changes
  useEffect(() => {
    if (sessionActive && startTime) {
      const sessionData = {
        userName,
        selectedRoom,
        selectedRack,
        notes,
        startTime: startTime.toISOString(),
        stopTime: stopTime ? stopTime.toISOString() : null,
        sessionActive,
      };

      localStorage.setItem("tankCleaningSession", JSON.stringify(sessionData));
    } else if (!sessionActive) {
      // Clear localStorage when no active session
      localStorage.removeItem("tankCleaningSession");
    }
  }, [
    sessionActive,
    userName,
    selectedRoom,
    selectedRack,
    notes,
    startTime,
    stopTime,
  ]);

  const checkActiveSession = async () => {
    if (!userName.trim()) return;

    try {
      const response = await fetch(
        `${BASE_URL}/tank-cleaning-logs/check-active?name=${encodeURIComponent(
          userName
        )}`
      );
      const data = await response.json();

      if (response.ok && data.hasActiveSession) {
        setSessionActive(true);
        setStartTime(new Date(data.session.startTime));

        // If available from the API, update other fields too
        if (data.session.room) setSelectedRoom(data.session.room);
        if (data.session.rack) setSelectedRack(data.session.rack);
        if (data.session.notes) setNotes(data.session.notes);

        // Save session to localStorage
        const sessionData = {
          userName,
          selectedRoom: data.session.room || selectedRoom,
          selectedRack: data.session.rack || selectedRack,
          notes: data.session.notes || notes,
          startTime: data.session.startTime,
          stopTime: null,
          sessionActive: true,
        };

        localStorage.setItem(
          "tankCleaningSession",
          JSON.stringify(sessionData)
        );
      } else {
        setSessionActive(false);
        setStartTime(null);
        setStopTime(null);
      }
    } catch (error) {
      console.error("Error checking active session:", error);
    }
  };

  const newDateValue = (dateValue: any) => {
    const updatedDate = new Date(dateValue);
    const currentTime = new Date(currentDateTime);

    updatedDate.setHours(currentTime.getHours());
    updatedDate.setMinutes(currentTime.getMinutes());

    return updatedDate;
  };

  const newTimeValue = (timeValue: any) => {
    const [hours, minutes] = timeValue.split(":");
    const updatedTime = new Date(currentDateTime);

    updatedTime.setHours(parseInt(hours));
    updatedTime.setMinutes(parseInt(minutes));

    return updatedTime;
  };

  const formattedStartTime = startTime
    ? startTime.toLocaleString("en-US", {
        dateStyle: "medium",
        timeStyle: "short",
      })
    : "";

  const formattedStopTime = stopTime
    ? stopTime.toLocaleString("en-US", {
        dateStyle: "medium",
        timeStyle: "short",
      })
    : "";

  // Calculate total time worked
  const calculateTotalTimeWorked = (start: Date, stop: Date) => {
    const diffMs = stop.getTime() - start.getTime();
    const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
    const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
    const diffSecs = Math.floor((diffMs % (1000 * 60)) / 1000);

    return `${diffHrs} hrs ${diffMins} mins ${diffSecs} secs`;
  };

  const validateForm = (): boolean => {
    if (!userName.trim()) {
      setErrorMessage("Name is required before starting");
      return false;
    }

    if (!selectedRack) {
      setErrorMessage("Rack selection is required");
      return false;
    }

    return true;
  };

  const resetForm = () => {
    setStartTime(null);
    setStopTime(null);
    setSelectedRack(null);
    setNotes("");
    setSessionActive(false);

    // Clear local storage on reset
    localStorage.removeItem("tankCleaningSession");
  };

  const handleStartClick = async () => {
    // Validate form before proceeding
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    const now = new Date();

    // Format date as YYYY-MM-DD for the database
    const sqlDate = now.toISOString().split("T")[0];
    // Format time as HH:MM:SS for the database
    const sqlTime = now.toTimeString().split(" ")[0];

    const logEntry = {
      name: userName,
      date: sqlDate,
      time: sqlTime,
      room: selectedRoom,
      rack: selectedRack,
      notes: notes,
      startTime: now.toISOString(),
    };

    try {
      const response = await fetch(`${BASE_URL}/tank-cleaning-logs/start`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(logEntry),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to save log entry");
      }

      const data = await response.json();
      console.log("Log entry saved:", data);

      // Update UI after successful API call
      setStartTime(now);
      setCurrentDateTime(now);
      setSessionActive(true);
      setSuccessMessage("Tank cleaning started successfully");

      // Save to localStorage
      const sessionData = {
        userName,
        selectedRoom,
        selectedRack,
        notes,
        startTime: now.toISOString(),
        stopTime: null,
        sessionActive: true,
      };

      localStorage.setItem("tankCleaningSession", JSON.stringify(sessionData));
    } catch (error: any) {
      console.error("Error saving log entry:", error);
      if (
        error.message.includes("duplicate") ||
        error.message.includes("unique") ||
        error.message.includes("active cleaning session")
      ) {
        setErrorMessage("This name already has an active cleaning session");
      } else {
        setErrorMessage(error.message || "Failed to start cleaning session");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleStopClick = async () => {
    if (!userName.trim()) {
      setErrorMessage("Name is required");
      return;
    }

    setIsLoading(true);
    const now = new Date();

    if (!startTime) {
      setErrorMessage("No active session to stop");
      setIsLoading(false);
      return;
    }

    const totalTimeWorked = calculateTotalTimeWorked(startTime, now);

    const logEntry = {
      name: userName,
      stopTime: now.toISOString(),
      totalTimeWorked: totalTimeWorked,
    };

    try {
      const response = await fetch(`${BASE_URL}/tank-cleaning-logs/stop`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(logEntry),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || "Failed to update log entry with stop time"
        );
      }

      const data = await response.json();

      // Check if any records were updated
      if (
        data.affectedRows === 0 ||
        data.message === "No active session found"
      ) {
        setErrorMessage("No active cleaning session found for this name");
        return;
      }

      console.log("Log entry updated with stop time:", data);

      // Update UI after successful API call
      setStopTime(now);
      setCurrentDateTime(now);
      setSuccessMessage("Tank cleaning stopped successfully");

      // Clear localStorage when session is complete
      localStorage.removeItem("tankCleaningSession");

      // Reset form after stopping
      setTimeout(() => {
        resetForm();
      }, 3000);
    } catch (error: any) {
      console.error("Error updating log entry:", error);
      setErrorMessage(error.message || "Failed to stop cleaning session");
    } finally {
      setIsLoading(false);
    }
  };

  // Toast components
  const renderErrorToast = () => {
    if (!showErrorToast) return null;

    return (
      <div className="fixed top-20 right-16 z-50 flex items-center bg-red-500 text-white px-4 py-3 rounded shadow-lg transition-opacity duration-300 opacity-100">
        <AlertCircle size={20} className="mr-2" />
        <span>{errorMessage}</span>
        <button
          onClick={() => setShowErrorToast(false)}
          className="ml-3 text-white hover:text-red-100"
        >
          <X size={16} />
        </button>
      </div>
    );
  };

  const renderSuccessToast = () => {
    if (!showSuccessToast) return null;

    return (
      <div className="fixed top-20 right-16 z-50 flex items-center bg-green-500 text-white px-4 py-3 rounded shadow-lg transition-opacity duration-300 opacity-100">
        <span>{successMessage}</span>
        <button
          onClick={() => setShowSuccessToast(false)}
          className="ml-3 text-white hover:text-green-100"
        >
          <X size={16} />
        </button>
      </div>
    );
  };

  // Handler for date change (prevent future dates)
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(e.target.value);
    const today = new Date();
    if (isNaN(selectedDate.getTime())) {
      alert("Invalid date");
      return;
    }
    if (selectedDate <= today) {
      setCurrentDateTime(selectedDate);
    } else {
      alert("Cannot select future dates");
    }
  };

  const renderHeader = () => {
    return (
      <div className="flex-1 bg-[#1E1E1E]">
        <div className="flex bg-[#262626] justify-between items-center p-6">
          <div className="text-[#858585]">
            <h1 className="text-2xl font-bold">Tank Cleaning Entry</h1>
          </div>

          <div className="flex items-center">
            <div className="flex space-x-2 text-blue-400 px-2 py-1 rounded">
              <input
                type="date"
                max={new Date().toISOString().split("T")[0]}
                value={formatDate(currentDateTime)}
                onChange={(e) =>
                  handleDateChange(e)
                }
                className="px-2 py-1 bg-[#1C1C1E] rounded text-sm text-blue-400 text-center appearance-none cursor-pointer focus:outline-none"
              />
              <input
                type="time"
                max={new Date().toISOString().split("T")[0]}
                value={formatTime(currentDateTime)}
                onChange={(e) =>
                  handleDateChange(e)
                }
                className="px-2 py-1 bg-[#1C1C1E] rounded text-sm text-blue-400 text-center appearance-none cursor-pointer focus:outline-none"
              />
            </div>

            <div className="w-10 h-10 rounded-full bg-gray-500 flex items-center justify-center font-bold">
              {
                currentDateTime.toLocaleTimeString([], {
                  hour12: true,
                  hour: "2-digit",
                })[0]
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="p-10">
        {/* Name Input Section */}
        <div className="mb-6">
          <label className="block text-sm text-white mb-2">
            Name (required):
          </label>
          <div>
            <input
              type="text"
              placeholder="Enter your name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className={`px-4 py-2 bg-[#1C1C1E] rounded text-white border border-gray-600 appearance-none focus:outline-none w-[400px] ${
                sessionActive ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={sessionActive}
            />
          </div>
        </div>

        {/* Start/Stop Section */}
        <div className="flex flex-col items-start space-y-4 mb-6">
          {/* Start Button + Time */}
          <div className="flex items-center space-x-4 py-2">
            <button
              onClick={handleStartClick}
              disabled={isLoading || sessionActive}
              className={`${
                isLoading || sessionActive
                  ? "bg-gray-500"
                  : "bg-[#d9d9d9] hover:bg-gray-200 active:bg-gray-300"
              } text-black rounded-lg text-xl font-semibold transition-colors duration-150 flex items-center justify-center`}
              style={{
                width: "180px",
                height: "80px",
                minWidth: "180px",
                minHeight: "80px",
                maxWidth: "180px",
                maxHeight: "80px",
              }}
            >
              {isLoading ? "" : "Start"}
            </button>
            {startTime && sessionActive && (
              <span className="text-sm text-blue-400 pl-16">
                {formattedStartTime}
              </span>
            )}
          </div>

          {/* Stop Button + Time */}
          <div className="flex items-center space-x-4 py-2">
            <button
              onClick={handleStopClick}
              disabled={isLoading || !sessionActive}
              className={`${
                isLoading || !sessionActive
                  ? "bg-gray-500"
                  : "bg-[#d9d9d9] hover:bg-gray-200 active:bg-gray-300"
              } text-black rounded-lg text-xl font-semibold transition-colors duration-150 flex items-center justify-center`}
              style={{
                width: "180px",
                height: "80px",
                minWidth: "180px",
                minHeight: "80px",
                maxWidth: "180px",
                maxHeight: "80px",
              }}
            >
              {isLoading ? "" : "Stop"}
            </button>
            {stopTime && (
              <span className="text-sm text-blue-400 pl-16">
                {formattedStopTime}
              </span>
            )}
          </div>
        </div>
        {/* Tanks Cleaned */}
        <div className="mb-6">
          <label className="block text-sm text-white mb-2">
            Tanks Cleaned:
          </label>
          <label className="block text-sm text-white mb-2 mt-4">
            Select Room
          </label>
          <select
            value={selectedRoom}
            onChange={(e) => setSelectedRoom(e.target.value)}
            className={`bg-[#2C2C2E] border border-white text-white px-3 py-2 rounded focus:outline-none w-[400px] ${
              sessionActive ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={sessionActive}
          >
            <option>Lower Level</option>
            <option>Upper Level</option>
            <option>Main Room</option>
          </select>
        </div>

        {/* Dropdown / Rack Selection */}
        <div className="mb-6">
          {selectedRack ? (
            // Selected state: show a pill with the same width & styling
            <div className="inline-flex items-center bg-white text-black px-11 py-2 rounded-full justify-between">
              <span className="truncate">{`Rack ${selectedRack}`}</span>
              <button
                type="button"
                onClick={() => !sessionActive && setSelectedRack(null)}
                className={`ml-2 text-black ${
                  sessionActive ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={sessionActive}
              >
                <X size={16} />
              </button>
            </div>
          ) : (
            // Unselected state: show the white, pill-shaped dropdown
            <select
              value={selectedRack || ""}
              onChange={(e) => setSelectedRack(e.target.value)}
              className={`inline-flex items-center bg-white text-black px-8 py-2 rounded-full focus:outline-none ${
                sessionActive ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={sessionActive}
            >
              <option value="">Select Rack</option>
              <option value="A">Rack A</option>
              <option value="B">Rack B</option>
              <option value="C">Rack C</option>
              <option value="D">Rack D</option>
            </select>
          )}
        </div>

        {/* Notes */}
        <div className="mb-6">
          <label className="block text-sm text-white mb-2">Notes:</label>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Any Notes Entered"
            className={`w-2/3 bg-[#2C2C2E] border rounded px-3 py-2 text-white focus:outline-none ${
              sessionActive ? "opacity-50 cursor-not-allowed" : ""
            }`}
            rows={4}
            disabled={sessionActive}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-[#1C1C1E] text-white flex">
      {/* Toast notifications */}
      {renderErrorToast()}
      {renderSuccessToast()}

      <div className="bg-[#232323] min-h-screen">
        <TankCleaningLHS />
      </div>

      <div className="flex flex-col w-full">
        <div className="h-16 flex items-center">{renderHeader()}</div>

        <div className="flex-1 px-4 py-6">{renderBody()}</div>
      </div>
    </div>
  );
}

export default TankCleaning;
