// src/services/apiService.ts
import axios from "axios";
import { FeedingLogData, FoodType, Species } from "./types";

let tempBaseUrl = "https://feed-fish-dev.appbrainz.com/api";

export const BASE_URL = process.env.REACT_APP_BASE_URL || tempBaseUrl;

export const apiService = {
  async getFoodTypes(): Promise<string[]> {
    try {
      const response = await axios.get<FoodType[]>(`${BASE_URL}/food-type`);
      return response.data.map((type) => type.name);
    } catch (error) {
      console.error("Error fetching food types:", error);
      throw error;
    }
  },

  async getSpecies(): Promise<string[]> {
    try {
      const response = await axios.get<Species[]>(`${BASE_URL}/species`);
      return response.data.map((species) => species.name);
    } catch (error) {
      console.error("Error fetching species:", error);
      throw error;
    }
  },

  async saveFeedingLog(logData: FeedingLogData) {
    try {
      const response = await axios.post(`${BASE_URL}/feeding-log`, logData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error saving feeding log:", error);
      throw error;
    }
  },
};
