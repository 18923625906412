import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { BASE_URL } from "./apiService";

// Define the type for morbidity log data from API
interface MorbidityLogEntry {
  date: string;
  time: string;
  room: string;
  species: string;
  tank: string;
  age: string;
  cause: string;
  num_of_fish: number;
  preserved: boolean;
  notes: string;
}

interface ChartDataPoint {
  date: string;
  [key: string]: number | string;
}

const AnalyticsGraph: React.FC = () => {
  const [chartData, setChartData] = useState<ChartDataPoint[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [speciesList, setSpeciesList] = useState<string[]>([]);

  useEffect(() => {
    const fetchMorbidityData = async () => {
      try {
        setLoading(true);
        const response = await axios.get<MorbidityLogEntry[]>(
          `${BASE_URL}/morbidity-log`
        );

        // Process the data for the chart
        const processedData = processDataForChart(response.data);
        setChartData(processedData.chartData);
        setSpeciesList(processedData.species);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching morbidity data:", err);
        setError("Failed to load morbidity data. Please try again later.");
        setLoading(false);
      }
    };

    fetchMorbidityData();
  }, []);

  // Function to process the API data into a format suitable for the chart
  const processDataForChart = (data: MorbidityLogEntry[]) => {
    // Get unique dates
    const dateMap = new Map<string, Map<string, number>>();
    const speciesSet = new Set<string>();

    // Group by date and species, sum num_of_fish
    data.forEach((entry) => {
      // Format date for consistency (YYYY-MM-DD)
      const formattedDate = new Date(entry.date).toISOString().split("T")[0];

      if (!dateMap.has(formattedDate)) {
        dateMap.set(formattedDate, new Map<string, number>());
      }

      const speciesMap = dateMap.get(formattedDate)!;
      const currentCount = speciesMap.get(entry.species) || 0;
      speciesMap.set(entry.species, currentCount + entry.num_of_fish);

      speciesSet.add(entry.species);
    });

    // Convert to chart data format
    const chartData: ChartDataPoint[] = [];
    const species = Array.from(speciesSet).sort();

    // Sort dates
    const sortedDates = Array.from(dateMap.keys()).sort();

    for (const date of sortedDates) {
      const dataPoint: ChartDataPoint = { date };
      const speciesMap = dateMap.get(date)!;

      // Initialize all species values to 0
      for (const sp of species) {
        dataPoint[sp] = speciesMap.get(sp) || 0;
      }

      chartData.push(dataPoint);
    }

    return { chartData, species };
  };

  // Generate a color for each species
  const getSpeciesColor = (index: number) => {
    const colors = [
      "#FF9F1C",
      "#2EC4B6",
      "#E71D36",
      "#8338EC",
      "#3A86FF",
      "#FB5607",
      "#FFBE0B",
      "#FF006E",
      "#8AC926",
      "#1982C4",
    ];
    return colors[index % colors.length];
  };

  if (loading)
    return (
      <div className="flex items-center justify-center h-64">
        Loading morbidity data...
      </div>
    );
  if (error) return <div className="text-red-500 p-4">{error}</div>;

  return (
    <div className="w-full h-full">
      {chartData.length === 0 ? (
        <div className="text-gray-400 p-4">No morbidity data available</div>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
            <YAxis
              stroke="#9CA3AF"
              tick={{ fill: "#9CA3AF" }}
              allowDecimals={false}
              label={{
                value: "Number of Deaths",
                angle: -90,
                position: "insideLeft",
                offset: 10,
                dy: 50,
                style: { fill: "#9CA3AF", fontSize: "14px" },
              }}
            />
            <XAxis
              dataKey="date"
              stroke="#9CA3AF"
              tick={{ fill: "#9CA3AF" }}
              tickFormatter={(value) => new Date(value).toLocaleDateString()}
              label={{
                value: "Date",
                position: "insideBottom",
                offset: -40,
                style: { fill: "#9CA3AF", fontSize: "14px" },
              }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "#1F2937",
                border: "1px solid #374151",
                borderRadius: "0.5rem",
                color: "#F3F4F6",
              }}
              formatter={(value, name) => [`${value} fish`, name]}
              labelFormatter={(label) => new Date(label).toLocaleDateString()}
            />
            <Legend
              wrapperStyle={{
                color: "#F3F4F6",
              }}
            />
            {speciesList.map((species, index) => (
              <Line
                key={species}
                type="monotone"
                dataKey={species}
                name={species}
                stroke={getSpeciesColor(index)}
                dot={{ fill: getSpeciesColor(index) }}
                activeDot={{ r: 8 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default AnalyticsGraph;
