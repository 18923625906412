import { useLocation, useNavigate } from "react-router-dom";

  const TankCleaningLHS = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const isActive = (path: string) => location.pathname === path;

    const handleTimeEntry = () => {
      navigate("/tank-cleaning");
    };
  
    const handleTankCleaningRecord = () => {
      navigate("/tank-cleaning-record");
    };

    return (
      <div className="w-64 bg-[#232323] flex flex-col">
        <div 
          onClick={() => navigate("/")}
          className="cursor-pointer justify-center align-middle text-center py-4 mb-6 px-4 py-3 p-6 mt-4 text-sm text-left bg-[#1C1C1E] text-white hover:text-white" 
          style={{ borderRadius: 8 }}
        >
          Back to Dashboard
        </div>

        <div className="rounded-sm bg-[#707070] mb-4 text-white p-2 m-2 border-t border-r border-l" style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}>
          <div className="py-2 px-2 border-b border-gray-400 cursor-pointer">
            Tank Cleaning
          </div>
          <div 
            onClick={handleTimeEntry} 
            className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between"
            style={{ opacity: isActive('/tank-cleaning') ? 1 : 0.6, fontWeight:  isActive('/tank-cleaning') ? 'bold' : undefined }}
            >
            <span className="text-sm"><span className='text-black'>☆</span> Tank Record Entry</span>
            <span className="text-xs text-black">⇧ A</span>
          </div>
          <div 
            onClick={handleTankCleaningRecord} 
            className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between"
            style={{ opacity: isActive('/tank-cleaning-record') ? 1 : 0.6, fontWeight:  isActive('/tank-cleaning-record') ? 'bold' : undefined }}
          >
            <span className="text-sm"><span className='text-black'>☆</span> Tank Cleaning Record</span>
            <span className="text-xs text-black">⇧ A</span>
          </div>
        </div>
      </div>
    );
  };
export default TankCleaningLHS;