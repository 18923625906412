import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../components/apiService";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import WaterQualityLHS from "./WaterQualityLHS";

interface WaterQualityLog {
  id: number;
  date: string;
  time: string;
  room: string;
  pH: string;
  temperature: string;
  conductivity: string;
  completed_by: string;
  createdAt?: string;
}

const WaterQualityLogRecord: React.FC = () => {
  const navigate = useNavigate();

  // State variables
  const [waterQualityLogs, setWaterQualityLogs] = useState<WaterQualityLog[]>(
    []
  );
  const [filteredLogs, setFilteredLogs] = useState<WaterQualityLog[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedRoom, setSelectedRoom] = useState<string>("Main Room");
  const [showAllRooms, setShowAllRooms] = useState<boolean>(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage] = useState<number>(5);

  // Current date and time state
  const [currentDateTime, setCurrentDateTime] = useState<Date>(new Date());

  // Rooms for filtering
  const rooms = ["Main Room", "Side Room", "Upper Room"];

  // Colors for each room (consistent with the attached image)
  const roomColors = {
    "Main Room": "#FFD700", // Yellow
    "Side Room": "#FFA500", // Orange
    "Upper Room": "#FF6347", // Red
  };

  // Update current time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Fetch water quality logs
  const fetchWaterQualityLogs = async () => {
    try {
      setIsLoading(true);
      const url = new URL(`${BASE_URL}/water-quality`);
      const response = await axios.get(url.toString());
      // Determine the correct logs array
      let logs: WaterQualityLog[] = [];

      // Check different possible structures
      if (Array.isArray(response.data)) {
        logs = response.data;
      } else if (Array.isArray(response.data.logs)) {
        logs = response.data.logs;
      } else if (response.data[0]) {
        logs = response.data;
      }

      // Do not parse or format the date
      setWaterQualityLogs(logs);
      setIsLoading(false);
    } catch (error) {
      setError("Failed to fetch water quality logs");
      setIsLoading(false);
    }
  };

  // Fetch logs on component mount
  useEffect(() => {
    fetchWaterQualityLogs();
  }, []);

  // Filter logs when room is selected or when logs are fetched
  useEffect(() => {
    if (showAllRooms) {
      setFilteredLogs(waterQualityLogs);
    } else {
      const filtered = waterQualityLogs.filter(
        (log) => log.room === selectedRoom
      );
      setFilteredLogs(filtered);
    }
    // Reset to first page when filter changes
    setCurrentPage(1);
  }, [selectedRoom, waterQualityLogs, showAllRooms]);

  // Format time with leading zeros
  const formatTime = (date: Date) => {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  // Format date
  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };

  const handleWaterQualityLogs = () => {
    navigate("/water-quality-logs");
  };

  // Toggle between single room and all rooms view
  const toggleAllRooms = () => {
    setShowAllRooms(!showAllRooms);
  };

  // Prepare data for charts
  const prepareChartData = () => {
    // Get unique dates across all logs
    const dateSet = new Set<string>();
    waterQualityLogs.forEach((log) => dateSet.add(log.date));
    const allDates = Array.from(dateSet).sort();

    // Create a mapping of all rooms to their data for the charts
    const allRoomsData = waterQualityLogs.reduce((acc, log) => {
      if (!acc[log.room]) {
        acc[log.room] = {};
      }

      if (!acc[log.room][log.date]) {
        acc[log.room][log.date] = {
          pH: parseFloat(log.pH),
          conductivity: parseFloat(log.conductivity),
          temperature: parseFloat(log.temperature),
        };
      }

      return acc;
    }, {} as Record<string, Record<string, { pH: number; conductivity: number; temperature: number }>>);

    // Create chart data with all rooms' values for each date
    return allDates.map((date) => {
      const entry: any = { date };

      // Add data for each room, using 0 as default if no data exists
      rooms.forEach((room) => {
        // If there's data for this room and date, use it
        if (allRoomsData[room] && allRoomsData[room][date]) {
          entry[`${room} pH`] = allRoomsData[room][date].pH;
          entry[`${room} Conductivity`] = allRoomsData[room][date].conductivity;
          entry[`${room} Temperature`] = allRoomsData[room][date].temperature;
        }
        // Otherwise set the value to 0 to ensure line continuity
        else {
          entry[`${room} pH`] = 0;
          entry[`${room} Conductivity`] = 0;
          entry[`${room} Temperature`] = 0;
        }
      });

      return entry;
    });
  };

  const chartData = prepareChartData();

  // Calculate pagination
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredLogs.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const totalPages = Math.ceil(filteredLogs.length / recordsPerPage);

  // Pagination controls
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Add this function to your WaterQualityLogRecord component

  const handleDeleteLog = async (id: number) => {
    if (!window.confirm("Are you sure you want to delete this record?")) {
      return;
    }

    try {
      const response = await axios.delete(
        `${BASE_URL}/water-quality-logs/${id}`
      );

      if (response.status === 200) {
        // Update the state by removing the deleted log
        const updatedLogs = waterQualityLogs.filter((log) => log.id !== id);
        setWaterQualityLogs(updatedLogs);

        // Show success message (optional)
        setError(null);
        alert("Record deleted successfully");
      }
    } catch (error) {
      setError("Failed to delete water quality log");
    }
  };

  const formatDateField = (dateString: string | null): string => {
    if (!dateString) return "-";

    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };
  const formatDateFieldGraph = (dateString: string | null): string => {
    if (!dateString) return "-";

    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate.replace(/\//g, "-");
  };

  return (
    <div className="min-h-screen bg-black text-white flex">
      {/* Left Sidebar */}
      <WaterQualityLHS showMenu />

      {/* Main Content */}
      <div className="flex-1 bg-[#1E1E1E]">
        <div className="flex bg-[#262626] justify-between items-center p-6">
          <div className="text-[#858585]">
            <h1 className="text-2xl font-bold">
              Water Quality Log{" "}
              {showAllRooms
                ? "- All Rooms"
                : selectedRoom
                ? `- ${selectedRoom}`
                : ""}
            </h1>
            <div className="text-sm text-gray-400">The Doe Lab</div>
          </div>

          <div className="flex items-center">
            <div className="mr-4 text-blue-400 flex space-x-2">
              <input
                type="date"
                value={formatDate(currentDateTime)}
                readOnly
                className="bg-transparent text-center"
              />
              <input
                type="time"
                value={formatTime(currentDateTime)}
                readOnly
                className="bg-transparent text-center"
              />
            </div>
            <div className="w-10 h-10 rounded-full bg-gray-500 flex items-center justify-center font-bold">
              {
                currentDateTime.toLocaleTimeString([], {
                  hour12: true,
                  hour: "2-digit",
                })[0]
              }
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-4 p-4">
          <div className="">
            <label className="block text-sm mb-2">Select Room</label>
          </div>
          <div className="flex-1">
            <select
              value={selectedRoom}
              onChange={(e) => {
                setSelectedRoom(e.target.value);
                setShowAllRooms(false);
              }}
              className="bg-[#232323] text-white p-2 rounded"
              disabled={showAllRooms}
            >
              {rooms.map((room) => (
                <option key={room} value={room}>
                  {room}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="p-6">
          {error && <div className="text-red-500 mb-4">Error: {error}</div>}

          {isLoading ? (
            <div className="text-white">Loading...</div>
          ) : (
            <>
              {/* Table */}
              <table className="w-full bg-white text-black rounded-lg overflow-hidden border border-black border-collapse">
                <thead>
                  <tr className="bg-[#707070]">
                    <th className="px-4 py-2 text-left w-[15%] border border-black">
                      Date
                    </th>
                    <th className="px-4 py-2 text-right w-[10%] border border-black">
                      Room
                    </th>
                    <th className="px-4 py-2 text-left w-[15%] border border-black">
                      Time
                    </th>
                    <th className="px-4 py-2 text-left w-[15%] border border-black">
                      pH
                    </th>
                    <th className="px-4 py-2 text-left w-[15%] border border-black">
                      Conductivity
                    </th>
                    <th className="px-4 py-2 text-left w-[10%] border border-black">
                      Temperature
                    </th>
                    <th className="px-3 py-2 text-center w-[10%] border border-black">
                      Completed By
                    </th>
                    <th className="px-3 py-2 text-center w-[10%] border border-black">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentRecords.map((log) => (
                    <tr key={log.id} className="border-b border-black">
                      <td className="p-3 border border-[#444]">
                        {formatDateField(log.date)}
                      </td>
                      <td className="px-4 py-2 text-right border border-black">
                        {log.room}
                      </td>
                      <td className="px-4 py-2 border border-black">
                        {log.time}
                      </td>
                      <td className="px-4 py-2 border border-black">
                        {log.pH}
                      </td>
                      <td className="px-4 py-2 border border-black">
                        {log.conductivity}
                      </td>
                      <td className="px-4 py-2 border border-black">
                        {log.temperature}°C
                      </td>
                      <td className="px-4 py-2 text-center border border-black">
                        {log.completed_by}
                      </td>
                      <td className="px-4 py-2 text-left border border-gray-700">
                        <button
                          onClick={() => handleDeleteLog(log.id)}
                          className="text-red-500 hover:text-red-700"
                          title="Delete Record"
                        >
                          ✖
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Pagination Controls */}
              {filteredLogs.length > 0 && (
                <div className="flex justify-between items-center mt-4 mb-6">
                  <button
                    onClick={prevPage}
                    disabled={currentPage === 1}
                    className={`px-4 py-2 rounded ${
                      currentPage === 1
                        ? "bg-[#232323] cursor-not-allowed"
                        : "bg-blue-600 hover:bg-blue-700"
                    }`}
                  >
                    Prev
                  </button>
                  <span className="text-white">
                    Page {currentPage} of {totalPages} ({filteredLogs.length}{" "}
                    records)
                  </span>
                  <button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                    className={`px-4 py-2 rounded ${
                      currentPage === totalPages
                        ? "bg-[#232323] cursor-not-allowed"
                        : "bg-[#232323] hover:bg-blue-700"
                    }`}
                  >
                    Next
                  </button>
                </div>
              )}
              <div>
                <button
                  onClick={toggleAllRooms}
                  className={`px-4 py-2 rounded ${
                    showAllRooms ? "bg-blue-600" : "bg-gray-600"
                  } hover:bg-blue-700`}
                >
                  {showAllRooms ? "Show Single Room" : "Show All Rooms"}
                </button>
              </div>
              {/* Line Charts in Separate Rows */}
              <div className="space-y-6 mb-6 mt-6">
                {/* pH Chart */}
                <div className="bg-white text-black p-4 rounded">
                  <h2 className="text-center font-bold mb-4">pH Over Time</h2>
                  <ResponsiveContainer width="100%" height={150}>
                    <LineChart data={chartData}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                      <XAxis
                        dataKey="date"
                        tickFormatter={(date) => formatDateFieldGraph(date)}
                        label={{
                          value: "Date",
                          position: "insideBottom",
                          offset: -5,
                        }}
                      />
                      <YAxis
                        label={{
                          value: "pH Level",
                          angle: -90,
                          position: "insideLeft",
                          offset: 10,
                          dy: 40,
                        }}
                      />
                      <Tooltip
                        contentStyle={{ backgroundColor: "#f0f0f0" }}
                        labelStyle={{ color: "#333" }}
                      />
                      <Legend />
                      {rooms.map((room) => (
                        <Line
                          key={`${room}-ph`}
                          type="monotone"
                          dataKey={`${room} pH`}
                          name={`${room} pH`}
                          stroke={roomColors[room as keyof typeof roomColors]}
                          strokeWidth={3}
                          dot={{ r: 6 }}
                          activeDot={{ r: 8 }}
                          hide={!showAllRooms && selectedRoom !== room}
                        />
                      ))}
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                {/* Conductivity Chart */}
                <div className="bg-white text-black p-4 rounded">
                  <h2 className="text-center font-bold mb-4">
                    Conductivity Over Time
                  </h2>
                  <ResponsiveContainer width="100%" height={150}>
                    <LineChart data={chartData}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                      <XAxis
                        dataKey="date"
                        tickFormatter={(date) => formatDateFieldGraph(date)}
                        label={{
                          value: "Date",
                          position: "insideBottom",
                          offset: -5,
                        }}
                      />
                      <YAxis
                        label={{
                          value: "Conductivity",
                          angle: -90,
                          position: "insideLeft",
                          offset: 10,
                          dy: 50,
                        }}
                      />
                      <Tooltip
                        contentStyle={{ backgroundColor: "#f0f0f0" }}
                        labelStyle={{ color: "#333" }}
                      />
                      <Legend />
                      {rooms.map((room) => (
                        <Line
                          key={`${room}-conductivity`}
                          type="monotone"
                          dataKey={`${room} Conductivity`}
                          name={`${room} Conductivity`}
                          stroke={roomColors[room as keyof typeof roomColors]}
                          strokeWidth={3}
                          dot={{ r: 6 }}
                          activeDot={{ r: 8 }}
                          hide={!showAllRooms && selectedRoom !== room}
                        />
                      ))}
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                {/* Temperature Chart */}
                <div className="bg-white text-black p-4 rounded">
                  <h2 className="text-center font-bold mb-4">
                    Temperature Over Time
                  </h2>
                  <ResponsiveContainer width="100%" height={150}>
                    <LineChart data={chartData}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                      <XAxis
                        dataKey="date"
                        tickFormatter={(date) => formatDateFieldGraph(date)}
                        label={{
                          value: "Date",
                          position: "insideBottom",
                          offset: -5,
                        }}
                      />
                      <YAxis
                        label={{
                          value: "Temperature",
                          angle: -90,
                          position: "insideLeft",
                          offset: 10,
                          dy: 50,
                        }}
                      />
                      <Tooltip
                        contentStyle={{ backgroundColor: "#f0f0f0" }}
                        labelStyle={{ color: "#333" }}
                      />
                      <Legend />
                      {rooms.map((room) => (
                        <Line
                          key={`${room}-temperature`}
                          type="monotone"
                          dataKey={`${room} Temperature`}
                          name={`${room} Temperature`}
                          stroke={roomColors[room as keyof typeof roomColors]}
                          strokeWidth={3}
                          dot={{ r: 6 }}
                          activeDot={{ r: 8 }}
                          hide={!showAllRooms && selectedRoom !== room}
                        />
                      ))}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {filteredLogs.length === 0 && (
                <div className="text-center text-gray-500 mt-4">
                  No water quality logs found{" "}
                  {showAllRooms ? "for any room" : `for ${selectedRoom}`}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default WaterQualityLogRecord;
