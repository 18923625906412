import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FoodDistributionPieChart from "../components/FoodDistributionPieChart";
import { BASE_URL } from "../components/apiService";
import FeedingLHS from "./FeedingLHS";

interface FeedingLog {
  id: number;
  date: string;
  time: string;
  period: string;
  room_category: string;
  room: string;
  type: string;
  amount: string | number;
  notes?: string;
  salt_added: string | null;
  specialty_diet: number;
  tank_number: string | null;
  species: string | null;
  diet_type: string | null;
  diet_amount: string | null;
  created_at: string;
}

interface PaginationResponse {
  logs: FeedingLog[];
  currentPage: number;
  totalPages: number;
  totalRecords: number;
}

const FoodRecordPage: React.FC = () => {
  const navigate = useNavigate();
  const [feedingLogs, setFeedingLogs] = useState<FeedingLog[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Pagination state
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  // Current date and time state
  const [currentDateTime, setCurrentDateTime] = useState<Date>(new Date());

  // Update current time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Fetch paginated feeding logs from the backend
  const fetchFeedingLogs = async (page: number = 1) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${BASE_URL}/feeding-log?page=${page}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      const responseText = await response.text();
      console.log("Raw response:", responseText);

      let data: PaginationResponse;
      try {
        data = JSON.parse(responseText);
      } catch (parseError) {
        console.error("JSON Parsing Error:", parseError);
        throw new Error("Invalid JSON response");
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Set feeding logs and pagination details
      setFeedingLogs(data.logs);
      setCurrentPage(data.currentPage);
      setTotalPages(data.totalPages);
      setTotalRecords(data.totalRecords);
      setError(null);
    } catch (error) {
      console.error("Error fetching feeding logs:", error);
      setError(error instanceof Error ? error.message : String(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteLog = async (logId: number) => {
    if (!window.confirm("Are you sure you want to delete this record?")) {
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/feeding-delete/${logId}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to delete the log");
      }
      fetchFeedingLogs(currentPage);
      alert("Record deleted successfully");
    } catch (error) {
      console.error("Error deleting feeding log:", error);
      setError(error instanceof Error ? error.message : String(error));
    }
  };

  // Initial fetch of feeding logs
  useEffect(() => {
    fetchFeedingLogs();
  }, []);

  // Pagination handlers
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      fetchFeedingLogs(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      fetchFeedingLogs(currentPage - 1);
    }
  };

  // Format time with leading zeros
  const formatTime = (date: Date) => {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  // Format date
  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };

  const handleFoodRecord = () => {
    navigate("/food-record");
  };

  const handleNutritionRecord = () => {
    navigate("/nutrition-record");
  };

  return (
    <div className="min-h-screen bg-black text-white flex">
      {/* Left Sidebar */}
      <FeedingLHS />

      {/* Main Content */}
      <div className="flex-1 bg-[#1E1E1E]">
        <div className="flex bg-[#262626] justify-between items-center p-6">
          <div className="text-[#858585]">
            <h1 className="text-2xl font-bold">
              Daily Feeding Log (All Rooms)
            </h1>
            <div className="text-sm text-gray-400">The Doe Lab</div>
          </div>

          <div className="flex items-center ">
            <div className="mr-4 text-blue-400 flex space-x-2">
              <input
                type="date"
                value={formatDate(currentDateTime)}
                readOnly
                className="bg-transparent text-center"
              />
              <input
                type="time"
                value={formatTime(currentDateTime)}
                readOnly
                className="bg-transparent text-center"
              />
            </div>
            <div className="w-10 h-10 rounded-full bg-gray-500 flex items-center justify-center font-bold">
              {
                currentDateTime.toLocaleTimeString([], {
                  hour12: true,
                  hour: "2-digit",
                })[0]
              }
            </div>
          </div>
        </div>

        <div className="p-6">
          <h2 className="text-xl font-bold mb-4">Feeding Logs</h2>
          {error && <div className="text-red-500 mb-4">Error: {error}</div>}

          {isLoading ? (
            <div className="text-white">Loading...</div>
          ) : (
            <>
              <table className="w-full bg-[#232323] rounded-lg overflow-hidden border border-[#1C1C1E]">
                <thead>
                  <tr className="bg-[#1C1C1E]">
                    <th className="px-4 py-2 text-left w-[15%] border border-gray-700">
                      Food Type
                    </th>
                    <th className="px-4 py-2 text-right w-[10%] border border-gray-700">
                      Amount
                    </th>
                    <th className="px-4 py-2 text-left w-[15%] border border-gray-700">
                      Room
                    </th>
                    <th className="px-4 py-2 text-left w-[15%] border border-gray-700">
                      Date
                    </th>
                    <th className="px-4 py-2 text-left w-[10%] border border-gray-700">
                      Time
                    </th>
                    <th className="px-4 py-2 text-center w-[10%] border border-gray-700">
                      Salt Added
                    </th>
                    <th className="px-4 py-2 text-left w-[15%] border border-gray-700">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {feedingLogs.map((log, index) => (
                    <tr
                      key={log.id || index}
                      className="border-b border-[#1C1C1E] hover:bg-[#2C2C2E]"
                    >
                      <td className="px-4 py-2 text-left border border-gray-700">
                        {log.type}
                      </td>
                      <td className="px-4 py-2 text-right border border-gray-700">
                        {log.amount}g
                      </td>
                      <td className="px-4 py-2 text-left border border-gray-700">
                        {log.room_category}
                      </td>
                      <td className="px-4 py-2 text-left border border-gray-700">
                        {new Date(log.date).toLocaleDateString()}
                      </td>
                      <td className="px-4 py-2 text-left border border-gray-700">
                        {log.time}
                      </td>
                      <td className="px-4 py-2 text-center border border-gray-700">
                        {log.salt_added === "Yes" ? "Y" : "N"}
                      </td>
                      <td className="px-4 py-2 text-left border border-gray-700">
                        <button
                          onClick={() => handleDeleteLog(log.id)}
                          className="text-red-500 hover:text-red-700"
                          title="Delete Record"
                        >
                          ✖
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Pagination Controls */}
              <div className="flex justify-between items-center mt-4">
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className="px-4 py-2 bg-[#232323] text-white rounded disabled:opacity-50"
                >
                  Prev
                </button>
                <span className="text-white">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="px-4 py-2 bg-[#232323] text-white rounded disabled:opacity-50"
                >
                  Next
                </button>
              </div>
            </>
          )}
        </div>

        <div className="p-6">
          <FoodDistributionPieChart />
        </div>
      </div>
    </div>
  );
};

export default FoodRecordPage;
