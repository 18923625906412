import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../components/apiService";

const FishMorbidity: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    date: new Date().toISOString().split("T")[0],
    time: "",
    tankLocation: "Main Fish Room",
    species: "",
    affectedFish: "",
    cause: "Unknown",
    otherCause: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name == 'date' || name == 'time') {
      const selectedDate = new Date(value);
      const today = new Date();
      if (isNaN(selectedDate.getTime())) {
        alert("Invalid date");
        return;
      }
      if (selectedDate > today) {
        alert("Cannot select future dates");
        return;  
      } 
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axios.post(
        `${BASE_URL}/fish-morbidity`,

        formData
      );
      console.log("Logged Data:", response.data);
      setSuccess(true);
      setFormData({
        date: new Date().toISOString().split("T")[0],
        time: "",
        tankLocation: "Main Fish Room",
        species: "",
        affectedFish: "",
        cause: "Unknown",
        otherCause: "",
      });

      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (err) {
      console.error("Error submitting data:", err);
      setError("Failed to submit morbidity data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 bg-[#1e1e1e] min-h-screen text-white font-sans">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-white mb-2">
          Fish Morbidity Management
        </h1>
        <p className="text-sm text-[#858585]">
          Log and track fish morbidity and mortality data.
        </p>
      </div>

      <div className="max-w-2xl mx-auto bg-[#262626] p-8 rounded-xl shadow-lg border border-[#333]">
        <h2 className="text-2xl font-semibold text-white mb-6">
          Morbidity Log Entry
        </h2>

        {success && (
          <div className="mb-6 bg-green-800 p-3 rounded-lg text-white">
            Morbidity data logged successfully! Redirecting to home page...
          </div>
        )}

        {error && (
          <div className="mb-6 bg-red-800 p-3 rounded-lg text-white">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-[#858585] mb-1">
              Date
            </label>
            <input
              type="date"
              name="date"
              max={new Date().toISOString().split("T")[0]}
              value={formData.date}
              onChange={handleInputChange}
              className="w-full p-3 bg-[#333] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00ff88]"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-[#858585] mb-1">
              Time Collected
            </label>
            <input
              type="time"
              name="time"
              max={new Date().toISOString().split("T")[0]}
              value={formData.time}
              onChange={handleInputChange}
              className="w-full p-3 bg-[#333] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00ff88]"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-[#858585] mb-1">
              Tank Location
            </label>
            <select
              name="tankLocation"
              value={formData.tankLocation}
              onChange={handleInputChange}
              className="w-full p-3 bg-[#333] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00ff88]"
            >
              <option value="Main Fish Room">Main Fish Room</option>
              <option value="Side Fish Room">Side Fish Room</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-[#858585] mb-1">
              Species
            </label>
            <select
              name="species"
              value={formData.species}
              onChange={handleInputChange}
              className="w-full p-3 bg-[#333] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00ff88]"
              required
            >
              <option value="" disabled>
                Select species
              </option>
              <option value="Species A">Species A</option>
              <option value="Species B">Species B</option>
              <option value="Species C">Species C</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-[#858585] mb-1">
              Number of Affected Fish
            </label>
            <input
              type="number"
              name="affectedFish"
              value={formData.affectedFish}
              onChange={handleInputChange}
              className="w-full p-3 bg-[#333] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00ff88]"
              placeholder="Enter number"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-[#858585] mb-1">
              Cause of Morbidity/Death
            </label>
            <select
              name="cause"
              value={formData.cause}
              onChange={handleInputChange}
              className="w-full p-3 bg-[#333] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00ff88]"
            >
              <option value="Predation">Predation</option>
              <option value="Disease">Disease</option>
              <option value="Water Quality Issue">Water Quality Issue</option>
              <option value="Unknown">Unknown</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {formData.cause === "Other" && (
            <div>
              <label className="block text-sm font-medium text-[#858585] mb-1">
                Specify Cause
              </label>
              <input
                type="text"
                name="otherCause"
                value={formData.otherCause}
                onChange={handleInputChange}
                className="w-full p-3 bg-[#333] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00ff88]"
                placeholder="Enter cause"
                required
              />
            </div>
          )}

          <button
            type="submit"
            disabled={loading}
            className={`w-full ${
              loading ? "bg-gray-500" : "bg-[#00ff88] hover:bg-[#00cc66]"
            } text-[#1e1e1e] py-3 px-6 rounded-lg font-semibold transition-colors`}
          >
            {loading ? "SUBMITTING..." : "DONE"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default FishMorbidity;
