// src/components/Input.tsx
import React, { useState, useEffect } from "react";

interface InputProps {
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  id?: string;
}

const Input: React.FC<InputProps> = ({
  type,
  value,
  onChange,
  placeholder,
  className = "",
  id = "",
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    if (!isEditing) {
      setDisplayValue(value); // Sync display value with the parent value
    }
  }, [value, isEditing]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayValue(e.target.value); // Update local display value
    onChange(e); // Propagate changes to parent
  };

  return (
    <div onClick={handleClick}>
      {isEditing ? (
        <input
          id={id}
          type={type}
          value={displayValue}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          className={`bg-gray-700 rounded text-white px-3 py-2 ${className}`}
          autoFocus
        />
      ) : (
        <div
          className={`bg-gray-700 rounded text-white px-3 py-2 ${className}`}
        >
          {displayValue}
        </div>
      )}
    </div>
  );
};

export default Input;
