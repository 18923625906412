import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Feeding from "./pages/Feeding";
import WaterQuality from "./pages/WaterQuality";
import FishMorbidity from "./pages/FishMorbidity";
import Feeding_f3 from "./pages/Feeding_f3";
import FoodRecordPage from "./pages/FoodRecordPage";
import NutritionRecordPage from "./pages/NutritionRecordPage";
import TankCleaning from "./pages/TankCleaning";
import TankCleaningRecord from "./pages/TankCleaningRecord";
import MorbidityEntryPage from "./pages/morbidityEntryPage/MorbidityEntryPage";
import MorbidityRecordPage from "./pages/morbidityEntryPage/MorbidityRecordPage";
import BirthEntryPage from "./pages/BirthEntryPage/BirthEntryPage";
import BirthRecordPage from "./pages/BirthEntryPage/BirthRecordPage";
import WaterQualityLogRecord from "./pages/WaterQualityLogRecord";
import AllDocuments from "./pages/AllDocuments";
import RoomBooking from "./pages/RoomBooking";
import LabPersonelHour from "./pages/LabPersonelHour";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/feeding" element={<Feeding />} />
        {/* <Route path="/feeding/f2" element={<Feeding_f2 />} /> */}
        <Route path="/feeding/f2" element={<Feeding_f3 />} />
        <Route path="/water-quality" element={<WaterQuality />} />
        <Route path="/fish-morbidity" element={<FishMorbidity />} />
        <Route path="/food-record" element={<FoodRecordPage />} />
        <Route path="/nutrition-record" element={<NutritionRecordPage />} />
        <Route path="/tank-cleaning" element={<TankCleaning />} />
        <Route path="/tank-cleaning-record" element={<TankCleaningRecord />} />
        <Route path="/MorbidityEntry" element={<MorbidityEntryPage />} />
        <Route path="/MorbidityRecord" element={<MorbidityRecordPage />} />
        <Route path="/BirthEntryPage" element={<BirthEntryPage />} />
        <Route path="/BirthRecordPage" element={<BirthRecordPage />} />
        <Route path="/water-quality-logs" element={<WaterQualityLogRecord />} />
        <Route path="/AllDocuments" element={<AllDocuments />} />
        <Route path="/RoomBooking" element={<RoomBooking />} />
        <Route path="/lab-personal-hours" element={<LabPersonelHour />} />
      </Routes>
    </Router>
  );
};

export default App;
