import { useLocation, useNavigate } from "react-router-dom";

const AllDocumentsLHS = ({isUploadModalOpen=false, handleShowUploadModal=()=>{}}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = (path: string) => location.pathname === path && !isUploadModalOpen;
    const handleNavigate = (path:string)=>{
      navigate(path);
    }
  

  return (
    <div className="w-56 bg-[#232323] flex flex-col">

      <div onClick={() => navigate("/")}
        className=" cursor-pointer justify-center align-middle text-center py-4 mb-6 px-4 py-3 p-6 mt-4 text-sm text-left bg-[#1C1C1E] text-white hover:text-white" style={{ borderRadius: 8 }}
      >
        Back to Dashboard
      </div>

      <div className="rounded-sm bg-[#707070] mb-4 text-white p-2 m-2 border-t border-r border-l " style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }} >
        <div className="py-2 px-2 border-b border-gray-400 cursor-pointer">
          Documents 
        </div>
        <div
          onClick={()=>{}}
          className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between"
          style={{ opacity: isActive('/AllDocuments') ? 1 : 0.6, fontWeight:  isActive('/AllDocuments') ? 'bold' : undefined }}
          >
          <span className="text-sm"><span className='text-black' >☆</span> All Document Log</span>
          <span className="text-xs text-black">⇧ A</span>
        </div>
        <div
          onClick={()=>handleShowUploadModal()}
          className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between"
          style={{ opacity: isUploadModalOpen ? 1 : 0.6, fontWeight:  isUploadModalOpen ? 'bold' : undefined }}
          >
          <span className="text-sm"><span className='text-black' >☆</span> Upload Document</span>
          <span className="text-xs text-black">⇧ A</span>
        </div>
      </div>
    </div>

  )
}
export default AllDocumentsLHS;