interface InputDescriptionBoxProps {
  title?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

function InputDescriptionBox({
  title,
  placeholder,
  value,
  onChange,
}: InputDescriptionBoxProps) {
  return (
    <div>
      {title && <div className="text-sm font-medium mb-2">{title}</div>}
      <textarea
        className="w-full p-2 bg-[#1C1C1E] rounded text-white border"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default InputDescriptionBox;
