import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../components/apiService";
import WaterQualityLHS from "./WaterQualityLHS";

const WaterQuality: React.FC = () => {
  const [selectedRoom, setSelectedRoom] = useState<string>("Main Room");
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupValue, setPopupValue] = useState<string>("");
  const [popupField, setPopupField] = useState<string>("");

  const rooms = ["Main Room", "Side Room", "Upper Room"];
  const navigate = useNavigate();
  const [currentDateTime, setCurrentDateTime] = useState<Date>(new Date());
  const [waterQualityName, setWaterQualityName] = useState("");

  const getCurrentDate = () => {
    return new Date().toISOString().split("T")[0];
  };

  const getCurrentTime = () => {
    return new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const [currentDate, setCurrentDate] = useState<string>(getCurrentDate());
  const [currentTime, setCurrentTime] = useState<string>(getCurrentTime());

  const [roomData, setRoomData] = useState<
    Record<
      string,
      {
        pH: string;
        temp: string;
        cond: string;
        other: string;
        notes: string;
      }
    >
  >({
    "Main Room": { pH: "", temp: "", cond: "", other: "", notes: "" },
    "Side Room": { pH: "", temp: "", cond: "", other: "", notes: "" },
    "Upper Room": { pH: "", temp: "", cond: "", other: "", notes: "" },
  });

  const handleInputChange = (room: string, field: string, value: string) => {
    setRoomData({
      ...roomData,
      [room]: {
        ...roomData[room],
        [field]: value,
      },
    });
  };

  const handleRoomChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRoom(e.target.value);
  };

  const handleSaveData = async () => {
    if (!waterQualityName.trim()) {
      alert("Please enter your name before saving.");
      return;
    }

    const pHValue = parseFloat(roomData[selectedRoom].pH);
    const tempValue = parseFloat(roomData[selectedRoom].temp);
    const condValue = parseFloat(roomData[selectedRoom].cond);

    // Validate pH (0-14)
    if (isNaN(pHValue) || pHValue < 0 || pHValue > 14) {
      alert("pH value must be between 0 and 14.");
      return;
    }

    // Validate temperature (reasonable range, e.g., 0-100°C)
    if (isNaN(tempValue) || tempValue < 0 || tempValue > 100) {
      alert("Temperature must be between 0 and 100°C.");
      return;
    }

    // Validate conductivity (positive value)
    if (isNaN(condValue) || condValue < 0) {
      alert("Conductivity must be a positive value.");
      return;
    }

    try {
      const waterQualityLogData = {
        date: currentDate,
        time: currentTime,
        room: selectedRoom,
        pH: pHValue.toString(),
        temperature: tempValue.toString(),
        conductivity: condValue.toString(),
        completed_by: waterQualityName || "Unknown",
        notes: roomData[selectedRoom].notes || "",
      };

      const response = await axios.post(
        `${BASE_URL}/water-quality-logs`,
        waterQualityLogData
      );

      alert("Water quality data saved successfully!");
      navigate("/");
    } catch (err) {
      console.error("Error saving water quality data:", err);

      if (axios.isAxiosError(err)) {
        const errorMsg =
          err.response?.data?.error || "Failed to save water quality data";
        alert(errorMsg);
      } else {
        alert("Failed to save water quality data. Please try again.");
      }
    }
  };
  const handleBackToDashboard = () => {
    navigate("/");
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentTime(e.target.value);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(e.target.value);
    const today = new Date();
    if (isNaN(selectedDate.getTime())) {
      alert("Invalid date");
      return;
    }
    if (selectedDate <= today) {
      setCurrentDate(e.target.value);
    } else {
      alert("Cannot select future dates");
    }
  };

  const handleWaterQualityLogs = () => {
    navigate("/water-quality-logs");
  };

  const handleInputClick = (field: string) => {
    setPopupField(field);
    setShowPopup(true);
  };

  const handlePopupSave = () => {
    if (popupField && popupValue) {
      handleInputChange(selectedRoom, popupField, popupValue);
      setShowPopup(false);
      setPopupValue("");
    }
  };

  const renderLeftBar = () => {
    return (
      <div className="w-56 bg-[#232323] flex flex-col">
        <div
          onClick={() => navigate("/")}
          className=" cursor-pointer justify-center align-middle text-center py-4 mb-6 px-4 py-3 p-6 mt-4 text-sm text-left bg-[#1C1C1E] text-white hover:text-white"
          style={{ borderRadius: 8 }}
        >
          Back to Dashboard
        </div>

        <div
          className="rounded-sm bg-[#707070] mb-4 text-white p-2 m-2 border-t border-r border-l "
          style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
        >
          <div
            className="py-2 px-2 border-b border-gray-400 cursor-pointer"
            onClick={handleWaterQualityLogs}
          >
            Water Quality Log
          </div>
          <div className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between">
            <span className="text-sm">
              <span className="text-black">☆</span> pH Log
            </span>
            <span className="text-xs text-black">⇧ A</span>
          </div>
          <div className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between">
            <span className="text-sm">
              <span className="text-black">☆</span> Conductivity Log
            </span>
            <span className="text-xs text-black">⇧ A</span>
          </div>
          <div className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between">
            <span className="text-sm">
              <span className="text-black">☆</span> Temperature Log
            </span>
            <span className="text-xs text-black">⇧ A</span>
          </div>
          <div className="flex items-center border-t  p-2 mt-4 text-sm">
            <span className="mr-2 text-black">☆</span>
            <span>Other Water Quality</span>
            <span className="ml-auto text-black">⇧ A</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-black text-white flex">
      {/* Left Sidebar */}
      <WaterQualityLHS />

      {/* Main Content */}
      <div className="flex-1 bg-[#1E1E1E]">
        <div className="flex bg-[#262626] justify-between items-center p-6">
          <div className="text-[#858585]">
            <h1 className="text-2xl font-bold">
              Daily Feeding Log (All Rooms)
            </h1>
            <div className="text-sm text-gray-400">The Doe Lab</div>
          </div>

          <div className="flex items-center ">
            <div className="mr-4 text-blue-400">
              <input
                type="date"
                max={new Date().toISOString().split("T")[0]}
                value={currentDate}
                onChange={handleDateChange}
                className="bg-transparent"
              />
              <input
                type="time"
                value={currentTime}
                onChange={handleTimeChange}
                className="bg-transparent text-center"
              />
            </div>
            <div className="w-10 h-10 rounded-full bg-gray-500 flex items-center justify-center font-bold">
              {
                currentDateTime.toLocaleTimeString([], {
                  hour12: true,
                  hour: "2-digit",
                })[0]
              }
            </div>
          </div>
        </div>

        <div className="flex justify-between p-8 -mt-4">
          {/* Room Selection and Inputs */}
          <div className="lg:w-1/2">
            <div className="w-40">
              <label className="block text-sm mb-2">Select Room</label>
              <select
                className="bg-[#333] p-2 w-40 rounded w-full text-sm mb-4"
                value={selectedRoom}
                onChange={handleRoomChange}
              >
                {rooms.map((room) => (
                  <option key={room} value={room}>
                    {room}
                  </option>
                ))}
              </select>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-3 mr-32 justify-start">
              {/* First Row: pH and Temp */}
              <div className="flex items-center bg-white text-black rounded-full lg:px-3 px-2 py-2">
                <span className="text-sm mr-1">pH: </span>
                <input
                  type="text"
                  value={roomData[selectedRoom].pH}
                  onClick={() => handleInputClick("pH")}
                  className="bg-transparent outline-none w-16 text-sm cursor-pointer"
                  placeholder="[Value]"
                  readOnly
                />
              </div>

              <div className="flex items-center bg-white text-black rounded-full px-3 py-2">
                <span className="text-sm mr-1">Temp: </span>
                <input
                  type="text"
                  value={roomData[selectedRoom].temp}
                  onClick={() => handleInputClick("temp")}
                  className="bg-transparent outline-none w-16 text-sm cursor-pointer"
                  placeholder="[Value]"
                  readOnly
                />
              </div>

              {/* Second Row: Cond and Other */}
              <div className="flex items-center bg-white text-black rounded-full px-3 py-2">
                <span className="text-sm mr-1">Cond: </span>
                <input
                  type="text"
                  value={roomData[selectedRoom].cond}
                  onClick={() => handleInputClick("cond")}
                  className="bg-transparent outline-none w-16 text-sm cursor-pointer"
                  placeholder="[Value]"
                  readOnly
                />
              </div>
            </div>
          </div>

          {/* Notes Section */}
          <div className="w-1/2 mt-20">
            <label className="block text-sm mb-2">Notes</label>
            <textarea
              value={roomData[selectedRoom].notes}
              onChange={(e) =>
                handleInputChange(selectedRoom, "notes", e.target.value)
              }
              className="w-full bg-[#1E1E1E] border p-3 rounded resize-none  text-sm"
              placeholder="Text"
            />
          </div>
        </div>
        {/* Name Input */}
        <div className="mb-4 w-40 ml-7">
          <label className="block text-sm mb-2">Completed By</label>
          <div className="flex items-center bg-white text-black rounded-full px-3 py-2">
            <input
              type="text"
              value={waterQualityName}
              onChange={(e) => setWaterQualityName(e.target.value)}
              className="bg-transparent outline-none w-full text-sm"
              placeholder="Enter your name"
            />
          </div>
        </div>
        <div className="flex space-x-2 p-8 -mt-3">
          <button
            onClick={handleSaveData}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Save Water Quality Log
          </button>
        </div>
      </div>

      {/* Popup for entering values */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-[#1E1E1E] p-6 rounded-lg">
            <h2 className="text-lg mb-4">Enter {popupField} value</h2>
            <input
              type="text"
              value={popupValue}
              onChange={(e) => setPopupValue(e.target.value)}
              className="bg-transparent border p-2 rounded w-full mb-4"
            />
            <div className="flex justify-end">
              <button
                onClick={() => setShowPopup(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handlePopupSave}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WaterQuality;
