import { useNavigate, useLocation } from "react-router-dom";

const FeedingLHS = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = (path: string) => location.pathname === path;

    const handleNavigate = (path:string)=>{
      navigate(path);
    }
  

  return (
    <div className="w-56 bg-[#232323] flex flex-col">

      <div onClick={() => navigate("/")}
        className=" cursor-pointer justify-center align-middle text-center py-4 mb-6 px-4 py-3 p-6 mt-4 text-sm text-left bg-[#1C1C1E] text-white hover:text-white" style={{ borderRadius: 8 }}
      >
        Back to Dashboard
      </div>

      <div className="rounded-sm bg-[#707070] mb-4 text-white p-2 m-2 border-t border-r border-l " style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }} >
        <div className="py-2 px-2 border-b border-gray-400 cursor-pointer">
          Feeding 
        </div>
        <div
          onClick={()=>handleNavigate('/feeding/f2')}
          className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between"
          style={{ opacity: isActive('/feeding/f2') ? 1 : 0.6, fontWeight:  isActive('/feeding/f2') ? 'bold' : undefined }}
          >
          <span className="text-sm"><span className='text-black' >☆</span> Daily Feed Entry</span>
          <span className="text-xs text-black">⇧ A</span>
        </div>
        <div
          onClick={()=>handleNavigate('/food-record')}
          style={{ opacity: isActive('/food-record') ? 1 : 0.8,  fontWeight:  isActive('/food-record') ? 'bold' : undefined  }}
          className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between">
          <span className="text-sm"><span className='text-black' >☆</span> Daily Feeding Log</span>
          <span className="text-xs text-black">⇧ A</span>
        </div>
        <div
          onClick={()=>handleNavigate('/nutrition-record')}
          style={{ opacity: isActive('/nutrition-record') ? 1 : 0.8,  fontWeight:  isActive('/nutrition-record') ? 'bold' : undefined  }}
          className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between">
          <span className="text-sm"><span className='text-black' >☆</span> Nutrition Log</span>
          <span className="text-xs text-black">⇧ A</span>
        </div>
      </div>
    </div>

  )
}
export default FeedingLHS;