import { useState, useEffect } from "react";
import { formatDate, formatTime } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import TankCleaningLHS from "./TankCleaningLHS";
import { BASE_URL } from "../components/apiService";
import axios from "axios";

// Interface for cleaning log data
interface TankCleaningLog {
  id: number;
  name: string;
  date: string;
  time: string;
  room: string;
  rack: string;
  notes: string;
  startTime: string;
  stopTime: string | null;
}

function TankCleaningRecord() {
  const [currentDateTime, setCurrentDateTime] = useState<Date>(new Date());
  const [cleaningLogs, setCleaningLogs] = useState<TankCleaningLog[]>([]);
  const [isFetchingLogs, setIsFetchingLogs] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [deleteLoading, setDeleteLoading] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const navigate = useNavigate();

  // Fetch cleaning logs from API
  const fetchCleaningLogs = async () => {
    setIsFetchingLogs(true);
    try {
      const response = await fetch(`${BASE_URL}/tank-cleaning-logs`);
      if (!response.ok) {
        throw new Error("Failed to fetch cleaning logs");
      }
      const data = await response.json();
      setCleaningLogs(data);
    } catch (error) {
      console.error("Error fetching cleaning logs:", error);
      showAlert("error", "Failed to fetch cleaning logs");
    } finally {
      setIsFetchingLogs(false);
    }
  };

  // Fetch logs on component mount
  useEffect(() => {
    fetchCleaningLogs();
  }, []);

  const handleDeleteLog = async (logId: number) => {
    if (!window.confirm("Are you sure you want to delete this record?")) {
      return;
    }

    try {
      setDeleteLoading(logId);
      const response = await axios.delete(
        `${BASE_URL}/tankcleaning-log/${logId}`
      );

      if (response.status === 200) {
        // Update the logs list by removing the deleted log
        setCleaningLogs((prevLogs) =>
          prevLogs.filter((log) => log.id !== logId)
        );

        // Update pagination if needed
        const remainingRecords = cleaningLogs.length - 1;
        const newTotalPages = Math.ceil(remainingRecords / itemsPerPage);
        if (currentPage > newTotalPages && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }

        showAlert("success", "Record deleted successfully");
      } else {
        throw new Error("Failed to delete record");
      }
    } catch (err) {
      console.error("Error deleting tank cleaning record:", err);
      setError("Failed to delete record. Please try again later.");
      showAlert("error", "Failed to delete record");
    } finally {
      setDeleteLoading(null);
    }
  };

  // Alert function
  const showAlert = (type: "success" | "error", message: string) => {
    alert(`${type.toUpperCase()}: ${message}`);
    // In a real app, you might use a toast notification library
  };

  const formatDateTime = (dateTimeString: string | null): string => {
    if (!dateTimeString) return "-";

    const date = new Date(dateTimeString);
    return date.toLocaleString(); // Adjust the format as needed
  };

  const formatDateField = (dateString: string | null): string => {
    if (!dateString) return "-";

    const date = new Date(dateString);
    return date.toLocaleDateString(); // Adjust the format as needed
  };

  // Calculate total time worked in hours and minutes
  const calculateTotalTimeWorked = (
    startTime: string,
    stopTime: string | null
  ): string => {
    if (!startTime || !stopTime) return "-";

    const start = new Date(startTime);
    const stop = new Date(stopTime);

    // Return early if dates are invalid
    if (isNaN(start.getTime()) || isNaN(stop.getTime())) return "-";

    // Calculate difference in milliseconds
    const diffMs = stop.getTime() - start.getTime();

    // If negative time difference or invalid calculation, return dash
    if (diffMs < 0) return "-";

    // Convert to hours, minutes, and seconds
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
    const diffSeconds = Math.floor((diffMs % (1000 * 60)) / 1000);

    return `${diffHours}h ${diffMinutes}m ${diffSeconds}s`;
  };

  const handleTimeEntry = () => {
    navigate("/tank-cleaning");
  };

  const handleTankCleaningRecord = () => {
    navigate("/tank-cleaning-record");
  };

  const newDateValue = (dateValue: string) => {
    const updatedDate = new Date(dateValue);
    const currentTime = new Date(currentDateTime);

    updatedDate.setHours(currentTime.getHours());
    updatedDate.setMinutes(currentTime.getMinutes());

    return updatedDate;
  };

  const newTimeValue = (timeValue: string) => {
    const [hours, minutes] = timeValue.split(":");
    const updatedTime = new Date(currentDateTime);

    updatedTime.setHours(parseInt(hours));
    updatedTime.setMinutes(parseInt(minutes));

    return updatedTime;
  };

  // Filter logs based on search term
  const filteredLogs = cleaningLogs.filter(
    (log) =>
      log.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      log.room.toLowerCase().includes(searchTerm.toLowerCase()) ||
      log.rack.toLowerCase().includes(searchTerm.toLowerCase()) ||
      log.notes.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate pagination values
  const totalPages = Math.ceil(filteredLogs.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLogs.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination handlers
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderHeader = () => {
    return (
      <div className="flex-1 bg-[#1E1E1E]">
        <div className="flex bg-[#262626] justify-between items-center p-6">
          <div className="text-[#858585]">
            <h1 className="text-2xl font-bold">Tank Cleaning Record</h1>
            <div>The Doe Lab</div>
          </div>

          <div className="flex items-center">
            <div className="flex space-x-2 text-blue-400 px-2 py-1 rounded">
              <input
                type="date"
                value={formatDate(currentDateTime)}
                onChange={(e) =>
                  setCurrentDateTime(newDateValue(e.target.value))
                }
                className="px-2 py-1 bg-[#1C1C1E] rounded text-sm text-blue-400 text-center appearance-none cursor-pointer focus:outline-none"
              />
              <input
                type="time"
                value={formatTime(currentDateTime)}
                onChange={(e) =>
                  setCurrentDateTime(newTimeValue(e.target.value))
                }
                className="px-2 py-1 bg-[#1C1C1E] rounded text-sm text-blue-400 text-center appearance-none cursor-pointer focus:outline-none"
              />
            </div>

            <div className="w-10 h-10 rounded-full bg-gray-500 flex items-center justify-center font-bold">
              {
                currentDateTime.toLocaleTimeString([], {
                  hour12: true,
                  hour: "2-digit",
                })[0]
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <div className="bg-[#262626] rounded-lg p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Tank Cleaning Logs</h2>
          <div className="relative">
            <input
              type="text"
              placeholder="Search logs..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="px-3 py-2 bg-[#333] rounded text-white focus:outline-none"
            />
          </div>
        </div>

        {error && (
          <div className="bg-red-500 text-white p-2 mb-4 rounded">{error}</div>
        )}

        {isFetchingLogs ? (
          <div className="text-center py-8">Loading...</div>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-[#333333] text-left">
                    <th className="p-3 border border-[#444]"></th>
                    <th className="p-3 border border-[#444]">Name</th>
                    <th className="p-3 border border-[#444]">Date</th>
                    <th className="p-3 border border-[#444]">Time</th>
                    <th className="p-3 border border-[#444]">Room</th>
                    <th className="p-3 border border-[#444]">Rack</th>
                    <th className="p-3 border border-[#444]">Start Time</th>
                    <th className="p-3 border border-[#444]">Stop Time</th>
                    <th className="p-3 border border-[#444]">
                      Total Time Worked
                    </th>
                    <th className="p-3 border border-[#444]">Notes</th>
                    <th className="p-3 border border-[#444]">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.length > 0 ? (
                    currentItems.map((log, index) => (
                      <tr key={log.id} className="hover:bg-[#333]">
                        <td className="p-3 border border-[#444] bg-[#333333]">
                          {indexOfFirstItem + index + 1}
                        </td>
                        <td className="p-3 border border-[#444]">{log.name}</td>
                        <td className="p-3 border border-[#444]">
                          {formatDateField(log.date)}
                        </td>
                        <td className="p-3 border border-[#444]">{log.time}</td>
                        <td className="p-3 border border-[#444]">{log.room}</td>
                        <td className="p-3 border border-[#444]">{log.rack}</td>
                        <td className="p-3 border border-[#444]">
                          {formatDateTime(log.startTime)}
                        </td>
                        <td className="p-3 border border-[#444]">
                          {formatDateTime(log.stopTime)}
                        </td>
                        <td className="p-3 border border-[#444]">
                          {calculateTotalTimeWorked(
                            log.startTime,
                            log.stopTime
                          )}
                        </td>
                        <td className="p-3 border border-[#444]">
                          {log.notes}
                        </td>
                        <td className="px-4 py-2 text-left border border-gray-700">
                          <button
                            onClick={() => handleDeleteLog(log.id)}
                            className="text-red-500 hover:text-red-700"
                            title="Delete Record"
                            disabled={deleteLoading === log.id}
                          >
                            {deleteLoading === log.id ? "..." : "✖"}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={11} className="p-3 text-center">
                        No cleaning logs found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {filteredLogs.length > 0 && (
              <div className="flex justify-between items-center mt-4">
                <button
                  onClick={goToPreviousPage}
                  disabled={currentPage === 1}
                  className="px-4 py-2 bg-[#232323] text-white rounded disabled:opacity-50"
                >
                  Prev
                </button>
                <span className="text-white">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={goToNextPage}
                  disabled={currentPage === totalPages}
                  className="px-4 py-2 bg-[#232323] text-white rounded disabled:opacity-50"
                >
                  Next
                </button>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-[#1C1C1E] text-white flex">
      <div className="bg-[#232323] min-h-screen">
        <TankCleaningLHS />
      </div>

      <div className="flex flex-col w-full">
        <div className="h-16 flex items-center">{renderHeader()}</div>

        <div className="flex-1 px-4 py-6">{renderTable()}</div>
      </div>
    </div>
  );
}

export default TankCleaningRecord;
