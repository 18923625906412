import React, { useState, useEffect } from "react";
import { formatDate, formatTime } from "../../utils/utils";
import DropDownSelectInput from "../../components/DropDownSelectInput";
import InputDescriptionBox from "../../components/InputDescriptionBox";
import { useNavigate } from "react-router-dom";
import CategoryButton from "../../components/CategoryButton";
import Modal from "../../components/Modal";
import { BASE_URL } from "../../components/apiService";
import BirthLHS from "./BirthLHS";
import BirthAnalyticsGraph from "./BirthAnalyticsGraph";
import axios from "axios";

function BirthEntryPage() {
  const [currentDateTime, setCurrentDateTime] = useState<Date>(new Date());
  const navigate = useNavigate();

  const [showTypeModal, setShowTypeModal] = useState<boolean>(false);
  const [species, setSpecies] = useState<string>("");
  const [tank, setTank] = useState<string>("");
  const [parents, setParents] = useState<string>("");
  const [numOfFish, setNumOfFish] = useState<string>("");
  const [seperated, setSeperated] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [room, setRoom] = useState<string>("Main Room");
  const [name, setName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [speciesOptions, setSpeciesOptions] = useState<string[]>([]);
  const [tankOptions, setTankOptions] = useState<string[]>([]);
  const [ageOptions, setAgeOptions] = useState<number[]>([]);
  const [numOfFishOptions, setNumOfFishOptions] = useState<number[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>(
    formatDate(new Date())
  );

  const handleNavigateToRecord = () => {
    navigate("/BirthRecordPage");
  };

  const handleNavigateToEntry = () => {
    navigate("/BirthEntryPage");
  };

  // Fetch rooms list when component mounts
  const roomsList = ["Main Room", "Side Room", "Upper Room"];

  // Update current date time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const fetchSpecies = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/species`);
      const speciesData = response.data.map(
        (item: { species_name: string }) => item.species_name
      );
      setSpeciesOptions(speciesData);
    } catch (error) {
      console.error("Error fetching species:", error);
    }
  };

  const fetchTanks = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/tanks`);
      const tankData = response.data.map(
        (item: { tank_name: string }) => item.tank_name
      );
      setTankOptions(tankData);
    } catch (error) {
      console.error("Error fetching tanks:", error);
    }
  };

  useEffect(() => {
    fetchSpecies();
    fetchTanks();
    setAgeOptions(Array.from({ length: 100 }, (_, i) => i + 1));
    setNumOfFishOptions(Array.from({ length: 50 }, (_, i) => i + 1));
  }, []);

  // Handle date selection - separated from time for clarity
  const handleDateChange = (dateValue: string) => {

    const selectedDate = new Date(dateValue);
    const today = new Date();
    if (isNaN(selectedDate.getTime())) {
      alert("Invalid date");
      return;
    }

    if (selectedDate > today) {
      alert("Cannot select future dates");
      return;
    } 
    setSelectedDate(dateValue);

    // Update the currentDateTime with the new date while preserving the time
    const [year, month, day] = dateValue.split("-").map(Number);
    const newDate = new Date(currentDateTime);
    newDate.setFullYear(year);
    newDate.setMonth(month - 1); // Month is 0-indexed in JavaScript Date
    newDate.setDate(day);

    setCurrentDateTime(newDate);
  };

  // Handle time selection - only updates the time part of currentDateTime
  const handleTimeChange = (timeValue: string) => {
    const selectedDate = new Date(timeValue);
    const today = new Date();
    
    if (selectedDate > today) {
      alert("Cannot select future dates");
      return;
    } 
    const [hours, minutes] = timeValue.split(":").map(Number);
    const newDateTime = new Date(currentDateTime);
    newDateTime.setHours(hours);
    newDateTime.setMinutes(minutes);

    setCurrentDateTime(newDateTime);
  };

  const handleSelectPress = (type: string) => {
    setShowTypeModal(true);
    setSelectedTab(type);
  };

  const handleSetValue = (value: string) => {
    switch (selectedTab) {
      case "Species":
        setSpecies(value);
        break;
      case "Tank":
        setTank(value);
        break;
      case "Parents":
        setParents(value);
        break;
      case "NoOfFish":
        setNumOfFish(value);
        break;
      case "Seperated":
        setSeperated(value);
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    if (!room) return "Please select a room";
    if (!name) return "Please enter a name";
    if (!species) return "Please select a species";
    if (!tank) return "Please enter a tank number";
    if (!parents) return "Please enter parent information";
    if (!numOfFish || isNaN(parseInt(numOfFish)))
      return "Please enter a valid number of fish";
    return null;
  };

  // Get properly formatted date string for the API
  const getFormattedDateForAPI = (date: Date) => {
    // Ensure date is in YYYY-MM-DD format with proper timezone handling
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSave = async () => {
    const validationError = validateForm();
    if (validationError) {
      alert(validationError);
      return;
    }

    setIsLoading(true);

    // Use the selected date directly to avoid timezone issues
    const formattedDate = selectedDate;

    const birthData = {
      date: formattedDate,
      time: formatTime(currentDateTime),
      room,
      name,
      species,
      tank,
      parents,
      num_of_fish: parseInt(numOfFish),
      seperated,
      notes,
    };

    try {
      const response = await fetch(`${BASE_URL}/birth-log`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(birthData),
      });

      if (response.ok) {
        setSaveSuccess(true);
        setTimeout(() => setSaveSuccess(false), 3000);

        const shouldRedirect = window.confirm("Record saved successfully!");
        if (shouldRedirect) {
          navigate("/");
        }

        // Reset form fields
        setName("");
        setSpecies("");
        setTank("");
        setParents("");
        setNumOfFish("");
        setSeperated("");
        setNotes("");
        setRoom("Main Room");
      } else {
        const errorData = await response.json();
        alert(
          `Failed to save birth log: ${errorData.message || "Unknown error"}`
        );
      }
    } catch (error) {
      console.error("Error saving birth log:", error);
      alert("Error saving birth log. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const renderLeftBar = () => {
    return (
      <div className="w-56 bg-[#232323] flex flex-col">
        <div
          onClick={() => navigate("/")}
          className="cursor-pointer justify-center align-middle text-center py-4 mb-6 px-4 py-3 p-6 mt-4 text-sm text-left bg-[#1C1C1E] text-white hover:text-white"
          style={{ borderRadius: 8 }}
        >
          Back to Dashboard
        </div>

        <div
          className="rounded-sm bg-[#707070] mb-4 text-white p-2 m-2 border-t border-r border-l"
          style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
        >
          <div className="py-2 px-2 border-b border-gray-400 cursor-pointer">
            Birth Log
          </div>
          <div
            onClick={handleNavigateToRecord}
            className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between"
          >
            <span className="text-sm">
              <span className="text-black">☆</span> Birth Record
            </span>
            <span className="text-xs text-black">⇧ A</span>
          </div>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex-1 bg-[#1E1E1E]">
        <div className="flex bg-[#262626] justify-between items-center p-6">
          <div className="text-[#858585]">
            <h1 className="text-2xl font-bold">Birth Entry</h1>
          </div>

          <div className="flex items-center">
            <div className="flex space-x-2 text-blue-400 px-2 py-1 rounded">
              <input
                type="date"
                max={new Date().toISOString().split("T")[0]}
                value={selectedDate}
                onChange={(e) => handleDateChange(e.target.value)}
                className="px-2 py-1 bg-[#1C1C1E] rounded text-sm text-blue-400 text-center appearance-none cursor-pointer focus:outline-none"
              />
              <input
                type="time"
                max={new Date().toISOString().split("T")[0]}
                value={formatTime(currentDateTime)}
                onChange={(e) => handleTimeChange(e.target.value)}
                className="px-2 py-1 bg-[#1C1C1E] rounded text-sm text-blue-400 text-center appearance-none cursor-pointer focus:outline-none"
              />
            </div>

            <div className="w-10 h-10 rounded-full bg-gray-500 flex items-center justify-center font-bold">
              {
                currentDateTime.toLocaleTimeString([], {
                  hour12: true,
                  hour: "2-digit",
                })[0]
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSpecialInputs = () => {
    return (
      <div className="flex flex-col md:flex-row gap-8 md:gap-40 mt-10">
        <div>
          {/* Name input field */}
          <div className="mb-4">
            <label className="block text-white text-sm mb-2">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-3 py-2 bg-[#2C2C2E] text-white rounded-md"
              placeholder="Enter name"
            />
          </div>

          <div className="flex flex-wrap gap-2">
            <CategoryButton
              label={`Species: ${species || "Select..."}`}
              onClick={() => handleSelectPress("Species")}
              onClose={() => setSpecies("")}
            />
            <CategoryButton
              label={`Tank: ${tank || "Select..."}`}
              onClick={() => handleSelectPress("Tank")}
              onClose={() => setTank("")}
            />
            <CategoryButton
              label={`Parents: ${parents || "Select..."}`}
              onClick={() => handleSelectPress("Parents")}
              onClose={() => setParents("")}
            />
          </div>

          <div className="flex flex-wrap gap-2 mt-2">
            <CategoryButton
              label={`# of Fish: ${numOfFish || "Select..."}`}
              onClick={() => handleSelectPress("NoOfFish")}
              onClose={() => setNumOfFish("")}
            />
            <CategoryButton
              label={`Seperated: ${seperated || "Select..."}`}
              onClick={() => handleSelectPress("Seperated")}
              onClose={() => setSeperated("")}
            />
          </div>
        </div>

        <div className="w-full md:w-[320px] mt-4 md:-mt-6">
          <InputDescriptionBox
            title="Notes"
            value={notes}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setNotes(e.target.value)
            }
          />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div>
        <div className="flex flex-row justify-between">
          <div>
            <div className="flex flex-row items-center space-x-4">
              <div className="text-white text-lg">Select Room</div>
              <div className="w-[150px] border">
                <DropDownSelectInput
                  itemsList={roomsList}
                  value={room}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setRoom(e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {renderSpecialInputs()}

        <div className="mt-8">
          <button
            onClick={handleSave}
            disabled={isLoading}
            className={`px-6 py-2 rounded-md ${
              isLoading
                ? "bg-gray-600 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700"
            }`}
          >
            {isLoading ? "Saving..." : "Save Birth Record"}
          </button>

          {saveSuccess && (
            <span className="ml-4 text-green-500">
              Record saved successfully!
            </span>
          )}
        </div>

        <div className="mt-8">
          <div className="text-center text-lg">
            Number of Births by Species Over Time
          </div>
          <BirthAnalyticsGraph />
        </div>
      </div>
    );
  };

  const renderModalContent = () => {
    switch (selectedTab) {
      case "Species":
        return (
          <div className="space-y-4">
            {speciesOptions.map((option) => (
              <div
                key={option}
                onClick={() => {
                  handleSetValue(option);
                  setShowTypeModal(false);
                }}
                className="p-3 bg-gray-700 rounded cursor-pointer hover:bg-gray-600"
              >
                {option}
              </div>
            ))}
          </div>
        );
      case "Tank":
        return (
          <div className="space-y-4">
            {tankOptions.map((option) => (
              <div
                key={option}
                onClick={() => {
                  handleSetValue(option);
                  setShowTypeModal(false);
                }}
                className="p-3 bg-gray-700 rounded cursor-pointer hover:bg-gray-600"
              >
                {option}
              </div>
            ))}
          </div>
        );
      case "Parents":
        return (
          <div>
            <input
              type="text"
              placeholder="Enter Parents"
              className="p-3 bg-gray-700 rounded w-full text-white"
              onChange={(e) => handleSetValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setShowTypeModal(false);
                }
              }}
              autoFocus
            />
            <button
              onClick={() => setShowTypeModal(false)}
              className="mt-4 px-4 py-2 bg-blue-600 rounded hover:bg-blue-700 w-full"
            >
              Confirm
            </button>
          </div>
        );
      case "NoOfFish":
        return (
          <div className="space-y-4 max-h-96 overflow-y-auto">
            {numOfFishOptions.map((option) => (
              <div
                key={option}
                onClick={() => {
                  handleSetValue(option.toString());
                  setShowTypeModal(false);
                }}
                className="p-3 bg-gray-700 rounded cursor-pointer hover:bg-gray-600"
              >
                {option}
              </div>
            ))}
          </div>
        );

      case "Seperated":
        return (
          <div className="space-y-4">
            {["Yes", "No"].map((option) => (
              <div
                key={option}
                onClick={() => {
                  handleSetValue(option);
                  setShowTypeModal(false);
                }}
                className="p-3 bg-gray-700 rounded cursor-pointer hover:bg-gray-600"
              >
                {option}
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-[#1C1C1E] text-white flex flex-col md:flex-row">
      <div className=" bg-[#232323] min-h-screen">
        <BirthLHS />
      </div>
      {/* Right Section (Header + Body) */}
      <div className="flex flex-col w-full">
        {/* Header */}
        {renderHeader()}

        {/* Body */}
        <div className="flex-1 px-4 py-6">{renderBody()}</div>
      </div>

      {/* Modal for selections */}
      <Modal
        isOpen={showTypeModal}
        onClose={() => setShowTypeModal(false)}
        title={`Select ${
          selectedTab === "NoOfFish" ? "Number of Fish" : selectedTab
        }`}
      >
        {renderModalContent()}
      </Modal>
    </div>
  );
}

export default BirthEntryPage;
