interface DropDownSelectInputProps {
  itemsList?: string[];
  selectedItemIdx?: number;
  title?: string;
  whiteBg?: boolean;
  marginTop?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

function DropDownSelectInput({
  itemsList = [],
  selectedItemIdx,
  title,
  whiteBg,
  marginTop,
  value,
  onChange,
}: DropDownSelectInputProps) {
  return (
    <div className={`${marginTop ? marginTop : ""}`}>
      {title && <div className="text-sm font-medium mb-2">{title}</div>}
      <select
        className={`w-full p-2 rounded ${
          whiteBg ? "bg-white" : "bg-[#1C1C1E]"
        }`}
        value={value}
        onChange={onChange}
      >
        {itemsList.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
}

export default DropDownSelectInput;
