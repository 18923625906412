import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sidebarItems } from "../utils/data";
import Flag from "../assets/images/Flag.png";
import AllDocumentsLHS from "./AllDocumentsLHS";
import axios from "axios";
import { BASE_URL } from "../components/apiService";
interface DocumentData {
  id: number;
  document_name: string;
  uploaded_by: string;
  created_at: string;
  is_important: boolean;
  category: string;
  file_url: string;
}

interface PaginationResponse {
  fileList: DocumentData[];
  currentPage: number;
  totalPages: number;
  totalRecords: number;
}

const AllDocuments: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [documents, setDocuments] = useState<DocumentData[]>([]);
  const [uploadForm, setUploadForm] = useState({
    document_name: "",
    uploaded_by: "",
    is_important: false,
    category: "Standard Operating Procedures",
  });

  const [uploadingFiles, setUploadingFiles] = useState<string[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  // Pagination state
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  useEffect(() => {
    setTimeout(() => {
      fetchFileList();
    }, 1000);
  }, []);

  const handleNavigation = (item: string) => {
    if (item === "Feeding" || item === "fish feeding") {
      navigate("/feeding/f2");
    } else if (item === "water quality") {
      navigate("/water-quality");
    } else if (item === "Fish Morbidity") {
      navigate("/MorbidityEntry");
    } else if (item === "tank cleaning") {
      navigate("/tank-cleaning");
    } else if (item === "Fish Birth") {
      navigate("/BirthEntryPage");
    } else if (item === "All Documents") {
      navigate("/AllDocuments");
    }
  };

  const handleUploadFiles = async () => {
    // Simulate file upload
    const isSuccess = await handleUploadFile(selectedFile);
    if (isSuccess === -1) {
      return;
    }

    setIsUploadModalOpen(false);

    // Reset form
    setUploadForm({
      document_name: "",
      uploaded_by: "",
      is_important: false,
      category: "Standard Operating Procedures",
    });

    setUploadingFiles([]);
    setUploadedFiles([]);
  };

  const getFileExtension = (filename: string): string => {
    const ext = filename.split(".").pop() || "";
    return ext.toUpperCase();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      // Extract file extension and set it as the type
      const fileType = getFileExtension(file.name);

      // Update the form with the file and its type
      setUploadForm({
        ...uploadForm,
      });

      setSelectedFile(file);
      // Simulate file upload process
      setUploadingFiles([file.name]);

      // Simulate progress
      // let progress = 0;
      // const interval = setInterval(() => {
      //   progress += 5;
      //   setUploadProgress(progress);

      //   if (progress >= 100) {
      //     clearInterval(interval);
      //     setUploadingFiles([]);
      //     setUploadedFiles([file.name]);
      //   }
      // }, 100);
    }
  };

  // Save feeding log
  const handleUploadFile = async (file: any) => {
    if (file === null) {
      alert("Please select correct file.");
      return -1;
    }
    if (
      !uploadForm.document_name ||
      !uploadForm.uploaded_by ||
      !uploadForm.category
    ) {
      alert("Please input all values.");
      return -1;
    }
    try {
      const response = await axios.post(
        `${BASE_URL}/upload`,

        {
          file,
          document_name: uploadForm.document_name,
          uploaded_by: uploadForm.uploaded_by,
          category: uploadForm.category,
          is_important: uploadForm.is_important ? 1 : 0,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("File uploaded successfully:", response);
        let data: PaginationResponse;
        try {
          data = response.data;
          setDocuments(data.fileList);
          setCurrentPage(data.currentPage);
          setTotalPages(data.totalPages);
          setTotalRecords(data.totalRecords);
        } catch (parseError) {
          console.error("JSON Parsing Error:", parseError);
          throw new Error("Invalid JSON response");
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file. Please try again.");
      return -1;
    }
  };

  // Fetch paginated feeding logs from the backend
  const fetchFileList = async (page: number = 1) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${BASE_URL}/file-list?page=${page}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      const responseText = await response.text();
      console.log("Raw response:", responseText);

      let data: PaginationResponse;
      try {
        data = JSON.parse(responseText);
      } catch (parseError) {
        console.error("JSON Parsing Error:", parseError);
        throw new Error("Invalid JSON response");
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Set feeding logs and pagination details
      setDocuments(data.fileList);
      setCurrentPage(data.currentPage);
      setTotalPages(data.totalPages);
      setTotalRecords(data.totalRecords);
    } catch (error) {
      console.error("Error fetching feeding logs:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileDelete = (fileName: string) => {
    setUploadedFiles(uploadedFiles.filter((name) => name !== fileName));
    // Also reset the file type when file is deleted
    setUploadForm({
      ...uploadForm,
    });
  };

  const filteredDocuments =
    selectedCategory === "All"
      ? documents
      : documents.filter((doc) => doc.category === selectedCategory);

  return (
    <div className="bg-[#262626] text-[#858585] min-h-screen flex">
      {/* Sidebar */}
      <AllDocumentsLHS
        handleShowUploadModal={() => setIsUploadModalOpen(true)}
        isUploadModalOpen={isUploadModalOpen}
      />

      {/* Main Content */}
      <main className="flex-1 bg-[#1E1E1E]">
        <div className="p-6">
          <div className="flex flex-col">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h1 className="text-2xl font-semibold text-white">
                  Document Hub
                </h1>
                <p className="text-sm text-gray-400">The Doe Lab</p>
              </div>
              <button
                onClick={() => setIsUploadModalOpen(true)}
                className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-md"
              >
                Upload
              </button>
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-white">
                Category
              </label>
              <select
                className="bg-[#262626] border border-gray-600 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-60 p-2.5"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="All">All</option>
                <option value="Emergency Protocols and Contact">
                  Emergency Protocols and Contact
                </option>
                <option value="Standard Operating Procedures">
                  Standard Operating Procedures
                </option>
                <option value="Animal Care Approvals">
                  Animal Care Approvals
                </option>
              </select>
            </div>

            <div className="overflow-x-auto bg-[#262626] rounded-lg shadow">
              <table className="w-full text-sm text-left text-gray-300">
                <thead className="text-xs text-gray-400 uppercase bg-[#1C1C1E] border-b border-gray-700">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Document Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Type
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Uploaded By
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Uploaded Date
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Flag (Important)
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Category
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDocuments.map((doc) => (
                    <tr
                      key={doc.id}
                      className="border-b border-gray-700 hover:bg-[#2C2C2E]"
                    >
                      <td className="px-6 py-4 font-medium text-white">
                        <a href={doc.file_url} target="_blank" rel="noopener noreferrer">{doc.document_name}</a>
                      </td>
                      <td className="px-6 py-4">
                        {getFileExtension(doc.file_url)}
                      </td>
                      <td className="px-6 py-4">{doc.uploaded_by}</td>
                      <td className="px-6 py-4">{doc.created_at}</td>
                      <td className="px-6 py-4">
                        {doc.is_important ? (
                          <div className=" items-center justify-center inline-flex ">
                            <span className=" text-white rounded-md">Yes</span>
                            <img
                              src={Flag}
                              className="h-5 w-5 ms-4"
                              alt="Important flag"
                            />
                          </div>
                        ) : (
                          "No"
                        )}
                      </td>
                      <td className="px-6 py-4">{doc.category}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>

      {/* Upload Modal */}

      {isUploadModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg max-w-md w-full max-h-[90vh] flex flex-col">
            <div className="px-8 py-6 relative flex-shrink-0">
              <button
                type="button"
                className="absolute top-4 right-4 text-gray-700 font-bold hover:bg-gray-50 p-1 rounded"
                onClick={() => setIsUploadModalOpen(false)}
              >
                X
              </button>
              <div className="flex items-center justify-center">
                <h3 className="text-2xl font-bold text-gray-900 mb-4">
                  Upload
                </h3>
              </div>
            </div>

            {/* Scrollable content area */}
            <div className="px-8 pb-6 overflow-y-auto flex-grow">
              <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 mb-4">
                <div className="flex flex-col items-center">
                  <svg
                    className="w-12 h-12 text-indigo-500 mb-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500">
                    Drag & drop files or
                  </p>
                  <label className="text-indigo-600 hover:underline cursor-pointer">
                    Browse
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleFileChange}
                      accept=".jpeg,.jpg,.png,.gif,.mp4,.pdf,.psd,.ai,.doc,.docx,.ppt,.pptx"
                    />
                  </label>
                  <p className="mt-1 text-xs text-gray-500">
                    Supported formats: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word,
                    PPT
                  </p>
                </div>
              </div>

              {uploadingFiles.length > 0 && (
                <div className="mb-4">
                  <div className="mt-2 p-2 border border-gray-200 rounded">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-700">
                        {uploadingFiles[0]}
                      </span>
                      <span className="text-sm text-gray-500">
                        Type: {getFileExtension(selectedFile?.name || "")}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {uploadedFiles.length > 0 && (
                <div className="mb-4">
                  <p className="text-sm font-medium text-gray-700">Uploaded</p>
                  {uploadedFiles.map((file, index) => (
                    <div
                      key={index}
                      className="mt-2 p-2 border border-gray-200 rounded"
                    >
                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-700">{file}</span>
                        <div className="flex items-center">
                          <span className="text-sm text-gray-500 mr-3">
                            Type: {getFileExtension(selectedFile?.name || "")}
                          </span>
                          <button
                            className="text-red-400 hover:text-red-500"
                            onClick={() => handleFileDelete(file)}
                          >
                            <svg
                              className="w-4 h-4"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Document Name
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={uploadForm.document_name}
                  onChange={(e) =>
                    setUploadForm({
                      ...uploadForm,
                      document_name: e.target.value,
                    })
                  }
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Uploaded by
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={uploadForm.uploaded_by}
                  onChange={(e) =>
                    setUploadForm({
                      ...uploadForm,
                      uploaded_by: e.target.value,
                    })
                  }
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Category
                </label>
                <select
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={uploadForm.category}
                  onChange={(e) =>
                    setUploadForm({ ...uploadForm, category: e.target.value })
                  }
                >
                  <option value="Emergency Protocols and Contact">
                    Emergency Protocols and Contact
                  </option>
                  <option value="Standard Operating Procedures">
                    Standard Operating Procedures
                  </option>
                  <option value="Animal Care Approvals">
                    Animal Care Approvals
                  </option>
                </select>
              </div>

              <div className="mb-4">
                <div className="flex items-center">
                  <input
                    id="important"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    checked={uploadForm.is_important}
                    onChange={(e) =>
                      setUploadForm({
                        ...uploadForm,
                        is_important: e.target.checked,
                      })
                    }
                  />
                  <label
                    htmlFor="important"
                    className="ml-2 block text-sm text-gray-700"
                  >
                    Mark as Important
                  </label>
                </div>
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  className="w-full px-4 py-2 text-sm font-bold text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleUploadFiles}
                >
                  Upload Files
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllDocuments;
