import React, { useState, useEffect } from "react";
import { formatDate, formatTime, LightDarkBgColor } from "../../utils/utils";
import DropDownSelectInput from "../../components/DropDownSelectInput";
import ValueInput from "../../components/ValueInput";
import InputDescriptionBox from "../../components/InputDescriptionBox";
import AnalyticsGraph from "../../components/AnalyticsGraph";
import { useNavigate } from "react-router-dom";
import CategoryButton from "../../components/CategoryButton";
import Modal from "../../components/Modal";
import MorbidityLHS from "./MorbidityLHS";
import { BASE_URL } from "../../components/apiService";
import axios from "axios";

// Define interfaces for API data
interface SpeciesData {
  id: number;
  species_name: string;
}

interface TankData {
  id: number;
  tank_name: string;
}

function MorbidityEntryPage() {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const navigate = useNavigate();

  const [showTypeModal, setShowTypeModal] = useState(false);
  const [species, setSpecies] = useState("");
  const [speciesOptions, setSpeciesOptions] = useState<string[]>([]);
  const [tank, setTank] = useState("");
  const [tankOptions, setTankOptions] = useState<string[]>([]);
  const [age, setAge] = useState("");
  const [cause, setCause] = useState("");
  const [numOfFish, setNumOfFish] = useState("");
  const [preserved, setPreserved] = useState("");
  const [selectedTab, setSelectedTab] = useState("");
  const [notes, setNotes] = useState("");
  const [room, setRoom] = useState("Main Room"); // Default to "Main Room"
  const [name, setName] = useState(""); // New state for name field
  const [isLoading, setIsLoading] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isLoadingSpecies, setIsLoadingSpecies] = useState(false);
  const [isLoadingTanks, setIsLoadingTanks] = useState(false);

  // Generate arrays for dropdown options
  const ageOptions = Array.from(
    { length: 100 },
    (_, i) => `${i + 1} month${i !== 0 ? "s" : ""}`
  );
  const numOfFishOptions = Array.from({ length: 50 }, (_, i) =>
    (i + 1).toString()
  );

  // Fetch species data when component mounts
  useEffect(() => {
    const fetchSpecies = async () => {
      setIsLoadingSpecies(true);
      try {
        const response = await axios.get(`${BASE_URL}/species`);
        const speciesData = response.data.map(
          (item: SpeciesData) => item.species_name
        );
        setSpeciesOptions(speciesData);
      } catch (error) {
        console.error("Error fetching species:", error);
      } finally {
        setIsLoadingSpecies(false);
      }
    };

    fetchSpecies();
  }, []);

  // Fetch tank data when component mounts
  useEffect(() => {
    const fetchTanks = async () => {
      setIsLoadingTanks(true);
      try {
        const response = await axios.get(`${BASE_URL}/tanks`);
        const tankData = response.data.map((item: TankData) => item.tank_name);
        setTankOptions(tankData);
      } catch (error) {
        console.error("Error fetching tanks:", error);
      } finally {
        setIsLoadingTanks(false);
      }
    };

    fetchTanks();
  }, []);

  const handleNavigateToRecord = () => {
    navigate("/MorbidityRecord");
  };

  const handleNavigateToEntry = () => {
    navigate("/MorbidityEntry");
  };

  // Fetch rooms list when component mounts
  const roomsList = ["Main Room", "Side Room", "Upper Room"];

  // Update current date time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const handleSelectPress = (type: string) => {
    setShowTypeModal(true);
    setSelectedTab(type);
  };

  const handleSetValue = (value: string) => {
    switch (selectedTab) {
      case "Species":
        setSpecies(value);
        break;
      case "Tank":
        setTank(value);
        break;
      case "Age":
        setAge(value);
        break;
      case "Cause":
        setCause(value);
        break;
      case "NoOfFish":
        setNumOfFish(value);
        break;
      case "Preserved":
        setPreserved(value);
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    if (!room) return "Please select a room";
    if (!name) return "Please enter a name";
    if (!species) return "Please select a species";
    if (!tank) return "Please enter a tank number";
    if (!age) return "Please enter the age";
    if (!cause) return "Please enter a cause";
    if (!numOfFish || isNaN(parseInt(numOfFish)))
      return "Please enter a valid number of fish";
    if (!preserved) return "Please select if specimens were preserved";
    return null;
  };

  const handleSave = async () => {
    const validationError = validateForm();
    if (validationError) {
      alert(validationError);
      return;
    }

    setIsLoading(true);
    const morbidityData = {
      date: formatDate(currentDateTime),
      time: formatTime(currentDateTime),
      room,
      name,
      species,
      tank,
      age,
      cause,
      num_of_fish: parseInt(numOfFish),
      preserved,
      notes,
    };

    try {
      const response = await fetch(`${BASE_URL}/morbidity-log`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(morbidityData),
      });

      if (response.ok) {
        setSaveSuccess(true);
        setTimeout(() => setSaveSuccess(false), 3000);

        const shouldRedirect = window.confirm("Record saved successfully!");
        if (shouldRedirect) {
          navigate("/");
        }

        // Reset form fields
        setName("");
        setSpecies("");
        setTank("");
        setAge("");
        setCause("");
        setNumOfFish("");
        setPreserved("");
        setNotes("");
        setRoom("Main Room");
      } else {
        const errorData = await response.json();
        alert(
          `Failed to save morbidity log: ${
            errorData.message || "Unknown error"
          }`
        );
      }
    } catch (error) {
      console.error("Error saving morbidity log:", error);
      alert("Error saving morbidity log. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const newDateValue = (dateValue: string) => {
    const updatedDate = new Date(dateValue);
    const currentTime = new Date(currentDateTime);

    updatedDate.setHours(currentTime.getHours());
    updatedDate.setMinutes(currentTime.getMinutes());

    return updatedDate;
  };

  const newTimeValue = (timeValue: string) => {
    const [hours, minutes] = timeValue.split(":");
    const updatedTime = new Date(currentDateTime);

    updatedTime.setHours(parseInt(hours));
    updatedTime.setMinutes(parseInt(minutes));

    return updatedTime;
  };

  const renderSpecialInputs = () => {
    return (
      <div className="flex flex-col md:flex-row gap-8 md:gap-40 mt-10">
        <div>
          {/* Add Name input field */}
          <div className="mb-4">
            <label className="block text-white text-sm mb-2">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-3 py-2 bg-[#2C2C2E] text-white rounded-md"
              placeholder="Enter name"
            />
          </div>

          <div className="flex flex-wrap gap-2">
            <CategoryButton
              label={`Species: ${species || "Select..."}`}
              onClick={() => handleSelectPress("Species")}
              onClose={() => setSpecies("")}
            />
            <CategoryButton
              label={`Tank: ${tank || "Select..."}`}
              onClick={() => handleSelectPress("Tank")}
              onClose={() => setTank("")}
            />
            <CategoryButton
              label={`Age: ${age || "Select..."}`}
              onClick={() => handleSelectPress("Age")}
              onClose={() => setAge("")}
            />
          </div>

          <div className="flex flex-wrap gap-2 mt-2">
            <CategoryButton
              label={`Cause: ${
                cause && cause.length > 3
                  ? cause.substring(0, 3) + "..."
                  : cause || "Select..."
              }`}
              onClick={() => handleSelectPress("Cause")}
              onClose={() => setCause("")}
            />
            <CategoryButton
              label={`# of Fish: ${numOfFish || "Select..."}`}
              onClick={() => handleSelectPress("NoOfFish")}
              onClose={() => setNumOfFish("")}
            />
            <CategoryButton
              label={`Preserved: ${preserved || "Select..."}`}
              onClick={() => handleSelectPress("Preserved")}
              onClose={() => setPreserved("")}
            />
          </div>
        </div>

        <div className="w-full md:w-[320px] mt-4 md:-mt-6">
          <InputDescriptionBox
            title="Notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div>
        <div className="flex flex-row justify-between ">
          <div>
            <div className="flex flex-row items-center space-x-4">
              <div className="text-white text-lg">Select Room</div>
              <div className="w-[150px] border">
                <DropDownSelectInput
                  itemsList={roomsList}
                  value={room}
                  onChange={(e) => setRoom(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        {renderSpecialInputs()}

        <div className="mt-8">
          <button
            onClick={handleSave}
            disabled={isLoading}
            className={`px-6 py-2 rounded-md ${
              isLoading
                ? "bg-gray-600 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700"
            }`}
          >
            {isLoading ? "Saving..." : "Save Morbidity Record"}
          </button>

          {saveSuccess && (
            <span className="ml-4 text-green-500">
              Record saved successfully!
            </span>
          )}
        </div>

        <div className="mt-8">
          <div className="text-center text-lg">
            Number of Deaths by Species Over Time
          </div>
          <AnalyticsGraph />
        </div>
      </div>
    );
  };

  // Handler for date change (prevent future dates)
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(e.target.value);
    const today = new Date();
    if (isNaN(selectedDate.getTime())) {
      alert("Invalid date");
      return;
    }
    if (selectedDate <= today) {
      setCurrentDateTime(selectedDate);
    } else {
      alert("Cannot select future dates");
    }
  };

  const renderHeader = () => {
    return (
      <div className="flex-1 bg-[#1E1E1E]">
        <div className="flex bg-[#262626] justify-between items-center p-6">
          <div className="text-[#858585]">
            <h1 className="text-2xl font-bold"> Morbidity Record Entry</h1>
          </div>

          <div className="flex items-center ">
            <div className="flex space-x-2 text-blue-400  px-2 py-1 rounded">
              <input
                type="date"
                max={new Date().toISOString().split("T")[0]}
                value={formatDate(currentDateTime)}
                onChange={(e) =>
                  handleDateChange(e)
                }
                className="px-2 py-1 bg-[#1C1C1E] rounded text-sm text-blue-400 text-center appearance-none cursor-pointer focus:outline-none"
              />
              <input
                type="time"
                max={new Date().toISOString().split("T")[0]}
                value={formatTime(currentDateTime)}
                onChange={(e) =>
                  handleDateChange(e)
                }
                className="px-2 py-1 bg-[#1C1C1E] rounded text-sm text-blue-400 text-center appearance-none cursor-pointer focus:outline-none"
              />
            </div>

            <div className="w-10 h-10 rounded-full bg-gray-500 flex items-center justify-center font-bold">
              {
                currentDateTime.toLocaleTimeString([], {
                  hour12: true,
                  hour: "2-digit",
                })[0]
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderModalContent = () => {
    switch (selectedTab) {
      case "Species":
        return (
          <div className="space-y-4 max-h-96 overflow-y-auto">
            {isLoadingSpecies ? (
              <div className="p-3 text-center">Loading species...</div>
            ) : (
              speciesOptions.map((option) => (
                <div
                  key={option}
                  onClick={() => {
                    handleSetValue(option);
                    setShowTypeModal(false);
                  }}
                  className="p-3 bg-gray-700 rounded cursor-pointer hover:bg-gray-600"
                >
                  {option}
                </div>
              ))
            )}
          </div>
        );
      case "Tank":
        return (
          <div className="space-y-4 max-h-96 overflow-y-auto">
            {isLoadingTanks ? (
              <div className="p-3 text-center">Loading tanks...</div>
            ) : (
              tankOptions.map((option) => (
                <div
                  key={option}
                  onClick={() => {
                    handleSetValue(option);
                    setShowTypeModal(false);
                  }}
                  className="p-3 bg-gray-700 rounded cursor-pointer hover:bg-gray-600"
                >
                  {option}
                </div>
              ))
            )}
          </div>
        );
      case "Preserved":
        return (
          <div className="space-y-4">
            {["Yes", "No"].map((option) => (
              <div
                key={option}
                onClick={() => {
                  handleSetValue(option);
                  setShowTypeModal(false);
                }}
                className="p-3 bg-gray-700 rounded cursor-pointer hover:bg-gray-600"
              >
                {option}
              </div>
            ))}
          </div>
        );
      case "Age":
        return (
          <div className="space-y-4 max-h-96 overflow-y-auto">
            {ageOptions.map((option) => (
              <div
                key={option}
                onClick={() => {
                  handleSetValue(option);
                  setShowTypeModal(false);
                }}
                className="p-3 bg-gray-700 rounded cursor-pointer hover:bg-gray-600"
              >
                {option}
              </div>
            ))}
          </div>
        );
      case "NoOfFish":
        return (
          <div className="space-y-4 max-h-96 overflow-y-auto">
            {numOfFishOptions.map((option) => (
              <div
                key={option}
                onClick={() => {
                  handleSetValue(option);
                  setShowTypeModal(false);
                }}
                className="p-3 bg-gray-700 rounded cursor-pointer hover:bg-gray-600"
              >
                {option}
              </div>
            ))}
          </div>
        );
      case "Cause":
        return (
          <div>
            <input
              type="text"
              placeholder="Enter Cause"
              className="p-3 bg-gray-700 rounded w-full text-white"
              onChange={(e) => handleSetValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setShowTypeModal(false);
                }
              }}
              autoFocus
            />
            <button
              onClick={() => setShowTypeModal(false)}
              className="mt-4 px-4 py-2 bg-blue-600 rounded hover:bg-blue-700 w-full"
            >
              Confirm
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-[#1C1C1E] text-white flex flex-col md:flex-row">
      <div className=" bg-[#232323] min-h-screen">{<MorbidityLHS />}</div>

      {/* Right Section (Header + Body) */}
      <div className="flex flex-col w-full">
        {/* Header */}
        {renderHeader()}

        <div className="flex-1 px-4 py-6">{renderBody()}</div>
      </div>

      <Modal
        isOpen={showTypeModal}
        onClose={() => setShowTypeModal(false)}
        title={`Select ${
          selectedTab === "NoOfFish" ? "Number of Fish" : selectedTab
        }`}
      >
        {renderModalContent()}
      </Modal>
    </div>
  );
}

export default MorbidityEntryPage;
