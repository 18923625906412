import React, { useState, useEffect } from "react";
import axios from "axios";
import Dropdown from "./Dropdown";
import CategoryButton from "./CategoryButton";
import Modal from "./Modal";
import Input from "./Input";
import Button from "./Button";
import { BASE_URL } from "./apiService";

interface SpecialtyDietSectionProps {
  hasSpecialtyDiet: boolean;
  setHasSpecialtyDiet: (value: boolean) => void;
  selectedRoom: string;
  setSelectedRoom: (value: string) => void;
  tankNumber: string;
  setTankNumber: (value: string) => void;
  species: string;
  setSpecies: (value: string) => void;
  dietType: string;
  setDietType: (value: string) => void;
  dietAmount: string;
  setDietAmount: (value: string) => void;
}

const SpecialtyDietSection: React.FC<SpecialtyDietSectionProps> = ({
  hasSpecialtyDiet,
  setHasSpecialtyDiet,
  selectedRoom,
  setSelectedRoom,
  tankNumber,
  setTankNumber,
  species,
  setSpecies,
  dietType,
  setDietType,
  dietAmount,
  setDietAmount,
}) => {
  const [showTankModal, setShowTankModal] = useState<boolean>(false);
  const [showSpeciesModal, setShowSpeciesModal] = useState<boolean>(false);
  const [showDietTypeModal, setShowDietTypeModal] = useState<boolean>(false);
  const [showAmountModal, setShowAmountModal] = useState<boolean>(false);
  const [amountValue, setAmountValue] = useState<string>("");
  const [speciesOptions, setSpeciesOptions] = useState<string[]>([]);
  const [tankOptions, setTankOptions] = useState<
    { id: string; tank_name: string }[]
  >([]);
  const [dietTypeOptions, setDietTypeOptions] = useState<string[]>([]);

  const roomCategoryOptions = ["Lower Level", "Upper Level", "Main Room"];

  useEffect(() => {
    const fetchSpecies = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/species`);
        const speciesData = response.data.map(
          (item: { species_name: string }) => item.species_name
        );
        setSpeciesOptions(speciesData);
      } catch (error) {
        console.error("Error fetching species:", error);
      }
    };

    const fetchTanks = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/tanks`);
        const tankData = response.data.map(
          (item: { id: string; tank_name: string }) => ({
            id: item.id,
            tank_name: item.tank_name,
          })
        );
        setTankOptions(tankData);
      } catch (error) {
        console.error("Error fetching tanks:", error);
      }
    };

    const fetchDietTypes = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/food-types`);
        const dietTypeData = response.data.map(
          (item: { food_name: string }) => item.food_name
        );
        setDietTypeOptions(dietTypeData);
      } catch (error) {
        console.error("Error fetching diet types:", error);
      }
    };

    fetchSpecies();
    fetchTanks();
    fetchDietTypes();
  }, []);

  return (
    <div className="-mt-6">
      <div className="flex items-center mb-4">
        <div className="mr-4 ml-6">Any specialty diets?</div>
        <div className="flex gap-4">
          <div className="flex items-center">
            <input
              type="radio"
              id="specialtyYes"
              name="specialtyDiet"
              checked={hasSpecialtyDiet === true}
              onChange={() => setHasSpecialtyDiet(true)}
              className="mr-2"
            />
            <label htmlFor="specialtyYes">Yes</label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              id="specialtyNo"
              name="specialtyDiet"
              checked={hasSpecialtyDiet === false}
              onChange={() => setHasSpecialtyDiet(false)}
              className="mr-2"
            />
            <label htmlFor="specialtyNo">No</label>
          </div>
        </div>
      </div>

      {hasSpecialtyDiet && (
        <div className="ml-6">
          <div className="mb-2">Select Room:</div>
          <Dropdown
            value={selectedRoom}
            onChange={(e) => setSelectedRoom(e.target.value)}
            options={roomCategoryOptions}
            className="mb-4 w-64"
          />

          <div className="flex gap-4 flex-wrap mt-4">
            <CategoryButton
              label={`Tank: ${tankNumber || "Select..."}`}
              onClick={() => setShowTankModal(true)}
              onClose={() => setTankNumber("")}
            />
            <CategoryButton
              label={`Species: ${species || "Select..."}`}
              onClick={() => setShowSpeciesModal(true)}
              onClose={() => setSpecies("")}
            />
            <CategoryButton
              label={`Food Type: ${dietType || "Select..."}`}
              onClick={() => setShowDietTypeModal(true)}
              onClose={() => setDietType("")}
            />
            <CategoryButton
              label={`Food Amount: ${
                dietAmount ? dietAmount + " g" : "Enter amount"
              }`}
              onClick={() => setShowAmountModal(true)}
              onClose={() => setDietAmount("")}
            />
          </div>
        </div>
      )}

      {/* Tank Selection Modal */}
      <Modal
        isOpen={showTankModal}
        onClose={() => setShowTankModal(false)}
        title="Select Tank"
      >
        <div className="space-y-4">
          {tankOptions.map((tank) => (
            <div
              key={tank.id}
              onClick={() => {
                setTankNumber(tank.tank_name);
                setShowTankModal(false);
              }}
              className="p-3 bg-gray-700 rounded cursor-pointer hover:bg-gray-600"
            >
              {tank.tank_name}
            </div>
          ))}
          <div className="flex justify-end mt-4">
            <Button
              onClick={() => setShowTankModal(false)}
              className="bg-gray-600 hover:bg-gray-500"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      {/* Species Selection Modal */}
      <Modal
        isOpen={showSpeciesModal}
        onClose={() => setShowSpeciesModal(false)}
        title="Select Species"
      >
        <div className="space-y-4">
          {speciesOptions.map((option) => (
            <div
              key={option}
              onClick={() => {
                setSpecies(option);
                setShowSpeciesModal(false);
              }}
              className="p-3 bg-gray-700 rounded cursor-pointer hover:bg-gray-600"
            >
              {option}
            </div>
          ))}
          <div className="flex justify-end mt-4">
            <Button
              onClick={() => setShowSpeciesModal(false)}
              className="bg-gray-600 hover:bg-gray-500"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      {/* Diet Type Selection Modal */}
      <Modal
        isOpen={showDietTypeModal}
        onClose={() => setShowDietTypeModal(false)}
        title="Select Diet Type"
      >
        <div className="space-y-4">
          {dietTypeOptions.map((option) => (
            <div
              key={option}
              onClick={() => {
                setDietType(option);
                setShowDietTypeModal(false);
              }}
              className="p-3 bg-gray-700 rounded cursor-pointer hover:bg-gray-600"
            >
              {option}
            </div>
          ))}
          <div className="flex justify-end mt-4">
            <Button
              onClick={() => setShowDietTypeModal(false)}
              className="bg-gray-600 hover:bg-gray-500"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      {/* Diet Amount Entry Modal */}
      {showAmountModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-[#1E1E1E] p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">Enter Feed Amount</h2>
            <div className="space-y-4">
              <div>
                <label className="block mb-2">Amount (in grams):</label>
                <input
                  type="number"
                  value={amountValue}
                  onChange={(e) => setAmountValue(e.target.value)}
                  placeholder="Enter amount in grams"
                  className="w-full p-2 bg-[#232323] text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex justify-end mt-4">
                <button
                  onClick={() => setShowAmountModal(false)}
                  className="bg-gray-600 hover:bg-gray-500 text-white px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setDietAmount(amountValue);
                    setShowAmountModal(false);
                  }}
                  className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpecialtyDietSection;
