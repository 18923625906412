import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { BASE_URL } from "../../components/apiService";

// Define the type for birth log data from API
interface BirthLogEntry {
  date: string;
  time: string;
  room: string;
  name: string;
  species: string;
  tank: string;
  parents: string;
  num_of_fish: number;
  seperated: string;
  notes: string;
}

interface ChartDataPoint {
  date: string;
  [key: string]: number | string;
}

const BirthAnalyticsGraph: React.FC = () => {
  const [chartData, setChartData] = useState<ChartDataPoint[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [speciesList, setSpeciesList] = useState<string[]>([]);

  useEffect(() => {
    const fetchBirthData = async () => {
      try {
        setLoading(true);
        const response = await axios.get<BirthLogEntry[]>(
          `${BASE_URL}/birth-log`
        );

        // Process the data for the chart
        const processedData = processDataForChart(response.data);
        setChartData(processedData.chartData);
        setSpeciesList(processedData.species);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching birth data:", err);
        setError("Failed to load birth data. Please try again later.");
        setLoading(false);
      }
    };

    fetchBirthData();
  }, []);

  // Function to process the API data into a format suitable for the chart
  const processDataForChart = (data: BirthLogEntry[]) => {
    // Get unique dates
    const dateMap = new Map<string, Map<string, number>>();
    const speciesSet = new Set<string>();

    // Group by date and species, sum num_of_fish
    data.forEach((entry) => {
      // Format date for consistency (YYYY-MM-DD)
      const formattedDate = new Date(entry.date).toISOString().split("T")[0];

      if (!dateMap.has(formattedDate)) {
        dateMap.set(formattedDate, new Map<string, number>());
      }

      const speciesMap = dateMap.get(formattedDate)!;
      const currentCount = speciesMap.get(entry.species) || 0;
      speciesMap.set(entry.species, currentCount + entry.num_of_fish);

      speciesSet.add(entry.species);
    });

    // Convert to chart data format
    const chartData: ChartDataPoint[] = [];
    const species = Array.from(speciesSet).sort();

    // Sort dates
    const sortedDates = Array.from(dateMap.keys()).sort();

    for (const date of sortedDates) {
      const dataPoint: ChartDataPoint = { date };
      const speciesMap = dateMap.get(date)!;

      // Initialize all species values to 0
      for (const sp of species) {
        dataPoint[sp] = speciesMap.get(sp) || 0;
      }

      chartData.push(dataPoint);
    }

    return { chartData, species };
  };

  const formatDateFieldGraph = (dateString: string | null): string => {
    if (!dateString) return "-";

    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate.replace(/\//g, "-");
  };

  // Generate a color for each species
  const getSpeciesColor = (species: string) => {
    const colorMap: { [key: string]: string } = {
      Tilapia: "#1982C4", // Blue
      Guppy: "#FF1493", // Pink
      Goldfish: "#FF6347", // Red-Orange
      Betta: "#FFD700", // Yellow
      Catfish: "#FF4500", // Orange-Red
    };

    return colorMap[species] || "#8AC926"; // Default green if species not in map
  };

  if (loading)
    return (
      <div className="flex items-center justify-center h-64">
        <span className="text-gray-400">Loading birth data...</span>
      </div>
    );

  if (error) return <div className="text-red-500 p-4">{error}</div>;

  return (
    <div className="w-full h-full bg-[#1E1E1E] p-4 rounded-lg">
      {chartData.length === 0 ? (
        <div className="text-gray-400 p-4 text-center">
          No birth data available
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 60,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
            <YAxis
              stroke="#9CA3AF"
              tick={{ fill: "#9CA3AF" }}
              allowDecimals={false}
              label={{
                value: "Number of Fish Born",
                angle: -90,
                position: "insideLeft",
                offset: 0,
                style: { fill: "#9CA3AF", fontSize: "14px" },
              }}
            />
            <XAxis
              dataKey="date"
              stroke="#9CA3AF"
              tick={{ fill: "#9CA3AF" }}
              tickFormatter={(date) => formatDateFieldGraph(date)}
              label={{
                value: "Date",
                position: "insideBottom",
                offset: -40,
                style: { fill: "#9CA3AF", fontSize: "14px" },
              }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "#1F2937",
                border: "1px solid #374151",
                borderRadius: "0.5rem",
                color: "#F3F4F6",
              }}
              formatter={(value, name) => [`${value} fish`, name]}
              labelFormatter={(label) => new Date(label).toLocaleDateString()}
            />
            <Legend
              wrapperStyle={{
                color: "#F3F4F6",
                paddingTop: "1px",
              }}
            />
            {speciesList.map((species) => (
              <Line
                key={species}
                type="monotone"
                dataKey={species}
                name={species}
                stroke={getSpeciesColor(species)}
                dot={{ fill: getSpeciesColor(species) }}
                activeDot={{ r: 8 }}
                strokeWidth={2}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default BirthAnalyticsGraph;
