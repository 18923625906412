import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
  TooltipItem,
  TooltipModel,
} from "chart.js";
import { BASE_URL } from "../components/apiService";
import FeedingLHS from "./FeedingLHS";

ChartJS.register(ArcElement, Tooltip, Legend);

interface NoteLog {
  id: number;
  date: string;
  notes: string;
  created_at: string;
}

// Nutrient data for different food types
const foodNutrients: { [key: string]: NutrientData } = {
  flakes: {
    crude_protein: 0.102,
    crude_fat: 0.015,
    crude_fiber: 0.009,
    moisture: 0.0036,
    ash: 0.036,
    phosphorus: 0.024,
    calcium: 0.018,
    vitamin_a: 0.0000009,
    vitamin_d3: 1.13e-8,
    vitamin_e: 0.0000603,
    ascorbic_acid: 0.000015,
  },
  pellets: {
    crude_protein: 0.023,
    crude_fat: 0.008,
    crude_fiber: 0.001,
    ash: 0.007,
  },
  "brine shrimp": {
    crude_protein: 0.055,
    crude_fat: 0.012,
    crude_fiber: 0.003,
    moisture: 0.092,
    ash: 0.018,
    phosphorus: 0.014,
    calcium: 0.016,
  },
};

interface NutrientData {
  [key: string]: number;
}

interface FeedingLog {
  id: number;
  date: string;
  time: string;
  period: string;
  room_category: string;
  room: string;
  type: string;
  amount: number;
  tank_number: string | null;
  species: string | null;
  notes?: string;
  created_at?: string;
}

const NutritionRecordPage: React.FC = () => {
  const navigate = useNavigate();
  const [selectedRoom, setSelectedRoom] = useState<string>("Lower Level");
  const [allFeedingLogs, setAllFeedingLogs] = useState<FeedingLog[]>([]);
  const [filteredFeedingLogs, setFilteredFeedingLogs] = useState<FeedingLog[]>(
    []
  );
  const [nutrientData, setNutrientData] = useState<{ [key: string]: number }>(
    {}
  );
  const [weeklyPrimaryDiet, setWeeklyPrimaryDiet] = useState<string>("");
  const [monthlyPrimaryDiet, setMonthlyPrimaryDiet] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentDateTime, setCurrentDateTime] = useState<Date>(new Date());
  const [notesLogs, setNotesLogs] = useState<NoteLog[]>([]);

  // Fetch all feeding logs
  const fetchFeedingLogs = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${BASE_URL}/feeding`);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setAllFeedingLogs(data);
      const filteredNotes = data
        .filter((log: FeedingLog) => log.notes && log.notes.trim() !== "")
        .map((log: FeedingLog) => ({
          id: log.id,
          date: log.date,
          notes: log.notes,
          created_at: log.created_at,
        }));
      setNotesLogs(filteredNotes);
      filterAndCalculateNutrients(data, selectedRoom);
    } catch (error) {
      console.error("Error fetching feeding logs:", error);
      setError(error instanceof Error ? error.message : String(error));
    } finally {
      setIsLoading(false);
    }
  };

  // Filter logs and calculate nutrients
  const filterAndCalculateNutrients = (
    logs: FeedingLog[],
    roomCategory: string
  ) => {
    // Filter logs based on room category
    const filteredLogs =
      roomCategory === "All"
        ? logs
        : logs.filter((log) => log.room_category === roomCategory);

    setFilteredFeedingLogs(filteredLogs);
    calculateNutrientProfile(filteredLogs);
    calculatePrimaryDiets(logs, roomCategory);
  };

  // Calculate nutrient profile based on feeding logs
  const calculateNutrientProfile = (logs: FeedingLog[]) => {
    const nutrientAccumulation: { [key: string]: number } = {};

    logs.forEach((log) => {
      const foodType = log.type.toLowerCase();
      const amount = log.amount;

      // Check if the food type exists in our nutrient database
      if (foodNutrients[foodType]) {
        Object.keys(foodNutrients[foodType]).forEach((nutrient) => {
          if (!nutrientAccumulation[nutrient]) {
            nutrientAccumulation[nutrient] = 0;
          }
          // Calculate nutrient amount: food type's nutrient percentage * amount
          nutrientAccumulation[nutrient] +=
            foodNutrients[foodType][nutrient] * amount;
        });
      }
    });

    setNutrientData(nutrientAccumulation);
  };

  // Calculate primary diets for weekly and monthly averages
  const calculatePrimaryDiets = (logs: FeedingLog[], roomCategory: string) => {
    // Filter logs based on room category
    const filteredLogs =
      roomCategory === "All"
        ? logs
        : logs.filter((log) => log.room_category === roomCategory);

    // Calculate weekly diet
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    const weeklyLogs = filteredLogs.filter(
      (log) => new Date(log.date) >= oneWeekAgo
    );

    // Calculate monthly diet
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const monthlyLogs = filteredLogs.filter(
      (log) => new Date(log.date) >= oneMonthAgo
    );

    // Get primary diet type for weekly logs
    const weeklyDiet = calculatePrimaryDietType(weeklyLogs);
    setWeeklyPrimaryDiet(weeklyDiet);

    // Get primary diet type for monthly logs
    const monthlyDiet = calculatePrimaryDietType(monthlyLogs);
    setMonthlyPrimaryDiet(monthlyDiet);
  };

  // Helper function to calculate the primary diet type from logs
  const calculatePrimaryDietType = (logs: FeedingLog[]): string => {
    if (logs.length === 0) return "No data available";

    // Count occurrence of each food type
    const foodTypeCounts: { [key: string]: number } = {};
    logs.forEach((log) => {
      const foodType = log.type;
      if (!foodTypeCounts[foodType]) {
        foodTypeCounts[foodType] = 0;
      }
      foodTypeCounts[foodType] += log.amount;
    });

    // Find the most used food type
    let primaryType = "";
    let maxAmount = 0;

    Object.entries(foodTypeCounts).forEach(([type, amount]) => {
      if (amount > maxAmount) {
        maxAmount = amount;
        primaryType = type;
      }
    });

    return primaryType;
  };

  // Fetch logs on component mount
  useEffect(() => {
    fetchFeedingLogs();

    // Update current time every minute
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  // Handle room selection
  const handleRoomSelection = (room: string) => {
    setSelectedRoom(room);
    filterAndCalculateNutrients(allFeedingLogs, room);
  };

  // Prepare Pie Chart Data
  const preparePieChartData = () => {
    const totalNutrients =
      Object.values(nutrientData).reduce((sum, value) => sum + value, 0) || 1;

    return {
      labels: Object.keys(nutrientData).map((key) =>
        key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      ),
      datasets: [
        {
          data: Object.values(nutrientData).map(
            (value) => (value / totalNutrients) * 100
          ),
          backgroundColor: [
            "#36A2EB",
            "#FFCE56",
            "#FF6384",
            "#4BC0C0",
            "#9966FF",
            "#FF9F40",
          ],
        },
      ],
    };
  };

  // Explicitly typed chart options
  const chartOptions: ChartOptions<"pie"> = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (
            this: TooltipModel<"pie">,
            context: TooltipItem<"pie">
          ): string[] {
            const dataIndex = context.dataIndex;
            const datasetIndex = context.datasetIndex;

            const chart = this.chart;
            const dataset = chart.data.datasets[datasetIndex];
            const value = dataset.data[dataIndex] as number;
            const label = chart.data.labels?.[dataIndex] as string;

            const nutrientValues = Object.values(nutrientData);
            const actualValue = nutrientValues[dataIndex] || 0;

            return [
              `${label}: ${value.toFixed(2)}%`,
              `Amount: ${actualValue.toFixed(6)}g`,
            ];
          },
        },
      },
      legend: {
        display: true,
        position: "right",
      },
    },
  };

  // Navigation handlers
  const handleWaterQualityLogs = () => {
    navigate("/water-quality-logs");
  };

  const handleFoodRecord = () => {
    navigate("/food-record");
  };

  const handleNutritionRecord = () => {
    navigate("/nutrition-record");
  };

  const formatTime = (date: Date) => {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };

  const formatNoteDate = (dateString: string) => {
    const date = new Date(dateString);
    return {
      date: date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }),
      time: date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }),
    };
  };

  const renderLeftBar = () => {
    return (
      <div className="w-56 bg-[#232323] flex flex-col">
        <div
          onClick={() => navigate("/")}
          className=" cursor-pointer justify-center align-middle text-center py-4 mb-6 px-4 py-3 p-6 mt-4 text-sm text-left bg-[#1C1C1E] text-white hover:text-white"
          style={{ borderRadius: 8 }}
        >
          Back to Dashboard
        </div>

        <div
          className="rounded-sm bg-[#707070] mb-4 text-white p-2 m-2 border-t border-r border-l "
          style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
        >
          <div className="py-2 px-2 border-b border-gray-400 cursor-pointer">
            Daily Feeding Log
          </div>
          <div
            onClick={handleFoodRecord}
            className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between"
          >
            <span className="text-sm">
              <span className="text-black">☆</span> Food Record
            </span>
            <span className="text-xs text-black">⇧ A</span>
          </div>
          <div
            onClick={handleNutritionRecord}
            className="py-2 px-2 flex items-center space-x-2 cursor-pointer justify-between"
          >
            <span className="text-sm">
              <span className="text-black">☆</span> Nutrition Data
            </span>
            <span className="text-xs text-black">⇧ A</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-black text-white flex">
      {/* Left Sidebar */}
      <FeedingLHS />

      {/* Main Content */}
      <div className="flex-1 bg-[#1E1E1E]">
        <div className="flex bg-[#262626] justify-between items-center mb-6 p-6">
          <div className="bg-[#262626]">
            <h1 className="text-2xl font-bold">Nutrition Profile</h1>
            <div className="text-sm text-gray-400">The Doe Lab</div>
          </div>

          <div className="flex items-center ">
            <div className="mr-4 text-blue-400 flex space-x-2">
              <input
                type="date"
                value={formatDate(currentDateTime)}
                className="bg-transparent text-center"
                readOnly
              />
              <input
                type="time"
                value={formatTime(currentDateTime)}
                className="bg-transparent text-center"
                readOnly
              />
            </div>
            <div className="w-10 h-10 rounded-full bg-gray-500 flex items-center justify-center font-bold">
              {
                currentDateTime.toLocaleTimeString([], {
                  hour12: true,
                  hour: "2-digit",
                })[0]
              }
            </div>
          </div>
        </div>

        <div className="space-y-6 p-6">
          <div className="w-40">
            <h1 className="mb-2">Select Room</h1>
            <select
              value={selectedRoom}
              onChange={(e) => handleRoomSelection(e.target.value)}
              className="w-full bg-[#323232] text-white p-2 rounded"
            >
              <option value="Lower Level">Lower Level</option>
              <option value="Upper Level">Upper Level</option>
              <option value="All">All Rooms</option>
            </select>
          </div>

          {/* Loading or Error State */}
          {isLoading ? (
            <div className="text-center py-4">Loading nutrition data...</div>
          ) : error ? (
            <div className="text-red-500 text-center py-4">{error}</div>
          ) : null}

          {/* Nutrition Profile Section with Chart and Primary Diet Info */}
          <div className="flex flex-col md:flex-row space-y-10 md:space-y-10 lg:space-x-40 lg:ml-10 ml-10">
            {/* Nutrition Profile Pie Chart */}
            <div className="lg:w-72 w-full">
              <h2 className="text-2xl font-bold mb-4">Nutrition Profile:</h2>
              <div className="w-full h-64 bg-[#323232] rounded-lg p-4">
                {Object.keys(nutrientData).length > 0 ? (
                  <Pie data={preparePieChartData()} options={chartOptions} />
                ) : (
                  <div className="flex items-center justify-center h-full">
                    <p className="text-gray-400">
                      No nutrition data available for selected room
                    </p>
                  </div>
                )}
              </div>
            </div>

            {/* Primary Diet Components Section */}
            <div className="lg:w-1/2 w-full">
              <div className="space-y-4">
                {/* 1 Week Average Primary Diet */}
                <div>
                  <h3 className="text-sm mb-2">
                    1 Week Average (Primary Diet Component)
                  </h3>
                  <div className="bg-[#323232] rounded p-4 h-20">
                    {weeklyPrimaryDiet ? (
                      <p className="text-white">{weeklyPrimaryDiet}</p>
                    ) : (
                      <p className="text-gray-400">No data available</p>
                    )}
                  </div>
                </div>

                {/* 1 Month Average Primary Diet */}
                <div>
                  <h3 className="text-sm mb-2">
                    1 Month Average (Primary Diet Component)
                  </h3>
                  <div className="bg-[#323232] rounded p-4 h-20">
                    {monthlyPrimaryDiet ? (
                      <p className="text-white">{monthlyPrimaryDiet}</p>
                    ) : (
                      <p className="text-gray-400">No data available</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4">Notes Log</h2>
          <div className="rounded-lg p-4">
            {notesLogs.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="md:col-span-2 space-y-4">
                  {notesLogs.map((note) => (
                    <div key={note.id} className="border p-3 rounded-md">
                      <p className="text-white">{note.notes}</p>
                    </div>
                  ))}
                </div>
                <div className="md:col-span-1 space-y-4">
                  {notesLogs.map((note) => {
                    const { date, time } = formatNoteDate(
                      note.created_at || note.date
                    );
                    return (
                      <div key={note.id} className="p-3 rounded-md text-center">
                        <div className="text-sm text-gray-400">
                          {date} {time}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="text-center text-gray-400 py-8">
                No notes available
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NutritionRecordPage;
