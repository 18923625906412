import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { sidebarItems, sidebarCalendarItems } from "../utils/data";
import axios from "axios";
import { BASE_URL } from "../components/apiService";

interface TankCleaningLog {
  id: number;
  name: string;
  date: string;
  time: string;
  room: string;
  rack: string;
  notes: string;
  startTime: string;
  stopTime: string | null;
  totalTimeWorked: string | null;
}

interface PersonHours {
  name: string;
  last7DaysHours: number;
  lastMonthHours: number;
  logs: TankCleaningLog[];
}

interface PaginationInfo {
  total: number;
  totalPages: number;
  currentPage: number;
  limit: number;
}

const LabPersonnelHour: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("Calendar");
  const [cleaningLogs, setCleaningLogs] = useState<TankCleaningLog[]>([]);
  const [personnelHours, setPersonnelHours] = useState<PersonHours[]>([]);
  const [isFetchingLogs, setIsFetchingLogs] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState<PaginationInfo>({
    total: 0,
    totalPages: 0,
    currentPage: 1,
    limit: 10,
  });
  const navigate = useNavigate();

  const handleNavigation = (item: string) => {
    if (item === "Feeding" || item === "fish feeding") {
      navigate("/feeding/f2");
    } else if (item === "water quality") {
      navigate("/water-quality");
    } else if (item === "Fish Morbidity") {
      navigate("/MorbidityEntry");
    } else if (item === "tank cleaning") {
      navigate("/tank-cleaning");
    } else if (item === "Fish Birth") {
      navigate("/BirthEntryPage");
    } else if (item === "All Documents") {
      navigate("/AllDocuments");
    } else if (item === "Room Reservations") {
      navigate("/RoomBooking");
    } else if (item === "Task Manager") {
      navigate("/");
    } else if (item === "Lab Personnel Hours") {
      navigate("/lab-personal-hours");
    }
  };

  const showAlert = (type: string, message: string) => {
    console.error(`${type}: ${message}`);
    // Implement your alert system here
  };

  const fetchCleaningLogs = async (page: number = 1) => {
    setIsFetchingLogs(true);
    try {
      const response = await fetch(
        `${BASE_URL}/tank-cleaning-hours?page=${page}&limit=10`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch cleaning logs");
      }
      const result = await response.json();
      setCleaningLogs(result.data);
      setPagination(result.pagination);

      // Important: Calculate personnel hours using only the current page data
      calculatePersonnelHours(result.data);

      // Update current page
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching cleaning logs:", error);
      showAlert("error", "Failed to fetch cleaning logs");
    } finally {
      setIsFetchingLogs(false);
    }
  };

  const calculatePersonnelHours = (logs: TankCleaningLog[]) => {
    // Get current date and calculate dates for 7 days ago and 30 days ago
    const currentDate = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(currentDate.getDate() - 7);

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);

    // Group logs by person name
    const personnelMap = new Map<string, TankCleaningLog[]>();

    logs.forEach((log) => {
      if (!personnelMap.has(log.name)) {
        personnelMap.set(log.name, []);
      }
      personnelMap.get(log.name)?.push(log);
    });

    // Calculate hours for each person
    const personnelHoursData: PersonHours[] = [];

    personnelMap.forEach((personLogs, name) => {
      let last7DaysHours = 0;
      let lastMonthHours = 0;

      personLogs.forEach((log) => {
        if (!log.startTime || !log.stopTime) return;

        const startTime = new Date(log.startTime);
        const stopTime = new Date(log.stopTime);

        // Calculate hours worked
        const hoursWorked =
          (stopTime.getTime() - startTime.getTime()) / (1000 * 60 * 60);

        // Add to appropriate time periods
        if (startTime >= sevenDaysAgo) {
          last7DaysHours += hoursWorked;
        }

        if (startTime >= thirtyDaysAgo) {
          lastMonthHours += hoursWorked;
        }
      });

      personnelHoursData.push({
        name,
        last7DaysHours: parseFloat(last7DaysHours.toFixed(2)),
        lastMonthHours: parseFloat(lastMonthHours.toFixed(2)),
        logs: personLogs,
      });
    });

    setPersonnelHours(personnelHoursData);
  };

  const goToNextPage = () => {
    if (currentPage < pagination.totalPages) {
      const nextPage = currentPage + 1;
      fetchCleaningLogs(nextPage);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      fetchCleaningLogs(prevPage);
    }
  };

  // Fetch logs on component mount
  useEffect(() => {
    fetchCleaningLogs();
  }, []);

  return (
    <div className="bg-[#262626] text-[#858585] min-h-screen flex">
      <aside className="w-60 bg-[#232323] p-6">
        <div className="flex space-x-4 mb-6">
          <button
            className={`px-4 py-2 rounded ${
              selectedTab === "Jobs" ? "bg-[#1C1C1E]" : ""
            }`}
            onClick={() => setSelectedTab("Jobs")}
          >
            Jobs
          </button>
          <button
            className={`px-4 py-2 rounded ${
              selectedTab === "Calendar" ? "bg-[#1C1C1E]" : ""
            }`}
            onClick={() => setSelectedTab("Calendar")}
          >
            Calendar
          </button>
        </div>
        <nav>
          <ul className="space-y-2">
            {(selectedTab === "Jobs" ? sidebarItems : sidebarCalendarItems).map(
              (item, index) => (
                <li
                  key={index}
                  className="p-2 rounded hover:bg-[#1C1C1E] cursor-pointer"
                  onClick={() => handleNavigation(item)}
                >
                  {item}
                </li>
              )
            )}
          </ul>
        </nav>
      </aside>

      <main className="flex-1 bg-[#1E1E1E]">
        <header className="flex justify-between items-center p-6 bg-[#262626]">
          <div className="flex items-center space-x-4">
            <div className="bg-[#5D5D5D] rounded-full w-10 h-10 flex items-center justify-center">
              {/* Placeholder for user image */}
            </div>
            <div>
              <h1 className="text-xl font-semibold text-white">
                Welcome John Doe
              </h1>
              <p className="text-sm text-gray-500">The Doe Lab</p>
            </div>
          </div>
        </header>
        <div className="bg-[#1E1E1E] rounded-lg shadow-md p-6 m-6">
          <h1 className="text-2xl font-bold mb-6 text-white">Working Hours</h1>

          {isFetchingLogs ? (
            <div className="text-center py-8 text-white">Loading data...</div>
          ) : (
            <div className="overflow-x-auto">
              {/* Added border to the table */}
              <table className="w-full border-collapse">
                {/* Added border-x to table headers */}
                <thead className="bg-[#333333] text-left">
                  <tr>
                    <th className="p-3 border border-[#444]"></th>
                    <th className="p-3 border border-[#444]">Name</th>
                    <th className="p-3 border border-[#444]">
                      Hours (Last 7 Days)
                    </th>
                    <th className="p-3 border border-[#444]">
                      Hours (Last Month)
                    </th>
                  </tr>
                </thead>
                {/* Added borders to table cells */}
                <tbody>
                  {personnelHours.map((person, index) => (
                    <tr key={index} className="hover:bg-[#333]">
                      <td className="p-3 border border-[#444] bg-[#333333]">
                        {index +
                          1 +
                          (pagination.currentPage - 1) * pagination.limit}
                      </td>
                      <td className="p-3 border border-[#444]">
                        {person.name}
                      </td>
                      <td className="p-3 border border-[#444]">
                        {person.last7DaysHours} hrs
                      </td>
                      <td className="p-3 border border-[#444]">
                        {person.lastMonthHours} hrs
                      </td>
                    </tr>
                  ))}
                  {personnelHours.length === 0 && (
                    <tr>
                      <td
                        colSpan={4}
                        className="px-6 py-4 text-center text-sm text-white"
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {cleaningLogs.length > 0 && (
                <div className="flex justify-between items-center mt-6">
                  <button
                    onClick={goToPreviousPage}
                    disabled={currentPage === 1}
                    className="px-4 py-2 bg-[#232323] text-white rounded disabled:opacity-50"
                  >
                    Prev
                  </button>
                  <span className="text-white">
                    Page {pagination.currentPage} of {pagination.totalPages}
                  </span>
                  <button
                    onClick={goToNextPage}
                    disabled={currentPage === pagination.totalPages}
                    className="px-4 py-2 bg-[#232323] text-white rounded disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default LabPersonnelHour;
