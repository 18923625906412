export const formatTime = (date: Date) => {
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};
export const formatDate = (date: Date) => {
  return date.toISOString().split("T")[0];
};

export const darkBgColor = "#1C1C1E";
export const LightDarkBgColor = "#232323";
